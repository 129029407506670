import request from '@/utils/request'

// 主页查询列表
export function getShowRecruitmentList() {
  return request({
    url: '/recruitment/getShowRecruitmentList',
    method: 'get',
  })
}

// 查询仿智联招聘列表
export function listRecruitment(query) {
  return request({
    url: '/recruitment/list',
    method: 'get',
    params: query
  })
}

// 查询仿智联招聘详细
export function getRecruitment(recruitmentId) {
  return request({
    url: '/recruitment/' + recruitmentId,
    method: 'get'
  })
}

export function findInDict(dictList, str) {
  let res = ""
  dictList.forEach((item) => {
    if (item.dictValue == str) {
      res = item.dictLabel
    }
  })
  return res;
}