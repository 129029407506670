<template>
  <v-dialog v-model="baiduMapDialogShow" @click:outside="$emit('update:baiduMapDialogShow', false)" width="1400"
    :fullscreen="!$vuetify.breakpoint.xl">
    <v-card class="beautiful-card text--secondary">
      <v-card-title flat>
        <v-toolbar-title class="text-h6"> 总公司所在地 </v-toolbar-title>
        <v-spacer></v-spacer>
        <close-dialog-button @click-close="$emit('update:baiduMapDialogShow', false)" />
      </v-card-title>
      <v-card-text>
        <div class="baidu-div overflow-hidden">
          <iframe frameborder="0" height="700px" width="100%" src="https://j.map.baidu.com/c8/B5Dc" />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>