import { listRecruitment, findInDict } from "@/api/recruitment";
import { findClassification } from "@/api/classification";
import recruitmentItem from "../recruitment-item/index.vue";
export default {
  name: "recruitment-history",
  props: {
    recruimentHistoryShow: {
      type: Boolean,
      default: false,
    },
  },
  components: { recruitmentItem },
  data: () => ({
    recruitmentId: 0,
    recruitmentItemShow: false,
    recruitmentQueryParams: {
      pageNum: 1,
      pageSize: 10,
    },
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    recruitmentList: [],
    total: 0,
    overlay: true,
  }),
  watch: {
    recruitmentQueryParams: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    recruimentHistoryShow(val) {
      if (val) {
        this.getList();
      }
    }
  },
  methods: {
    /** 查询信息公告列表 */
    getList: function () {
      listRecruitment(this.recruitmentQueryParams).then((response) => {
        let _recruitmentList = [];
        response.data.rows.forEach((item) => {
          item.positionClassification = findClassification(
            item.positionClassification
          );
          item.companyType = findInDict(
            this.$global.companyTypeOptions,
            item.companyType
          );
          item.educationalRestrictionsType = findInDict(
            this.$global.educationalRestrictionsTypeOptions,
            item.educationalRestrictionsType
          );
          item.workType = findInDict(
            this.$global.workTypeOptions,
            item.workType
          );
          _recruitmentList.push(item);
        });
        this.recruitmentList = _recruitmentList;
        this.total = response.data.total;
        this.overlay = false;
      });
    },
    openRecruitmentItem: function (id) {
      this.recruitmentId = id;
      this.recruitmentItemShow = true;
    },
    salaryRangeGenerator: function (type, min, max, salaryType) {
      let typeMap = {
        "0": "月薪",
        "1": "日薪",
        "2": "年薪",
        "3": "时薪",
        "4": "周薪",
        "5": "计件",
      };
      switch (salaryType) {
        case 0:
          return ` ${typeMap[type]} ${min}-${max}`;
        case 1:
          return `${typeMap[type]} ${min} 以上 `
        case 2:
          return `${typeMap[type]} ${min} 以下 `
        case 3:
          return `工资面议 `
        case 4:
          return `${typeMap[type]} ${min} `
      }
    }
  },
};