<template>
  <v-dialog v-model="wechatDialogShow" @click:outside="$emit('update:wechatDialogShow', false)" width="auto"
    :fullscreen="!$vuetify.breakpoint.xl">
    <v-card class="beautiful-card text--secondary">
      <v-card-title class="beautiful-card-title" flat>
        <div class="beautiful-card-title-text">欢迎加求职交流群</div>
        <v-spacer></v-spacer>
        <close-dialog-button @click-close="$emit('update:wechatDialogShow', false)" />
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <div class="qr-box pa-8 text-center">
              <v-img :src="$nginxUrl + 'qr-code/wechat-communication.png'" class="ma-1" max-height="25rem"
                max-width="25rem" />

              <div>
                <p>微信扫一扫添加求职者交流群</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text> </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>