export default {
  name: "phone-book",
  props: {
    phoneListDialogShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      descShow: [],
      desserts: [
        {
          name: "招聘、求职、业务洽谈",
          desc: "地址：重庆市渝中区大坪正街19号英利国际广场1号楼39楼大坪七牌坊公交车站旁、地铁2号线大坪小学出口斜对面",
          phone: [
            {
              show: "63317705",
              number: "023-63317705",
            },
            {
              show: "63317706",
              number: "023-63317706",
            },
          ],
        },
        {
          name: "入离职办理、劳动关系证明",
          phone: [
            {
              show: "63315906",
              number: "023-63315906",
            },
          ],
        },
        {
          name: "工伤医疗待遇受理、退休咨询",
          phone: [
            {
              show: "63659697",
              number: "023-63659697",
            },
          ],
        },
        {
          name: "薪酬社保综合咨询",
          desc: "工资查询、收入证明、薪酬外包、社保参停咨询,养老、失业、生育待遇受理",
          phone: [
            {
              show: "63315901",
              number: "023-63315901",
            },
          ],
        },
        {
          name: "社保公积金相关问题",
          phone: [
            {
              show: "63315900",
              number: "023-63315900",
            },
          ],
        },
        {
          name: "薪酬查询",
          phone: [
            {
              show: "63315902",
              number: "023-63315902",
            },
          ],
        },
        {
          name: "档案托管、户口挂靠、职称评定",
          phone: [
            {
              show: "63315931",
              number: "023-63315931",
            },
          ],
        },
        {
          name: "网络平台使用咨询、系统开发",
          phone: [
            {
              show: "63315903",
              number: "023-63315903",
            },
          ],
        },
        {
          name: "投诉建议、综合事务",
          phone: [
            {
              show: "63315909",
              number: "023-63315909",
            },
          ],
        },
        {
          name: "T3项目部",
          phone: [
            {
              show: "63005051",
              number: "023-63005051",
            },
            {
              show: "63005053",
              number: "023-63005053",
            },
          ],
        },
        {
          name: "人力资源部",
          phone: [
            {
              show: "63327952",
              number: "023-63327952",
            },
          ],
        },
        {
          name: "第一分公司",
          desc: "渝北区空港国际新城春华大道99号中国(重庆)人力资源服务产业园北区7号楼507室(轨道交通十号线鹿山站下或乘坐619、679、689、877路公交车)",
          phone: [
            {
              show: "86097308",
              number: "023-86097308",
            },
            {
              show: "67189877",
              number: "023-67189877",
            },
          ],
        },
        {
          name: "巴南办事处",
          desc: "巴南区鱼洞镇(大江转盘)大江中路育才街3129栋2号(新世纪百货对面,张冬梅诊所往里面)",
          phone: [
            {
              show: "66214721",
              number: "023-66214721",
            },
          ],
        },
        {
          name: "璧山办事处",
          desc: "璧山区璧城街道柿花街51号（璧山红宇大道计生委宾馆背后）",
          phone: [
            {
              show: "85290382",
              number: "023-85290382",
            },
          ],
        },
      ],
    };
  },
};