import request from '@/utils/request'

// 查询微信二维码列表
export function listQr(query) {
  return request({
    url: '/qr/list',
    method: 'get',
    params: query
  })
}

// 查询微信二维码详细
export function getQr(wechatQrId) {
  return request({
    url: '/qr/' + wechatQrId,
    method: 'get'
  })
}

// 查询微信二维码详细
export function getQrByName(userName) {
  return request({
    url: '/qr/name/' + userName,
    method: 'get'
  })
}