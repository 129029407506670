import { getNews } from "../../../api/news";

export default {
    name: "staff-item",
    props: {
        newsItemShow: {
            type: Boolean,
            default: false,
        },
        newsId: {
            type: Number,
            default: 0,
        },
    },
    watch: {
        newsItemShow: function (val) {
            if (val) {
                let url = this.$official + "?type=news&id=" + btoa(this.newsId);
                this.snackbarTitle = url;
                this.getNewsById(this.newsId);
            }
        },
    },
    data: () => ({
        newsItem: {},
        snackbar: true,
        snackbarTitle: "",
        overlay: true,
        downloadListDialog: false,
    }),
    methods: {
        getNewsById: function (id) {
            getNews(id).then((response) => {
                this.newsItem = response.data.data;
                if (response.data.data.newsFile == null) {
                    this.newsItem.newsFile = null;
                } else {
                    this.newsItem.newsFile = response.data.data.newsFile.split(",");
                }
                this.overlay = false;
            });
        },
        shareAction: function () {
            // this.snackbar = !this.snackbar;
            let target = 10000;
            let container = this.$vuetify.breakpoint.xl ? ".news-box" : ".news-box-mobile";
            let options = {
                duration: 2000,
                offset: 0,
                easing: "easeInOutCubic",
                container: container
            }
            this.$vuetify.goTo(target, options);
        },
        download: function (fileName) {
            window.open(this.$nginxFileUrl + fileName)
        }
    },
};