<template>
  <v-container>
    <caption-widget title="企业服务" subtitle="Service" />
    <div v-if="$vuetify.breakpoint.xl">
      <figure class="service-box overflow-hidden" v-for="(item, index) in servicesItems" :key="index" v-ripple>
        <img :src="item.url" />
        <div class="title">
          <div>
            <h2>{{ item.title }}</h2>
            <h5>{{ item.englishTitle }}</h5>
          </div>
        </div>
        <figcaption>
          <p v-for="(descriptionItem, index) in item.description" :key="index">
            {{ descriptionItem }}
          </p>
        </figcaption>
      </figure>
    </div>
    <div v-else fluid>
      <v-row dense>
        <v-col v-for="(card, index) in servicesItems" :key="index" :cols="$vuetify.breakpoint.md ? 3 : 6">
          <v-card class="app-card">
            <v-img :src="card.url" class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              :height="$vuetify.breakpoint.md ? '200px' : '140px'" @click="check(index)" v-ripple>
              <v-card-title>
                <div class="text-subtitle-1 font-weight-medium text-shadow">
                  {{ card.title }}
                </div>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <span class="
                    font-weight-bold
                    text-subtitle-2
                    show-other-button
                    text-shadow
                  ">
                  查看详情</span>
              </v-card-actions>
            </v-img>

            <v-expand-transition>
              <div v-show="show[index]">
                <v-divider></v-divider>
                <v-card-text>
                  <div v-for="(descriptionItem, i) in card.description" :key="i" v-ripple class="text-subtitle-2">
                    {{ descriptionItem }}
                  </div>
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "./index.mobile.scss";
</style>