import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.lightBlue.darken1, // #039BE5
        secondary: colors.red.lighten4, // #FFCDD2
        accent: colors.lightBlue.darken1, // #039BE5
        error: colors.red.accent3, // #E53935
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
