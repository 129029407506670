<template>
  <div>
    <div class="overview-wrap">
      <div class="overview-box">
        <div class="t-box">
          <div class="pic">
            <v-img class="pc-box" :src="$nginxUrl + 'company/overview.jpg'" alt="img" aspect-ratio
              :height="$vuetify.breakpoint.xl ? 620 : 200" />
          </div>
          <div class="mess-box text--secondary" :class="{
            'body-1 py-10 pr-10': $vuetify.breakpoint.xl,
            'text-subtitle-2 pt-7': !$vuetify.breakpoint.xl,
          }">
            <p>
              重庆万友人才服务有限公司成立于2004年5月，投资单位为重庆市人力资源和社会保障局重庆市人才大市场集团有限公司和中国兵器装备集团西南兵器工业有限责任公司，系国有综合性人力资源服务机构，公司旗下有多家子公司和分公司。
            </p>
            <p>
              公司设有党总支和工会组织。
            </p>
            <p>
              公司与重庆市人才交流服务中心万友分中心合署办公。
            </p>
            <p>
              公司拥有网站、微信公众号、电子合同平台和自主研发的信息管理系统。
            </p>
            <p>
              公司服务内容包括劳务派遣、招聘流程外包、猎头服务、岗位外包、项目外包、管理咨询、专业培训、薪酬社保服务、档案整理、其他人力资源解决方案等，具有多样化的人力资源服务产品链。
            </p>
            <p>
              公司累计服务客户单位数千家，服务人员数十万人，分布在政府机关、事业单位、国有企业、外资企业、合资企业以及其他领域。
            </p>
            <p>
              公司以专业、规范、优质、高效的服务，赢得了客户单位、外派人员以及社会各界的广泛好评，树立了知名的“万友人才”品牌。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "./index.mobile.scss";
</style>