const data = {
    workTypeOptions: [
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2021-12-07 09:24:39",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 100,
            "dictSort": 0,
            "dictLabel": "实习",
            "dictValue": "0",
            "dictType": "recruitment_work_type",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2021-12-07 09:24:53",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 101,
            "dictSort": 1,
            "dictLabel": "全职",
            "dictValue": "1",
            "dictType": "recruitment_work_type",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2021-12-07 09:25:17",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 102,
            "dictSort": 2,
            "dictLabel": "兼职",
            "dictValue": "2",
            "dictType": "recruitment_work_type",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2022-03-02 16:37:49",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 112,
            "dictSort": 4,
            "dictLabel": "校园",
            "dictValue": "3",
            "dictType": "recruitment_work_type",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        }
    ],
    educationalRestrictionsTypeOptions: [
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2021-12-07 09:30:41",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 103,
            "dictSort": 0,
            "dictLabel": "无",
            "dictValue": "0",
            "dictType": "recruitment_education",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2021-12-07 09:30:52",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 104,
            "dictSort": 1,
            "dictLabel": "小学",
            "dictValue": "1",
            "dictType": "recruitment_education",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2021-12-07 09:31:03",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 105,
            "dictSort": 2,
            "dictLabel": "初中",
            "dictValue": "2",
            "dictType": "recruitment_education",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2021-12-07 09:31:40",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 106,
            "dictSort": 3,
            "dictLabel": "高中",
            "dictValue": "3",
            "dictType": "recruitment_education",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2021-12-07 09:32:05",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 107,
            "dictSort": 4,
            "dictLabel": "大学",
            "dictValue": "4",
            "dictType": "recruitment_education",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2021-12-07 09:32:23",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 108,
            "dictSort": 5,
            "dictLabel": "硕士",
            "dictValue": "5",
            "dictType": "recruitment_education",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2021-12-07 09:32:55",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 109,
            "dictSort": 6,
            "dictLabel": "博士",
            "dictValue": "6",
            "dictType": "recruitment_education",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2021-12-07 09:33:08",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 110,
            "dictSort": 7,
            "dictLabel": "中专/中技",
            "dictValue": "7",
            "dictType": "recruitment_education",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2021-12-07 09:33:16",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 111,
            "dictSort": 8,
            "dictLabel": "大专",
            "dictValue": "8",
            "dictType": "recruitment_education",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        }
    ],
    companyTypeOptions: [
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2022-03-03 15:05:43",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 113,
            "dictSort": 0,
            "dictLabel": "国有企业",
            "dictValue": "0",
            "dictType": "company_type",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2022-03-03 15:06:06",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 114,
            "dictSort": 1,
            "dictLabel": "国有控股企业",
            "dictValue": "1",
            "dictType": "company_type",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2022-03-03 15:06:16",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 115,
            "dictSort": 2,
            "dictLabel": "外资企业",
            "dictValue": "2",
            "dictType": "company_type",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2022-03-03 15:06:26",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 116,
            "dictSort": 3,
            "dictLabel": "合资企业",
            "dictValue": "3",
            "dictType": "company_type",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2022-03-03 15:06:37",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 117,
            "dictSort": 4,
            "dictLabel": "私营企业",
            "dictValue": "4",
            "dictType": "company_type",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2022-03-03 15:06:56",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 118,
            "dictSort": 5,
            "dictLabel": "事业单位",
            "dictValue": "5",
            "dictType": "company_type",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2022-03-03 15:07:09",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 119,
            "dictSort": 6,
            "dictLabel": "国家行政机关",
            "dictValue": "6",
            "dictType": "company_type",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2022-03-03 15:12:04",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 120,
            "dictSort": 7,
            "dictLabel": "港澳台资企业",
            "dictValue": "7",
            "dictType": "company_type",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2022-03-03 15:12:20",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 121,
            "dictSort": 8,
            "dictLabel": "个体工商户",
            "dictValue": "8",
            "dictType": "company_type",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2022-03-03 15:12:40",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 122,
            "dictSort": 9,
            "dictLabel": "民办非企业",
            "dictValue": "9",
            "dictType": "company_type",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        },
        {
            "searchValue": null,
            "createBy": "admin",
            "createTime": "2022-03-03 15:13:06",
            "updateBy": null,
            "updateTime": null,
            "remark": null,
            "params": {},
            "dictCode": 123,
            "dictSort": 10,
            "dictLabel": "其他企业",
            "dictValue": "10",
            "dictType": "company_type",
            "cssClass": null,
            "listClass": "default",
            "isDefault": "N",
            "status": "0",
            "default": false
        }
    ],
}

export function getData() {
    return data;
}