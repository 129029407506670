import navigatDialog from "./navigate-dialog/navigate.vue";
import phoneBook from "./phone-book/index.vue";
import wechatDialog from "./wechat-dialog/index.vue";
import wechatPublicAccountDialog from "./wechat-public-account-dialog/index.vue";

export default {
    name: "footer-section",
    components: {
        'wechat-dialog': wechatDialog,
        'phone-book': phoneBook,
        'wechat-public-account-dialog': wechatPublicAccountDialog,
        'navigate-dialog': navigatDialog,
    },
    data: function () {
        return {
            phoneListDialogShow: false,
            wechatDialogShow: false,
            wechatPublicAccountDialogShow: false,
            baiduMapDialogShow: false,
            isHelp: false,
            searchValue: "",
            snackbar: false,
        };
    },
    mounted: function () {
        if (this.$getUrlKey("type") == 'phoneBook') {
            this.phoneListDialogShow = true;
        }
    },
    methods: {
        handleCloseDialog: function (bool) {
            this.wechatDialogShow = bool;
            this.phoneListDialogShow = bool;
            this.wechatPublicAccountDialogShow = bool;
            this.baiduMapDialogShow = bool;
        },
        getHelp: function () {
            for (let i = 0; i < 6; i++) {
                setTimeout(() => {
                    this.isHelp = !this.isHelp;
                }, 500 * i);
            }
        },
        search: function () {
            if (this.searchValue == "") {
                this.snackbar = true;
            } else {
                window.open(
                    "https://www.baidu.com/s?wd=site%3Acqwyrc.cn%20" + this.searchValue,
                    "_blank"
                );
            }
        },
        openPhoneListDialog: function () {
            this.phoneListDialogShow = true;
            console.log(111)
        },
    },
};