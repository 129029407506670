<template>
  <div>
    <v-dialog v-if="$vuetify.breakpoint.xl" hide-overlay width="80%" v-model="recruimentHistoryShow"
      transition="dialog-bottom-transition" @click:outside="$emit('update:recruimentHistoryShow', false)">
      <v-card class="beautiful-card">
        <v-card-title class="beautiful-card-title" flat>
          <span class="beautiful-card-title-text"> 搜索招聘信息 </span>
          <v-spacer></v-spacer>
          <close-dialog-button @click-close="$emit('update:recruimentHistoryShow', false)" />
        </v-card-title>
        <v-card-text class="beautiful-card-content-wrapper">
          <v-overlay :value="overlay" opacity="0.05">
            <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
          </v-overlay>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field background-color="#00000015" label="招聘岗位" placeholder="请输入招聘岗位" dark flat solo-inverted
                    hide-details dense v-model="recruitmentQueryParams.recruitmentTitle"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-select background-color="#00000015" label="工作性质" :items="$global.workTypeOptions"
                    item-text="dictLabel" item-value="dictValue" flat hide-details dark solo-inverted dense
                    v-model="recruitmentQueryParams.workType">
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select background-color="#00000015" label="公司类型" :items="$global.companyTypeOptions"
                    item-text="dictLabel" item-value="dictValue" hide-details flat dark solo-inverted dense
                    v-model="recruitmentQueryParams.companyType">
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select background-color="#00000015" label="学历要求" :items="$global.educationalRestrictionsTypeOptions"
                    item-text="dictLabel" item-value="dictValue" hide-details flat dark solo-inverted dense
                    v-model="recruitmentQueryParams.educationalRestrictionsType"></v-select>
                </v-col>
                <v-col class="ma-auto text-center" cols="12" sm="6" md="2">
                  <v-btn text class="custom-btn btn-16 text-center">
                    <svg t="1647245556498" class="icon" viewBox="0 0 1024 1024" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" p-id="2183" width="32" height="32">
                      <path
                        d="M661.333333 597.333333h-33.706666l-11.946667-11.52a277.333333 277.333333 0 0 0 63.146667-227.84c-20.053333-118.613333-119.04-213.333333-238.506667-227.84a277.546667 277.546667 0 0 0-310.186667 310.186667c14.506667 119.466667 109.226667 218.453333 227.84 238.506667a277.333333 277.333333 0 0 0 227.84-63.146667l11.52 11.946667v33.706666l181.333334 181.333334c17.493333 17.493333 46.08 17.493333 63.573333 0 17.493333-17.493333 17.493333-46.08 0-63.573334L661.333333 597.333333z m-256 0C299.093333 597.333333 213.333333 511.573333 213.333333 405.333333S299.093333 213.333333 405.333333 213.333333 597.333333 299.093333 597.333333 405.333333 511.573333 597.333333 405.333333 597.333333z"
                        fill="#717790c7" p-id="2184"></path>
                    </svg>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-simple-table class="beautiful-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-h7">标题</th>
                  <th class="text-h7">工作性质</th>
                  <th class="text-h7">公司类型</th>
                  <th class="text-h7">学历要求</th>
                  <th class="text-h7">薪资范围</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in recruitmentList" :key="item.id" @click="openRecruitmentItem(item.recruitmentId)"
                  class="text-h7 overflow-hidden">
                  <td>{{ item.recruitmentTitle }}</td>
                  <td>{{ item.workType }}</td>
                  <td>{{ item.companyType }}</td>
                  <td>{{ item.educationalRestrictionsType }}</td>
                  <td> {{
                    salaryRangeGenerator(
                      item.salaryType,
                      item.salaryRangeMin,
                      item.salaryRangeMax,
                      item.salaryRangeType
                    )
                  }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions class="beautiful-card-action">
          <div class="text-center">
            <v-pagination color="#3a6df0" v-model="recruitmentQueryParams.pageNum" :length="Math.ceil(total / 10)"
              :total-visible="12" />
          </div>
        </v-card-actions>
      </v-card>
      <recruitmentItem :recruitmentItemShow.sync="recruitmentItemShow" :recruitmentId="recruitmentId" />
    </v-dialog>
    <!-- vuetify.breakpoint.xl -->
    <v-dialog v-else hide-overlay fullscreen v-model="recruimentHistoryShow" transition="dialog-bottom-transition"
      @click:outside="$emit('update:recruimentHistoryShow', false)">
      <v-card class="beautiful-card-mobile">
        <v-card-title class="beautiful-card-title" flat>
          <span class="beautiful-card-title-text"> 搜索招聘信息 </span>
          <v-spacer></v-spacer>
          <close-dialog-button @click-close="$emit('update:recruimentHistoryShow', false)" />
        </v-card-title>
        <v-card-text class="beautiful-card-content-wrapper">
          <v-overlay :value="overlay" opacity="0.05">
            <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
          </v-overlay>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field background-color="#00000015" label="招聘岗位" placeholder="请输入招聘岗位" dark flat solo-inverted
                    hide-details dense v-model="recruitmentQueryParams.recruitmentTitle"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-select background-color="#00000015" label="工作性质" :items="$global.workTypeOptions"
                    item-text="dictLabel" item-value="dictValue" flat hide-details dark solo-inverted dense
                    v-model="recruitmentQueryParams.workType">
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select background-color="#00000015" label="公司类型" :items="$global.companyTypeOptions"
                    item-text="dictLabel" item-value="dictValue" hide-details flat dark solo-inverted dense
                    v-model="recruitmentQueryParams.companyType">
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select background-color="#00000015" label="学历要求" :items="$global.educationalRestrictionsTypeOptions"
                    item-text="dictLabel" item-value="dictValue" hide-details flat dark solo-inverted dense
                    v-model="recruitmentQueryParams.educationalRestrictionsType"></v-select>
                </v-col>
                <v-col class="ma-auto text-center" cols="12" sm="6" md="2">
                  <v-btn text class="custom-btn btn-16 text-center">
                    <svg t="1647245556498" class="icon" viewBox="0 0 1024 1024" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" p-id="2183" width="32" height="32">
                      <path
                        d="M661.333333 597.333333h-33.706666l-11.946667-11.52a277.333333 277.333333 0 0 0 63.146667-227.84c-20.053333-118.613333-119.04-213.333333-238.506667-227.84a277.546667 277.546667 0 0 0-310.186667 310.186667c14.506667 119.466667 109.226667 218.453333 227.84 238.506667a277.333333 277.333333 0 0 0 227.84-63.146667l11.52 11.946667v33.706666l181.333334 181.333334c17.493333 17.493333 46.08 17.493333 63.573333 0 17.493333-17.493333 17.493333-46.08 0-63.573334L661.333333 597.333333z m-256 0C299.093333 597.333333 213.333333 511.573333 213.333333 405.333333S299.093333 213.333333 405.333333 213.333333 597.333333 299.093333 597.333333 405.333333 511.573333 597.333333 405.333333 597.333333z"
                        fill="#717790c7" p-id="2184"></path>
                    </svg>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <div class="home-mobile pt-6">
            <v-list class="list" color="rgb(255 255 255 / 10%)">
              <v-list-item-group>
                <v-list-item v-for="(item, index) in recruitmentList" :key="index"
                  @click="openRecruitmentItem(item.recruitmentId)">
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      <div v-text="item.recruitmentTitle" />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text>
                      {{ salaryRangeGenerator(item.salaryType, item.salaryRangeMin, item.salaryRangeMax,
                        item.salaryRangeType)
                      }}
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card-text>
        <v-card-actions class="beautiful-card-action">
          <div class="text-center">
            <v-pagination color="#3a6df0" v-model="recruitmentQueryParams.pageNum" :length="Math.ceil(total / 10)"
              :total-visible="6" />
          </div>
        </v-card-actions>
      </v-card>
      <recruitmentItem :recruitmentItemShow.sync="recruitmentItemShow" :recruitmentId="recruitmentId" />
    </v-dialog>
  </div>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "./index.mobile.scss";
</style>