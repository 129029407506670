<template>
  <v-container>
    <caption-widget title="岗位招聘" subtitle="Recruitment" />
    <v-row class="py-16" v-if="$vuetify.breakpoint.xl">
      <div class="li px-16 ma-3 overflow-auto app-card app-card-item"
        v-for="(recruitmentItem, recruitmentItemIndex) in recruitmentDataShow" :key="recruitmentItemIndex"
        @click="setRecrutmentItemParameter(recruitmentItem)" v-ripple>
        <h3 class="overflow-auto">
          <v-chip v-if="recruitmentItem.top == 'Y'" color="pink" label text-color="white" small>
            <v-icon left> mdi-fire </v-icon>
            热门
          </v-chip>
          {{ recruitmentItem.recruitmentTitle }}
        </h3>
        <v-spacer></v-spacer>
        <div class="app-card__subtext">
          <div v-if="recruitmentItem.companyType != null">
            <b>公司类型:</b> {{ recruitmentItem.companyType }}
          </div>
          <div v-if="recruitmentItem.positionClassification != null">
            <b>职业分类:</b>{{ recruitmentItem.positionClassification }}
          </div>
          <div v-if="recruitmentItem.workType != null">
            <b>工作类型:</b>{{ recruitmentItem.workType }}
          </div>
          <div v-if="recruitmentItem.educationalRestrictionsType != null">
            <b>学历要求:</b>{{ recruitmentItem.educationalRestrictionsType }}
          </div>
        </div>
        <div class="app-card-buttons">
          <span class="font-weight-bold">
            {{
              salaryRangeGenerator(
                recruitmentItem.salaryType,
                recruitmentItem.salaryRangeMin,
                recruitmentItem.salaryRangeMax,
                recruitmentItem.salaryRangeType
              )
            }}
          </span>
        </div>
      </div>
      <div class="li ma-3 overflow-auto app-card app-card-history" v-ripple @click="openHistoryDialog"
        ref="recruitmentHistoryDialog">
        <div class="text-h4 pa-5">
          <span>查看更多岗位</span>
        </div>
        <v-spacer></v-spacer>
        <div class="text-right">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" t="1646803646516"
            class="icon" viewBox="0 0 1024 1024" version="1.1" p-id="24984" width="130" height="130">
            <path fill="#ffffffcc"
              d="M479 336.3c-78.8 0-143 64.1-143 143 0 78.8 64.1 143 143 143 78.8 0 143-64.1 143-143-0.1-78.9-64.2-143-143-143z"
              p-id="24985" />
            <path fill="#ffffffcc"
              d="M512 0C229.2 0 0 229.2 0 512s229.2 512 512 512 512-229.2 512-512S794.8 0 512 0z m237.5 749.2c-7 7-16.2 10.5-25.5 10.5s-18.4-3.5-25.5-10.5l-94.9-94.9c-36.2 25.9-79.4 39.8-124.7 39.8-57.4 0-111.4-22.4-152-63s-63-94.6-63-152 22.4-111.4 63-152 94.6-63 152-63c57.4 0 111.4 22.4 152 63s63 94.6 63 152c0 45.1-13.8 88.1-39.4 124.1l94.9 94.9c14.1 14.2 14.1 37 0.1 51.1z"
              p-id="24986" />
          </svg>
        </div>
      </div>
    </v-row>
    <!-- $vuetify.breakpoint.xl -->

    <v-row class="py-4" v-else>
      <div class="li px-7 py-4 ma-3 overflow-auto app-card-mini app-card-item"
        v-for="(recruitmentItem, recruitmentItemIndex) in recruitmentMobileDataShow" :key="recruitmentItemIndex"
        @click="setRecrutmentItemParameter(recruitmentItem)" v-ripple>
        <div class="app-card__title">
          <h3 class="">
            <v-icon v-if="recruitmentItem.top == 'Y'" color="pink" left>
              mdi-fire </v-icon>{{ recruitmentItem.recruitmentTitle }}
          </h3>
        </div>
        <v-spacer></v-spacer>
        <div v-if="recruitmentItem.companyType != null">
          <b>公司类型:</b> {{ recruitmentItem.companyType }}
        </div>
        <div v-if="recruitmentItem.positionClassification != null">
          <b>职业分类:</b>{{ recruitmentItem.positionClassification }}
        </div>
        <div v-if="recruitmentItem.workType != null">
          <b>工作类型:</b>{{ recruitmentItem.workType }}
        </div>
        <div v-if="recruitmentItem.educationalRestrictionsType != null">
          <b>学历要求:</b>{{ recruitmentItem.educationalRestrictionsType }}
        </div>
        <div class="app-card-buttons">
          <span class="font-weight-bold">
            {{
              salaryRangeGenerator(
                recruitmentItem.salaryType,
                recruitmentItem.salaryRangeMin,
                recruitmentItem.salaryRangeMax,
                recruitmentItem.salaryRangeType
              )
            }}
          </span>
        </div>
      </div>
      <div class="li pa-4 ma-3 overflow-auto app-card-mini app-card-history-mobile" v-ripple @click="openHistoryDialog"
        ref="recruitmentHistoryDialog">
        <div class="text-h6 pa-3">
          <span>查看更多岗位</span>
        </div>
        <v-spacer></v-spacer>
        <div class="text-right">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" t="1646803646516"
            class="icon" viewBox="0 0 1024 1024" version="1.1" p-id="24984" width="70" height="70">
            <path fill="#ffffffcc"
              d="M479 336.3c-78.8 0-143 64.1-143 143 0 78.8 64.1 143 143 143 78.8 0 143-64.1 143-143-0.1-78.9-64.2-143-143-143z"
              p-id="24985" />
            <path fill="#ffffffcc"
              d="M512 0C229.2 0 0 229.2 0 512s229.2 512 512 512 512-229.2 512-512S794.8 0 512 0z m237.5 749.2c-7 7-16.2 10.5-25.5 10.5s-18.4-3.5-25.5-10.5l-94.9-94.9c-36.2 25.9-79.4 39.8-124.7 39.8-57.4 0-111.4-22.4-152-63s-63-94.6-63-152 22.4-111.4 63-152 94.6-63 152-63c57.4 0 111.4 22.4 152 63s63 94.6 63 152c0 45.1-13.8 88.1-39.4 124.1l94.9 94.9c14.1 14.2 14.1 37 0.1 51.1z"
              p-id="24986" />
          </svg>
        </div>
      </div>
    </v-row>

    <recruitmentHistory :recruimentHistoryShow.sync="historyDialog" />
    <recruitmentItem :recruitmentItemShow.sync="recruitmentItemShow" :recruitmentId="recruitmentId" />
  </v-container>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "./index.mobile.scss";
</style>