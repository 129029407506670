export default {
    name: "hero-section",
    props: {
        isServiceIntersecting: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            timeHelper: false,
        };
    },
    methods: {
        // 控制滚轮一秒滚一次
        getWheell: function (event) {
            if (this.timeHelper == false) {
                this.timeHelper = true;
                if (event.deltaY < 0) {
                    this.$parent.$vuetify.goTo("#main-show");
                } else {
                    this.$parent.$vuetify.goTo("#services");
                }
                setInterval(() => {
                    this.timeHelper = false;
                }, 1000);
            }
        },
    },
};