/*jshint esversion: 8 */


import axios from 'axios';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    //本地调试 
    // baseURL: "http://192.168.18.77:8080/official/",
    //本地生产
    // baseURL: "http://192.168.18.77:18080/prod-api/official/",
    //服务器生产
    baseURL: "http://47.108.77.156:18080/prod-api/official/",
    // 超时
    timeout: 10000
});

service.interceptors.request.use(config => {
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
        let url = config.url + '?';
        for (const propName of Object.keys(config.params)) {
            const value = config.params[propName];
            var part = encodeURIComponent(propName) + "=";
            if (value !== null && typeof (value) !== "undefined") {
                if (typeof value === 'object') {
                    for (const key of Object.keys(value)) {
                        if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                            let params = propName + '[' + key + ']';
                            let subPart = encodeURIComponent(params) + '=';
                            url += subPart + encodeURIComponent(value[key]) + '&';
                        }
                    }
                } else {
                    url += part + encodeURIComponent(value) + "&";
                }
            }
        }
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    return config;
}, error => {
    console.log(error);
    Promise.reject(error);
});


export default service;