/*jshint esversion: 8 */

import request from '../utils/request';

// 查询前10条信息公告
export function listNewsWithDetail(query) {
  return request({
    url: '/news/listNewsWithDetail',
    method: 'get',
    params: query
  });
}

// 查询信息公告列表
export function listNews(query) {
  return request({
    url: '/news/list',
    method: 'get',
    params: query
  });
}

// 查询信息公告详细
export function getNews(id) {
  return request({
    url: '/news/' + id,
    method: 'get'
  });
}  