import { getShowRecruitmentList, findInDict } from "../../api/recruitment";
import { findClassification } from "../../api/classification";
import recruitmentItem from "./recruitment-item/index.vue";
import recruitmentHistory from "./recruitment-history/index.vue";

export default {
    name: "recruitment-section",
    components: { recruitmentItem, recruitmentHistory },
    data() {
        return {
            firstOpen: true,
            recruitmentDataShow: [],
            recruitmentMobileDataShow: [],
            recruitmentId: 0,
            recruitmentItemShow: false,
            historyDialog: false,
        };
    },
    mounted: function () {
        if (this.firstOpen && this.$getUrlKey("type") == 'recruitment') {
            this.recruitmentItemShow = true;
            this.recruitmentId = parseInt(atob(this.$getUrlKey("id")));
            this.firstOpen = false;
        }
        this.getListRecruitmentWithDetail();
    },
    methods: {
        getListRecruitmentWithDetail: function () {
            getShowRecruitmentList().then((response) => {
                let list = [];
                response.data.forEach((item) => {
                    item.positionClassification = findClassification(
                        item.positionClassification
                    );
                    item.companyType = findInDict(
                        this.$global.companyTypeOptions,
                        item.companyType
                    );
                    item.educationalRestrictionsType = findInDict(
                        this.$global.educationalRestrictionsTypeOptions,
                        item.educationalRestrictionsType
                    );
                    item.workType = findInDict(
                        this.$global.workTypeOptions,
                        item.workType
                    );
                    list.push(item);
                });
                this.recruitmentDataShow = list.slice(0, 7);
                this.recruitmentMobileDataShow = list.slice(0, 3);
            });
        },
        setRecrutmentItemParameter: function (recruitmentItem) {
            this.recruitmentId = recruitmentItem.recruitmentId;
            this.recruitmentItemShow = true;
        },
        openHistoryDialog: function () {
            this.historyDialog = true;
        },
        salaryRangeGenerator: function (type, min, max, salaryType) {
            let typeMap = {
                "0": "月薪",
                "1": "日薪",
                "2": "年薪",
                "3": "时薪",
                "4": "周薪",
                "5": "计件",
            };
            switch (salaryType) {
                case 0:
                    return ` ${typeMap[type]} ${min}-${max}`;
                case 1:
                    return `${typeMap[type]} ${min} 以上 `
                case 2:
                    return `${typeMap[type]} ${min} 以下 `
                case 3:
                    return `工资面议 `
                case 4:
                    return `${typeMap[type]} ${min} `
            }
        }
    },
};