<template>
  <v-dialog v-model="phoneListDialogShow" @click:outside="$emit('update:phoneListDialogShow', false)" width="800px"
    :fullscreen="!$vuetify.breakpoint.xl" hide-overlay>
    <v-card class="beautiful-card">
      <v-card-title class="beautiful-card-title" flat>
        <div class="beautiful-card-title-text">咨询电话</div>
        <v-spacer></v-spacer>
        <close-dialog-button @click-close="$emit('update:phoneListDialogShow', false)" />
      </v-card-title>
      <v-card-text class="overflow-auto" :class="$vuetify.breakpoint.xl
        ? 'beautiful-card-content-wrapper'
        : 'beautiful-card-content-wrapper-mobile'
        " :style="$vuetify.breakpoint.xl ? 'max-height: 76vh' : 'max-height: 86vh'
    ">
        <v-simple-table class="beautiful-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">咨询对象</th>
                <th class="text-left">电话号码</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in desserts" :key="i">
                <td>
                  <span>
                    {{ item.name }}
                  </span>
                  <v-tooltip v-model="descShow[i]" top color="primary" v-if="item.desc != null">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small icon v-bind="attrs" v-on="on">
                        <v-icon small color="primary"> mdi-information </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ item.desc }}</span>
                  </v-tooltip>
                </td>
                <td>
                  <span v-for="(phoneItem, index) in item.phone" :key="index">
                    <span v-if="index > 0"> ; </span>
                    <a :href="'tel:' + phoneItem.number">{{ phoneItem.show }}</a>
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-text>
        <div class="hint">*以上电话均为重庆地区电话,区号为 023</div>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>