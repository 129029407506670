import { getRecruitment, findInDict } from "@/api/recruitment";
import { findClassification } from "@/api/classification";
import { getQrByName } from "@/api/qr";

export default {
  name: "official-recruitment",
  props: {
    recruitmentId: {
      type: Number,
      default: null,
    },
    recruitmentItemShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recruitmentItemRes: {},
      recruitmentItemHead: [],
      wechatQr: null,
      overlay: true,
      isIntersecting: true,
      isFirst: true,
      snackbar: true,
      snackbarTitle: "",
    };
  },
  watch: {
    recruitmentId: function (val) {

      if (val) {
        let url = this.$official + "?type=recruitment&id=" + btoa(this.recruitmentId);
        this.snackbarTitle = url;
        this.getListRecruitmentItem();
      }
    },
  },
  methods: {
    getLength: function Number(value) {
      if (value != null) {
        let valueStr = "" + value;
        if (valueStr.length <= 10) {
          return 3;
        } else if (valueStr.length <= 30) {
          return 6;
        } else {
          return 12;
        }
      } else {
        return 1;
      }
    },
    getListRecruitmentItem: function () {
      getRecruitment(this.recruitmentId).then((response) => {
        this.recruitmentItemRes = response.data.data;
        this.recruitmentItemHead = [
          {
            label: "工作性质",
            value: findInDict(
              this.$global.workTypeOptions,
              this.recruitmentItemRes.workType
            ),
          },
          {
            label: "公司类型",
            value: findInDict(
              this.$global.companyTypeOptions,
              this.recruitmentItemRes.companyType
            ),
          },
          {
            label: "职业分类",
            value: findClassification(
              this.recruitmentItemRes.positionClassification
            ),
          },
          {
            label: "学历要求",
            value: findInDict(
              this.$global.educationalRestrictionsTypeOptions,
              this.recruitmentItemRes.educationalRestrictionsType
            ) == '无' ? '' : findInDict(
              this.$global.educationalRestrictionsTypeOptions,
              this.recruitmentItemRes.educationalRestrictionsType
            ),
          },
          {
            label: "工作经验",
            value: this.recruitmentItemRes.workingAgeLimit,
          },
          {
            label: "上班地点",
            value: this.recruitmentItemRes.workAddress,
          },
          {
            label: "最低薪资",
            value: this.recruitmentItemRes.salaryRangeMin,
          },
          {
            label: "最高限制",
            value: this.recruitmentItemRes.salaryRangeMax,
          },
          {
            label: "招聘人数",
            value: this.recruitmentItemRes.recruits == '' ? null : this.recruitmentItemRes.recruits,
          },
        ];
        if (
          this.recruitmentItemRes.wechatQrId != null &&
          this.recruitmentItemRes.wechatQrId != ""
        ) {
          getQrByName(this.recruitmentItemRes.wechatQrId).then(
            (wechatQrRes) => {
              if (wechatQrRes.data != null && wechatQrRes.data != "") {
                this.wechatQr = wechatQrRes.data;
              }
            }
          );
        }
        this.overlay = false;
      });
    },
    onIntersect: function (entries) {
      if (this.isFirst) {
        this.isFirst = !this.isFirst;
        return;
      }
      this.isIntersecting = entries[0].isIntersecting;
    },
    shareAction: function () {
      // this.snackbar = !this.snackbar;
      // let target = this.$vuetify.breakpoint.xl?this.$refs.banner:this.$refs.bannerM;
      let container = this.$vuetify.breakpoint.xl ? ".recruitment-box" : ".recruitment-box-mobile";
      let target = 10000;
      let options = {
        duration: 2000,
        offset: 0,
        easing: "easeInOutCubic",
        container: container
      }
      this.$vuetify.goTo(target, options);
    }
  },
};