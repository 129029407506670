var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"partner-bg"},[_c('caption-widget',{attrs:{"dark":"","title":"合作伙伴","subtitle":"Partner"}}),_c('div',{staticClass:"text-center py-10 panter-url-box"},[(_vm.$vuetify.breakpoint.xl)?_c('vue-seamless-scroll',{staticClass:"warp pa-5",attrs:{"data":_vm.partnerItem,"class-option":_vm.classOption}},[_c('ul',{staticClass:"ul-item"},_vm._l((_vm.partnerItem),function(num,index){return _c('li',{key:index,staticClass:"li-item mr-5"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"},{name:"tilt",rawName:"v-tilt",value:({
            'max-glare': 0.8,
            glare: true,
            scale: 0.95,
            speed: 1000,
            transition: true,
            reverse: true,
            max: 10,
            transition: true,
          }),expression:"{\n            'max-glare': 0.8,\n            glare: true,\n            scale: 0.95,\n            speed: 1000,\n            transition: true,\n            reverse: true,\n            max: 10,\n            transition: true,\n          }"}],staticClass:"gallery-div",on:{"contextmenu":function($event){$event.preventDefault();$event.stopPropagation();return (() => { }).apply(null, arguments)}}},[_c('div',{staticClass:"gallery pa-1 ma-2"},[_c('v-img',{attrs:{"max-height":"130px","max-width":"280","contain":"","src":_vm.$nginxUrl + 'parnter/' + num.url}})],1)])])}),0)]):_c('v-slide-group',{staticClass:"pa-4"},_vm._l((_vm.partnerItem.length / 3),function(item,n){return _c('v-slide-item',{key:n},[_c('div',[_c('v-img',{staticClass:"ma-3 partner-card",attrs:{"aspect-ratio":16 / 9,"src":_vm.$nginxUrl + 'parnter/' + _vm.partnerItem[3 * n].url,"width":"150px","contain":""}}),(_vm.partnerItem[3 * n + 1].url != null)?_c('v-img',{staticClass:"ma-3 partner-card",attrs:{"aspect-ratio":16 / 9,"src":_vm.$nginxUrl + 'parnter/' + _vm.partnerItem[3 * n + 1].url,"width":"120px","contain":""}}):_vm._e(),(_vm.partnerItem[3 * n + 2].url != null)?_c('v-img',{staticClass:"ma-3 partner-card",attrs:{"aspect-ratio":16 / 9,"src":_vm.$nginxUrl + 'parnter/' + _vm.partnerItem[3 * n + 2].url,"width":"120px","contain":""}}):_vm._e()],1)])}),1),_c('v-divider'),_c('div',{staticClass:"text-h4 py-5 panter-url"},[_vm._v("友情链接")]),_c('v-row',{staticClass:"px-10 py-4"},_vm._l((_vm.partnerUrl),function(item,index){return _c('v-col',{directives:[{name:"ripple",rawName:"v-ripple"}],key:index,staticClass:"side-menu",attrs:{"cols":"12","sm":"3"},on:{"click":function($event){return _vm.navigetTo(item.url)}}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }