<template>
  <div>
    <div class="background" v-if="$vuetify.breakpoint.xl" @wheel.prevent="getWheell">
      <div class="main-show">
        <div class="main-frame">
          <div class="main-frame-childen" v-ripple @click="$vuetify.goTo('#services')">
            <div class="hero">
              <div class="hero-title">
                <div>
                  <div class="title-main text-h1 font-weight-bold py-3">
                    重庆万友人才服务有限公司
                  </div>
                  <div class="english-title text-h4 transform10">
                    Chongqing Wanyou Talents Service Co.,Ltd
                  </div>
                </div>
                <div class="text-h3 title-subtitle transform10 py-2">
                  <span> 重庆市人才交流服务中心万友分中心 </span>
                </div>
              </div>
              <div class="cube"></div>
              <div class="cube"></div>
              <div class="cube"></div>
              <div class="cube"></div>
              <div class="cube"></div>
              <div class="cube"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- breakpoint.xl -->
    <div class="main-show-mobile" v-if="!$vuetify.breakpoint.xl">
      <div class="hero" @click="$vuetify.goTo('#services')">
        <div class="hero-title text-center">
          <div>
            <div class="title-main text-h3 py-3">重庆万友人才服务有限公司</div>
            <div class="english-title text-h5 py-3">
              Chongqing Wanyou Talents Service Co.,Ltd
            </div>
          </div>
          <div class="text-h5 title-subtitle py-2">
            <span> 重庆市人才交流服务中心万友分中心 </span>
          </div>
        </div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
      </div>
      <div class="arrow bounce" :class="{ 'arrow-show': isServiceIntersecting }"></div>
    </div>
  </div>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "./index.mobile.scss";
</style>