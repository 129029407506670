import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0928528b&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=0928528b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../.yarn/__virtual__/vue-loader-virtual-c467dc4293/0/cache/vue-loader-npm-15.10.1-3a0b6c19d2-828dd30ec4.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0928528b",
  null
  
)

export default component.exports