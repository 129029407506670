<template>
  <div>
    <v-dialog v-if="$vuetify.breakpoint.xl" hide-overlay max-width="80%" v-model="newsItemShow"
      @click:outside="$emit('update:newsItemShow', false)">
      <v-card class="beautiful-card" elevation="0">
        <v-card-title class="beautiful-card-title" flat>
          <div class="beautiful-card-title-box">
            {{ newsItem.newsInitialDt }}
          </div>
          <span class="beautiful-card-title-text">{{
            newsItem.newsTitle
          }}</span>
          <v-spacer></v-spacer>
          <close-dialog-button @click-close="$emit('update:newsItemShow', false)" />
        </v-card-title>
        <v-card-text class="beautiful-card-content-wrapper news-box">
          <v-overlay :value="overlay" opacity="0.05">
            <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
          </v-overlay>
          <v-container class="pa-8">
            <div v-html="newsItem.newsContent" />
          </v-container>
          <v-banner v-model="snackbar" transition="slide-y-transition" class="text-right" ref="banner">
            <div class="font-weight-bold color-primary">复制链接分享</div>
            <div class="text--secondary">{{ snackbarTitle }}</div>
          </v-banner>
        </v-card-text>
        <v-card-actions class="beautiful-card-action">
          <v-spacer></v-spacer>
          <v-btn :href="$nginxFileUrl + item" v-for="(item, i) in newsItem.newsFile" :key="i" rounded color="#3a6df0" dark
            elevation="0">
            <v-icon> mdi-file </v-icon>
            <span class="px-4 py-2">附件 {{ i + 1 }}</span>
          </v-btn>
          <v-btn class="ma-1" plain dark rounded @click="shareAction" color="primary">
            <v-icon dark> mdi-share </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--  $vuetify.breakpoint.xl -->
    <v-dialog v-else hide-overlay v-model="newsItemShow" @click:outside="$emit('update:newsItemShow', false)" fullscreen>
      <v-card class="beautiful-card-mobile" elevation="0">
        <v-card-title class="beautiful-card-title" flat>
          <div class="beautiful-card-title-box">
            {{ newsItem.newsInitialDt }}
          </div>
          <span class="beautiful-card-title-text">{{
            newsItem.newsTitle
          }}</span>
          <v-spacer></v-spacer>
          <close-dialog-button @click-close="$emit('update:newsItemShow', false)" />
        </v-card-title>
        <v-card-text class="beautiful-card-content-wrapper news-box-mobile">
          <v-overlay :value="overlay" opacity="0.05">
            <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
          </v-overlay>
          <v-btn absolute right icon @click="shareAction" color="primary">
            <v-icon dark> mdi-share </v-icon>
          </v-btn>
          <v-container :class="$vuetify.breakpoint.xl ? 'pa-8' : 'pa-4'">
            <div v-html="newsItem.newsContent" />
          </v-container>
          <v-banner v-model="snackbar" transition="slide-y-transition" class="text-right" ref="bannerM">
            <div class="font-weight-bold color-primary">长按链接复制分享</div>
            <div class="text--secondary">{{ snackbarTitle }}</div>
          </v-banner>
        </v-card-text>
        <v-card-actions class="beautiful-card-action overflow-x-auto">
          <v-spacer></v-spacer>
          <v-btn color="#3a6df0" large rounded dark @click.stop="downloadListDialog = true">
            <span class="px-5">附件列表</span>
          </v-btn>
          <v-dialog v-model="downloadListDialog" hide-overlay max-width="350">
            <v-card>
              <v-card-title class="text-h5">
                附件列表
              </v-card-title>
              <v-list rounded>
                <v-subheader>单击下载</v-subheader>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(fileName, i) in newsItem.newsFile" :key="i">
                    <v-list-item-content>
                      <v-list-item-title class="text-caption color:#3a6df0" @click="download(fileName)">
                        {{ fileName.split("/").pop() }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="#3a6df0">mdi-download-circle</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded dark color="#3a6df0" @click="downloadListDialog = false">
                  关闭
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import index from "./index";
export default index;
</script>
