<template>
  <div>
    <div class="caption-bg" v-if="$vuetify.breakpoint.xl">
      <div class="pt-12 wb">
        <div class="text-h2 font-weight-medium caption" :class="{ dark: dark }">
          <span>
            {{ title }}
          </span>
        </div>
        <div>
          <span class="wb-line wb-line-1"></span>
          <span class="wb-line wb-line-2"></span>
        </div>
        <div>
          <span class="unit-subtitle pl-7" :class="{ dark: dark }">
            {{ subtitle }}
          </span>
        </div>
        <div>
          <span class="wb-line wb-line-3"></span>
        </div>
      </div>
    </div>
    <!-- breakpoint.xl -->
    <div class="pa-5 wb-mobile text-center caption-off" :class="{ 'caption-show': !isCaptionIntersecting }" v-else
      v-intersect="captionOnIntersect">
      <div class="text-h4" :class="{ dark: dark }">
        <span>
          {{ title }}
        </span>
      </div>
      <div>
        <span class="text-subtitle-1" :class="{ dark: dark }">
          {{ subtitle }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "./index.mobile.scss";
</style>