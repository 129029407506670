export default {
    name: "company-qualification",
    components: {},
    data: function () {
        return {
            qualificationItem: [
                {
                    title: "2017年度最佳合作单位",
                    subtitle: "重庆中建西部建设有限公司",
                    url: "2017 Best Cooperation Unit.jpg",
                    time: "二零一八年三月",
                },
                {
                    title: "2018年度成长型企业50强",
                    subtitle: "中共重庆市渝中区委、重庆市渝中区人民政府",
                    url: "Top 50 Growth Companies.jpg",
                    time: "二〇一九年三月",
                },
                {
                    title: "2015-2016年重庆市人力资源诚信服务示范单位",
                    subtitle: "重庆市人力资源和社会保障局",
                    url: "Integrity Service Demonstration Institution.jpg",
                    time: "2017年6月2日",
                },
                {
                    title: "重庆市中小商贸流通企业公共服务平台服务机构",
                    subtitle: "重庆市中小商贸流通企业服务中心",
                    url: "Service organization.jpg",
                    time: "二〇一五年",
                },
                {
                    title: "重庆市人力资源协会副会长单位",
                    subtitle: "ChongQing Human Resourece Association",
                    url: "Vice President Unit.jpg",
                    time: "",
                },
                {
                    title: "工人先锋号",
                    subtitle: "重庆市渝中区总工会",
                    url: "Worker Pioneer.jpg",
                    time: "二〇一七年四月",
                },
                {
                    title: "二〇一四年重庆市人力资源服务机构诚信示范单位",
                    subtitle: "重庆人力资源协会、华龙网",
                    url: "Integrity Demonstration Unit of Human Resources Service Organization.jpg",
                    time: "二〇一四年六月十二日",
                },
                {
                    title: "2016-2017年度人力资源服务机构十强(派遣外包类)",
                    subtitle: "重庆市人才研究和人力资源服务协会",
                    url: "Top Ten Human Resources Service Organizations.jpg",
                    time: "二〇一八年",
                },
                {
                    title: "二〇一五年度重庆市十佳人力资源服务机构",
                    subtitle: "重庆市人力资源协会",
                    url: "Top Ten Human Resources Service Organizations 2015.jpg",
                    time: "二〇一六年四月",
                },
                {
                    title: "先进基层党组织",
                    subtitle: "中共重庆市社会流动人才市场委员会",
                    url: "Advanced grassroots party organization.jpg",
                    time: "二〇一三年六月",
                },
                {
                    title: "优秀供应商",
                    subtitle: "重庆中建西部建设有限公司",
                    url: "Excellent supplier.jpg",
                    time: "二零一六年十二月",
                },
                {
                    title: "渝中区定点职业培训机构",
                    subtitle: "重庆市渝中区就业和人才服务局",
                    url: "Designated Vocational Training Institutions in Yuzhong District.jpg",
                    time: "二〇一八年一月",
                },
                {
                    title: "质量管理体系认证证书",
                    subtitle: "中标联合(北京)认证有限公司",
                    url: "Quality management system certification.jpg",
                    time: "",
                },
                {
                    title: "环境管理体系认证证书",
                    subtitle: "中标联合(北京)认证有限公司",
                    url: "ISO14001.jpg",
                    time: "",
                },
                {
                    title: "职业健康安全管理体系认证证书",
                    subtitle: "中标联合(北京)认证有限公司",
                    url: "ISO45001.jpg",
                    time: "",
                },
                {
                    title: "重庆市高校毕业生就业见习基地",
                    subtitle:
                        "重庆市人力资源和社会保障局,重庆市就业再就业工作领导小组办公室,重庆市财政局",
                    url: "Chongqing University Graduate Employment Training Base.jpg",
                    time: "",
                },
                {
                    title: "重庆市人力资源协会劳务派遣分会",
                    subtitle:
                        "Labour Dispatch Services Branch of Chongqing Human Resource Association",
                    url: "Labor Dispatch Branch of Chongqing Human Resources Association.jpg",
                    time: "",
                },
                {
                    title: "企业信用等级证书AAA",
                    subtitle: "华夏众众(北京)国际信用评价有限公司",
                    url: "CERTIFICATE OF ENTERPRISE CREDIT GRADE.jpg",
                    time: "",
                },
            ],
            dialog: [],
            mobileDialog: false,
            dialogUrl: '',
        };
    },
    methods: {
        openDialog(url) {
            this.mobileDialog = !this.mobileDialog
            this.dialogUrl = url
        }
    },
};