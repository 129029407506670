export default {
  name: "caption-widget",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCaptionIntersecting: false,
    }
  },
  methods: {
    captionOnIntersect: function (entries) {
      this.isCaptionIntersecting = entries[0].isIntersecting;
    },
  },
};