<template>
  <div>
    <v-dialog v-if="$vuetify.breakpoint.xl" hide-overlay width="60%" v-model="historyNewsShow"
      transition="dialog-bottom-transition" @click:outside="$emit('update:historyNewsShow', false)">
      <v-card class="beautiful-card" elevation="0">
        <v-card-title class="beautiful-card-title" flat>
          <span class="beautiful-card-title-text"> 历史信息公告 </span>
          <v-spacer></v-spacer>
          <v-text-field dense dark solo-inverted flat hide-details placeholder="点击搜索" class="beautiful-card-title-box"
            v-model="queryParams.newsTitle">
          </v-text-field>
          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" plain rounded>
                选择月份:{{ date }}
              </v-btn>
            </template>
            <v-date-picker v-model="date" no-title scrollable color="accent" type="month" locale="cn">
              <v-spacer></v-spacer>
              <v-btn depressed plain rounded color="accent" @click="reset">
                重置
              </v-btn>
              <v-btn depressed outlined rounded color="accent" @click="changeDate(date)">
                确定
              </v-btn>
            </v-date-picker>
          </v-menu>
          <close-dialog-button @click-close="$emit('update:historyNewsShow', false)" />
        </v-card-title>
        <v-card-text class="beautiful-card-content-wrapper">
          <v-overlay :value="overlay" opacity="0.05">
            <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
          </v-overlay>
          <v-simple-table class="beautiful-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-h6">标题</th>
                  <th class="text-h6">时间</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in newsList" :key="item.id" @click="openNewsItem(item.id)"
                  class="text-h7 overflow-hidden">
                  <td>{{ item.newsTitle }}</td>
                  <td>{{ item.newsInitialDt }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="beautiful-card-action">
          <v-pagination color="#3a6df0" v-model="queryParams.pageNum" :length="Math.ceil(total / 10)"></v-pagination>
        </v-card-actions>
      </v-card>
      <homeNewsItem :newsItemShow.sync="newsItemShow" :newsId="newsId" />
    </v-dialog>
    <!--  $vuetify.breakpoint.xl -->

    <v-dialog v-else hide-overlay v-model="historyNewsShow" transition="dialog-bottom-transition"
      @click:outside="$emit('update:historyNewsShow', false)" fullscreen>
      <v-card class="beautiful-card-mobile" elevation="0">
        <v-card-title class="beautiful-card-title" flat>
          <span class="beautiful-card-title-text-mobile"> 历史信息公告 </span>
          <v-spacer></v-spacer>
          <v-text-field dense dark solo-inverted flat hide-details placeholder="点击搜索"
            class="beautiful-card-title-box-mobile" v-model="queryParams.newsTitle">
          </v-text-field>
          <v-dialog ref="dialog" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
            transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" plain rounded>
                {{ date }}
              </v-btn>
            </template>
            <v-date-picker v-model="date" scrollable color="accent" type="month" locale="cn">
              <v-spacer></v-spacer>
              <v-btn depressed plain rounded color="accent" @click="reset">
                重置
              </v-btn>
              <v-btn depressed outlined rounded color="accent" @click="changeDate(date)">
                确定
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <close-dialog-button @click-close="$emit('update:historyNewsShow', false)" />
        </v-card-title>
        <v-card-text class="beautiful-card-content-wrapper">
          <v-overlay :value="overlay" opacity="0.05">
            <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
          </v-overlay>
          <v-simple-table class="beautiful-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-h6">标题</th>
                  <th class="text-h6">时间</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in newsList" :key="item.id" @click="openNewsItem(item.id)"
                  class="text-caption overflow-hidden">
                  <td style="width: 65%">{{ item.newsTitle }}</td>
                  <td>{{ item.newsInitialDt }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="beautiful-card-action">
          <v-pagination color="#3a6df0" v-model="queryParams.pageNum" :length="Math.ceil(total / 10)"
            :total-visible="6"></v-pagination>
        </v-card-actions>
      </v-card>
      <homeNewsItem :newsItemShow.sync="newsItemShow" :newsId="newsId" />
    </v-dialog>
  </div>
</template>

<script>
import index from "./index";
export default index;
</script>
