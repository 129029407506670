<template>
  <div>
    <div class="d-flex flex-wrap justify-center text-center py-1" @contextmenu.prevent.stop="() => { }"
      v-if="$vuetify.breakpoint.xl">
      <v-dialog v-model="dialog[index]" max-width="1100" v-for="(item, index) in qualificationItem" :key="index">
        <template v-slot:activator="{ on, attrs }">
          <div v-tilt="{
            speed: 1000,
            transition: true,
            perspective: 2000,
            reverse: true,
            max: 10,
            glare: true,
            scale: 1.05,
          }" class="ma-2">
            <v-card class="mx-auto" width="304" min-height="400" hover v-bind="attrs" v-on="on">
              <v-img :src="$nginxUrl + 'company/qualifications/low/' + item.url" height="200px" />
              <v-card-subtitle class="qualification-card-title">
                {{ item.title }}
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-subtitle class="qualification-card-submit">
                {{ item.subtitle }}
              </v-card-subtitle>
              <v-card-text class="qualification-card-time">
                {{ item.time }}
              </v-card-text>
            </v-card>
          </div>
        </template>
        <v-card @contextmenu.prevent.stop="() => { }">
          <v-img :src="$nginxUrl + 'company/qualifications/low/' + item.url">
            <div v-for="t in 16" :key="t">
              <div v-for="l in 6" :key="l">
                <div :class="'watermark-' + t + '-' + l + ' text-h4'">
                  <span>万友人才</span>
                </div>
              </div>
            </div>
          </v-img>
        </v-card>
      </v-dialog>
    </div>
    <!-- $vuetify.breakpoint.xl? -->
    <div class="
        d-flex
        flex-wrap
        justify-center
        text-center
        py-1
        mobile-qualification
      " @contextmenu.prevent.stop="() => { }" v-else>
      <v-hover v-slot="{ hover }" v-for="(item, index) in qualificationItem" :key="index">
        <v-card class="mobile-card pa-1 ma-1" @click="openDialog(item.url)">
          <v-img :aspect-ratio="16 / 9" :src="$nginxUrl + 'company/qualifications/low/' + item.url">
            <v-expand-transition>
              <div v-if="hover" class="
                  hover-box
                  d-flex
                  transition-fast-in-fast-out
                  v-card--reveal
                  white--text
                " style="height: 100%">
                <div class="font-weight-light write--text body-1 pa-1">
                  <div>{{ item.title }}</div>
                </div>
              </div>
            </v-expand-transition>
          </v-img>
        </v-card>
      </v-hover>
      <v-dialog v-model="mobileDialog">
        <v-card @contextmenu.prevent.stop="() => { }">
          <v-img :src="$nginxUrl + 'company/qualifications/low/' + dialogUrl">
            <div v-for="t in 5" :key="t">
              <div v-for="l in 5" :key="l">
                <div :class="'watermark-' + t + '-' + l + ' text-h4'">
                  <span>万友人才</span>
                </div>
              </div>
            </div>
          </v-img>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "./index.mobile.scss";
</style>