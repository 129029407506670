<template>
  <div>
    <v-container>
      <caption-widget title="信息公告" subtitle="News / Announcement" />
      <div class="home pt-12" v-if="$vuetify.breakpoint.xl">
        <div class="popular-articles">
          <div class="mini-card-grid">
            <!-- 标准卡片 -->
            <v-container class="mini-card" v-for="(item, index) in newsList" :key="index" @click="openNewsItem(item.id)"
              v-ripple="{ class: `accent--text` }">
              <h3 class="h6 pa-1">
                <p>{{ item.newsTitle }}</p>
              </h3>
              {{ item.newsSubtitle }}
              <div class="description">
                <p>{{ item.newsDesc }}</p>
              </div>
              <v-spacer></v-spacer>
              <div class="my-2">
                <v-chip v-if="item.newsIsTop == 'Y'" color="pink" label text-color="white">
                  <v-icon left> mdi-fire </v-icon>
                  置顶
                </v-chip>
              </div>
              <div class="date pa-1">
                <span>{{ item.newsInitialDt }}</span>
              </div>
            </v-container>
            <!-- 历史信息公告列表 -->
            <v-container class="mini-card history" v-ripple @click="historyNewsShow = true">
              <div class="text-h4 pa-5">
                <p>查看历史信息公告</p>
              </div>
              <v-spacer></v-spacer>
              <div class="text-right pt-16">
                <svg t="1643185903424" class="icon" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="5928" width="200" height="200">
                  <path
                    d="M512 96c229.76 0 416 186.24 416 416s-186.24 416-416 416S96 741.76 96 512 282.24 96 512 96z m42.752 244.864a17.066667 17.066667 0 0 0-24.149333 0l-21.12 21.12a17.066667 17.066667 0 0 0 0 24.106667l92.16 92.16H315.434667a17.066667 17.066667 0 0 0-17.066667 17.066666v29.866667c0 9.386667 7.68 17.066667 17.066667 17.066667h288.213333l-94.208 94.293333a17.066667 17.066667 0 0 0 0 24.106667l21.12 21.12a17.066667 17.066667 0 0 0 24.149333 0l156.885334-156.885334a17.066667 17.066667 0 0 0 4.949333-13.568 17.066667 17.066667 0 0 0-4.949333-13.568z"
                    p-id="5929" fill="#ffffffcc" data-spm-anchor-id="a313x.7781069.0.i1" class=""></path>
                </svg>
              </div>
            </v-container>
          </div>
        </div>
      </div>
      <!-- vuetify.breakpoint.xl -->
      <div v-else class="home-mobile pt-6">
        <v-list class="list" color="rgb(255 255 255 / 70%)">
          <v-list-item-group>
            <v-list-item v-for="item in newsListMobile" :key="item.title" @click="openNewsItem(item.id)">
              <v-list-item-content>
                <v-list-item-title>
                  <v-chip v-if="item.newsIsTop == 'Y'" color="pink" label small text-color="white">
                    <v-icon small left> mdi-fire </v-icon>
                    置顶
                  </v-chip>
                  {{ item.newsTitle }}
                </v-list-item-title>
                <v-list-item-subtitle class="text--primary">
                  <div v-text="item.newsSubtitle" />
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <div v-text="item.newsDesc" />
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>
                  <div v-text="item.newsInitialDt" />
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
            <v-divider />
            <v-list-item @click="historyNewsShow = true">
              <v-list-item-content />
              <v-list-item-action>
                <v-list-item-action-text>查看历史信息公告</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
    </v-container>
    <homeNewsHistory :historyNewsShow.sync="historyNewsShow" />
    <homeNewsItem :newsItemShow.sync="newsItemShow" :newsId="newsId" />
  </div>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "./index.mobile.scss";
</style>