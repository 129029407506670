<template>
  <div>
    <v-hover v-slot="{ hover }">
      <svg @click="$emit('click-close', true)" t="1648188331105" class="icon" viewBox="0 0 1024 1024" version="1.1"
        xmlns="http://www.w3.org/2000/svg" p-id="2671" width="20" height="20" data-spm-anchor-id="a313x.7781069.0.i9">
        <path
          d="M512 128C300.8 128 128 300.8 128 512s172.8 384 384 384 384-172.8 384-384S723.2 128 512 128zM672 627.2c12.8 12.8 12.8 32 0 44.8s-32 12.8-44.8 0L512 556.8l-115.2 115.2c-12.8 12.8-32 12.8-44.8 0s-12.8-32 0-44.8L467.2 512 352 396.8C339.2 384 339.2 364.8 352 352s32-12.8 44.8 0L512 467.2l115.2-115.2c12.8-12.8 32-12.8 44.8 0s12.8 32 0 44.8L556.8 512 672 627.2z"
          p-id="2672" :fill="hover ? '#ff4135' : '#f96057'"></path>
      </svg>
    </v-hover>
  </div>
</template>


<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>