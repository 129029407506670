<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <v-btn v-bind="attrs" v-on="on" class="btn-location button2 b-blue rot-135" fixed fab dark large text
          :class="{ btnHover: hover, btnShow: isHeroIntersecting }" v-on:click="$emit('getHelp')">
          <v-icon> mdi-help </v-icon>
        </v-btn>
      </v-hover>
    </template>
    <span class="text-h6">帮助</span>
  </v-tooltip>
</template>


<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>