<template>
  <v-app id="inspire">
    <v-navigation-drawer v-if="!$vuetify.breakpoint.xl" v-model="drawer" :clipped="$vuetify.breakpoint.xl" app>
      <v-list shaped nav>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in indexModules" :key="i" link @click="goToTool(item.id)">
            <v-list-item-content>
              <v-list-item-title class="pl-7 text-h6">
                {{ item.value }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar fixed app elevate-on-scroll hide-on-scroll :clipped-left="$vuetify.breakpoint.xl"
      color="rgba(255,255,255,0.6)">
      <v-container>
        <v-row align="center" justify="space-between">
          <v-col class="d-flex align-center">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="!$vuetify.breakpoint.xl" />
            <v-toolbar-title style="cursor: pointer" class="font-weight-bold headline">
              <v-container class="d-flex align-center" @click="goToTool(`main-show`)">
                <v-img @click="partnerEasterEggs++" :src="$nginxUrl + 'logo/logo-mini.png'" width="32" class="ma-4" />
                <span class="app-title">万友人才</span>
              </v-container>
            </v-toolbar-title>
          </v-col>
          <v-col class="text-right" v-if="$vuetify.breakpoint.xl">
            <v-btn text color="primary" plain v-for="(item, i) in indexModules" :key="i" @click="goToTool(item.id)"
              class="text-subtitle-1 font-weight-medium">
              <div class="text-h7">
                {{ item.value }}
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main>
      <hero-section id="main-show" v-intersect="heroOnIntersect" :isServiceIntersecting="isServiceIntersecting" />
      <div class="bg-slider-left-dec" v-intersect="serviceOnIntersect">
        <services-section id="services" />
        <news-section id="news" />
        <staff-section id="staff" />
      </div>

      <br />
      <overview-section id="overview" class="bg-services-contact" />
      <br />
      <section v-if="partnerEasterEggs >= 2" :class="partnerEasterEggs >= 2 ? 'bg-videos-partner' : 'bg-videos'">
        <recruitment-section id="recruitment" ref="recruitment" />
        <br />
        <v-expand-transition>
          <partner-section v-if="partnerEasterEggs >= 2" id="partner" />
        </v-expand-transition>
      </section>
    </v-main>
    <floating-help-button @getHelp="getHelp" :isHeroIntersecting="isHeroIntersecting" />
    <footer-section id="contact-us" ref="officialFooter" />
  </v-app>
</template>

<script>
import appJs from "./App";
export default appJs;
</script>

<style lang='scss'>
@import "./App.scss";
</style>
