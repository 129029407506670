import vueSeamlessScroll from "vue-seamless-scroll";

export default {
    name: "partner-section",
    components: { vueSeamlessScroll },
    data() {
        return {
            partnerItem: [
                {
                    name: "T3出行",
                    url: "T3.png",
                    desc: "T3出行是南京领行科技股份有限公司打造的智慧出行生态平台。公司以“成为最值得信赖的出行服务企业”为愿景，“科技引领 愉悦出行”为使命，致力于成为能够为客户和政府提供“安全、便捷、品质”出行服务的科技创新型企业。",
                    color: "white",
                },
                {
                    name: "兄弟(中国)商业有限公司",
                    url: "brother.png",
                    desc: " 兄弟(中国)商业有限公司成立于2005年，是Brother集团设立在中国的销售与服务公司。依托集团旗下分设于深圳、珠海、台湾等地的生产工厂，以及在杭州设立的开发公司，在国内实现了开发、生产、销售三位一体的供应体制，用高质量的产品与服务为中国顾客提供优良的价值。",
                    color: "grey darken-1",
                },
                {
                    name: "重庆长安民生物流股份有限公司",
                    url: "camsl.png",
                    desc: "重庆长安民生物流股份有限公司（简称长安民生物流）是专业的智慧物流与供应链平台服务商。公司成立于2001年8月，是香港上市的汽车物流企业，注册资本1.62亿元。主要股东为中国长安汽车集团有限公司、民生实业（集团）有限公司、美集物流有限公司。",
                    color: "white",
                },
                {
                    name: "重庆长江电工工业集团有限公司",
                    url: "CCEA.png",
                    desc: "重庆长江电工工业集团有限公司是隶属于中国兵器装备集团公司的大型国有企业，是国家批准重点保留军品生产能力的骨干企业之一。公司创建于1905年，迄今已有一百多年的悠久历史，是重庆最早成立的工业企业，1930年步入中国兵器工业企业行列，2011年更名为“重庆长江电工工业集团有限公司”。",
                    color: "white",
                },
                {
                    name: "浙江嘉科新能源科技有限公司",
                    url: "CETC.png",
                    desc: "浙江嘉科新能源科技有限公司（嘉科新能源）是中国电子科技集团公司（中国电科）第三十六研究所（三十六所）全资子公司，注册资金3000万，是国家高新技术企业。",
                    color: "white",
                },
                {
                    name: "重庆铃耀汽车有限公司",
                    url: "Changan Suzuki.png",
                    desc: "重庆铃耀汽车有限公司于1993年05月25日成立。法定代表人刘正均，公司经营范围包括：一般项目：生产、销售自产轿车、发动机及其零部件，销售自产产品，提供有关售后服务，并从事有关研究开发工作等。",
                    color: "white",
                },
                {
                    name: "长安汽车是中国汽车",
                    url: "changan.png",
                    desc: "长安汽车是中国汽车四大集团阵营企业，拥有159年历史底蕴、37年造车积累，全球有14个生产基地，33个整车、发动机及变速器工厂。2014年，长安系中国品牌汽车销量累计突破1000万辆。2020年，长安系中国品牌汽车销量累计突破2000万辆。",
                    color: "white",
                },
                {
                    name: "华夏航空股份有限公司",
                    url: "chinaexpressair.png",
                    desc: " 华夏航空股份有限公司（以下简称“华夏航空”）成立于2006年，是中国支线航空商业模式的引领者和主要践行者，是目前中国唯一长期专注于支线的独立航空公司。",
                    color: "white",
                },
                {
                    name: "中国邮政集团有限公司",
                    url: "chinapost.png",
                    desc: "经国务院批准，中国邮政集团公司于2019年12月正式改制为中国邮政集团有限公司。中国邮政集团有限公司是依照《中华人民共和国公司法》组建的国有独资公司，公司不设股东会，由财政部依据国家法律、行政法规等规定代表国务院履行出资人职责，公司设立党组、董事会、经理层。公司依法经营各项邮政业务，承担邮政普遍服务义务，受政府委托提供邮政特殊服务，对竞争性邮政业务实行商业化运营。 ",
                    color: "white",
                },
                {
                    name: "中国消防",
                    url: "CHINA_FIRE_AND_RESCUE_bedge_(2018).png",
                    desc: "",
                    color: "white",
                },
                {
                    name: "中国人寿保险股份有限公司",
                    url: "chinalife.png",
                    desc: "中国人寿保险股份有限公司是国内寿险行业的龙头企业，总部位于北京, 注册资本282.65亿元人民币。作为《财富》世界500强和世界品牌500强企业——中国人寿保险（集团）公司的核心成员，公司以悠久的历史、雄厚的实力、专业领先的竞争优势及世界知名的品牌赢得了社会广泛客户的信赖，始终占据国内寿险市场领导者的地位，被誉为中国保险业的“中流砥柱”。",
                    color: "white",
                },
                {
                    name: "中国电信集团有限公司",
                    url: "chinatelecom.png",
                    desc: "中国电信集团有限公司（简称“中国电信”）是国有特大型通信骨干企业，注册资本2131亿元人民币，资产规模超过9000亿元人民币，年收入规模超过4900亿元人民币，连续多年位列《财富》杂志全球500强。",
                    color: "grey darken-1",
                },
                {
                    name: "中机中联工程有限公司",
                    url: "CMCU.png",
                    desc: "中机中联一直以来承担了重庆市出口加工区、空港保税区、西永综合保税区、寸滩保税港区等全部保税港区的设计工作，还承担了重庆市“三基地四港区”大物流策划、规划与工程设计。",
                    color: "white",
                },
                {
                    name: "中移铁通有限公司",
                    url: "cmtietong.png",
                    desc: " 中移铁通有限公司（以下简称“中移铁通”）于2015年9月7日成立，为中国移动通信集团公司全资子公司，注册资本人民币1500,000万元，在全国31个省（自治区、直辖市）设有分公司，主营业务为通信技术，信息系统开发、咨询服务以及建筑工程项目管理。",
                    color: "white",
                },
                {
                    name: "中国东方资产管理股份有限公司",
                    url: "coamc.jpg",
                    desc: "中国东方资产管理股份有限公司（以下简称中国东方）是由中华人民共和国财政部、全国社保基金理事会共同发起设立的中央金融企业。中国东方前身为中国东方资产管理公司，成立于1999年10月，于2016年9月改制为股份有限公司。自成立以来，中国东方始终以“保全国有资产、化解金融风险、促进国企改革”为使命，将依法合规经营作为生命线，累计管理处置各类不良资产两万亿元，为国家金融系统的稳定作出了积极贡献。",
                    color: "white",
                },
                {
                    name: "重庆图书馆",
                    url: "cqlib.png",
                    desc: "重庆图书馆是中国大型综合性的公共图书馆，是重庆市主要的文献信息收集交流和服务中心，也是重庆对外文化交流的窗口。",
                    color: "white",
                },
                {
                    name: "重庆科技学院",
                    url: "cqust.png",
                    desc: "重庆科技学院是一所全日制公办普通本科院校，由两所1951年开始办学并保持良好声誉的原中央部委属学校——重庆工业高等专科学校和重庆石油高等专科学校（全国示范性高工专重点建设学校）合并组建，2004年5月经国家教育部正式批准设立。学校是全国卓越工程师教育培养计划试点单位、全国应用技术大学（学院）联盟副理事长单位、全国新建本科院校联盟副理事长单位、重庆市属高校转型发展联盟理事长单位，是重庆市重点支持的高水平新工科高校，被评为全国创新创业典型经验高校、全国深化创新创业教育改革示范高校。2017年成为硕士学位授予单位，2021年成为博士学位授予立项建设单位。",
                    color: "grey darken-1",
                },
                {
                    name: "重庆工程职业技术学院",
                    url: "cqvie.png",
                    desc: "重庆工程职业技术学院是一所由重庆市人民政府举办，重庆市教育委员会主管的全日制普通高等职业学校。学校始建于1951年，1998年起举办高等职业教育，2001年升格为高等职业院校。2010年建成全国100所国家示范性高等职业院校，2019年建成国家优质高职院校，同年被遴选为中国特色高水平高职学校和专业建设计划建设单位。",
                    color: "grey darken-1",
                },
                {
                    name: "重庆市轨道交通",
                    url: "CRT.png",
                    desc: "重庆市轨道交通（集团）有限公司创建于1992年，是重庆市承担城市轨道交通建设、运营和沿线资源开发一体化发展的大型国有控股企业。",
                    color: "white",
                },
                {
                    name: "重庆建设工业（集团）有限责任公司",
                    url: "csgc.png",
                    desc: "重庆建设工业（集团）有限责任公司是中国兵器装备集团有限公司直属骨干企业之一，国有独资企业。2005年12月28日成立,公司是一家主要从事特品、汽车零部件和军民结合产品研发生产的大型军民结合企业集团。特品广泛装备陆海空三军、公安、武警等，并出口国外。民品主要产品有汽车转向器、传动轴、中间轴、脚踏板、CVT等，与长安汽车、长城汽车、吉利汽车、比亚迪汽车、耐世特、博世、蒂森克虏伯、纳铁福等知名企业建立有长期供货关系。军民结合产品主要有民用枪、反恐防暴产品、瞄具系列。",
                    color: "white",
                },
                {
                    name: "中国船舶集团有限公司",
                    url: "CSSC.png",
                    desc: "中国船舶集团有限公司是按照党中央决策、经国务院批准，于2019年10月14日由原中国船舶工业集团有限公司与原中国船舶重工集团有限公司联合重组成立的特大型国有重要骨干企业，有科研院所、企业单位和上市公司113家，资产总额8400亿元，员工34.7万人，拥有我国最大的造修船基地和最完整的船舶及配套产品研发能力，能够设计建造符合全球船级社规范、满足国际通用技术标准和安全公约要求的船舶海工装备，是全球最大的造船集团。",
                    color: "grey darken-1",
                },
                {
                    name: "长安福特汽车有限公司",
                    url: "ford.png",
                    desc: "长安福特汽车有限公司由福特汽车和长安汽车于2001年4月共同签约成立，是一家集整车、发动机、变速器制造于一体的大型综合性汽车企业",
                    color: "white",
                },
                {
                    name: "恒丰银行股份有限公司",
                    url: "hfbank.jpg",
                    desc: "恒丰银行股份有限公司（简称“恒丰银行”）是12家全国性股份制商业银行之一，前身为1987年成立的烟台住房储蓄银行。2003年，经中国人民银行批准，改制为恒丰银行股份有限公司。2019年完成市场化改革股改建账，中央汇金公司成为第一大股东。",
                    color: "white",
                },
                {
                    name: "中国华融国际控股有限公司",
                    url: "hrintl.png",
                    desc: "中国华融国际控股有限公司（“华融国际”）成立於2013年1月，是中国华融资產管理股份有限公司（2799.HK）的核心子公司、海外全资子公司和境外融资战略平臺。公司持有华融金控（0993.HK）和华融投资（2277.HK）两家香港上市公司，在深圳、上海、北京设有具备QFLP等资格的全资子公司。",
                    color: "white",
                },
                {
                    name: "公安部物证鉴定中心",
                    url: "IFSC.png",
                    desc: "",
                    color: "white",
                },
                {
                    name: "LG集团",
                    url: "LG.png",
                    desc: "韩国LG集团于1947年成立于韩国首尔，位于首尔市永登浦区汝矣岛洞20号。是领导世界产业发展的国际性企业集团。 [1]  LG集团在171个国家与地区建立了300多家海外办事机构。旗下子公司有：LG电子、LG display、LG化学、LG生活健康等，事业领域覆盖化学能源、电子电器、通讯与服务等领域。",
                    color: "white",
                },
                {
                    name: "SK海力士",
                    url: "skhynix.png",
                    desc: "SK海力士将通过全球技术领导力（Global Tech Leadership）为客户、合作公司、投资者、社区及员工等利害关系方创造有价值的未来，使全球ICT产业取得更加光明的发展。同时，通过对合作公司进行技术合作投资和组建“共创技术（We Do Tech）中心 ”，共享多年积累的半导体专业知识与经验，努力激活半导体产业生态系统（Ecosystem）。",
                    color: "white",
                },
                {
                    name: "斯巴鲁是富士重工业株式会社",
                    url: "subaru.png",
                    desc: "重视生命、重视驾乘感受，“以人为本”的造车哲学赋予了斯巴鲁汽车的灵魂。自1958年研发出第一辆SUBARU 360汽车至今，斯巴鲁汽车顺应时代变化发展，以满足消费者需求而不断进行自我革命，对汽车性能升级改版。",
                    color: "white",
                },
                { name: "国家税务局", url: "taxation.png", desc: "", color: "white" },
                {
                    name: "重庆市天友乳业股份有限公司",
                    url: "tianyoudairy.png",
                    desc: "重庆市天友乳业股份有限公司（以下简称“天友乳业”）是西南地区具有强大综合实力，集牧草种植、奶牛养殖、生产加工、冷链物流配送、营销服务于一体的大型国有、全产业链的专业乳品提供商。",
                    color: "white",
                },
                {
                    name: "重庆青山工业有限责任公司",
                    url: "tsingshan.jpg",
                    desc: "青山公司拥有全面的研发人才队伍，建立了以传动系统研究院为主体的技术创新组织架构。现有技术研发人员500余人，拥有授权专利282项，各类研发、试验、试制流程合计224项，发布行业标准4项，企业级技术标准、技术规范、产品指南合计172项。通过国家认定企业技术中心认证、CNAS认证实验室、博士后科研工作站和CMMI 三级认证。具备国内领先、国际同等水平的七大研发能力，具备整车、动力总成、变速器总成和零部件试验验证及测试条件。自主研发出国内第一款AMT自动变速器并实现投产，全自主开发湿式双离合自动变速器并搭载整车上市，自主创新成果多次获得国家、兵装集团、重庆市奖项。",
                    color: "white",
                },
                {
                    name: "万友汽车",
                    url: "wanyouauto.png",
                    desc: "万友汽车投资有限公司招聘公告一、公司简介万友汽车投资有限公司（以下简称万友汽车）成立于2012年，系中国长安汽车集团股份有限公司与西南兵器工业公司汽车营销业务深度整合组建的国有独资公司。",
                    color: "grey darken-1",
                },
                {
                    name: "潍柴",
                    url: "weichai.png",
                    desc: "潍柴创建于1946年，全球拥有员工9.5万人，2020年收入超过3000亿元，名列中国企业500强第83位，中国制造业500强第23位，中国机械工业百强企业第2位。",
                    color: "white",
                },
                {
                    name: "西南医院",
                    url: "xnyy.png",
                    desc: "西南医院坐落于两江环抱、山水相依的重庆市沙坪坝区高滩岩上，有着悠久的历史和光荣的传统。医院前身系国民政府中央医院，1929年建于南京，1941年迁至重庆，1950年正式命名，现为第三军医大学第一附属医院、西南临床医学院。经过80多年的建设发展，业已成为一所集医、教、研于一体的大型综合性现代化“三级甲等”医院。医院占地面积300余亩，建筑面积36万平方米，展开床位2900余张，仪器设备总值15亿元，拥有达芬奇手术机器人、PET/CT、3.0MRI、双源螺旋CT、ECT、脑磁图、大型高压氧舱群、神经导航系统等一系列高精尖诊疗设备。2016年门急诊量382万人次、收容量13.2万人次、手术量9.6万台次，医教研护工作协调发展，办院综合实力国内一流。",
                    color: "white",
                },
                {
                    name: "雅马哈发动机株式会社",
                    url: "yamaha.svg",
                    desc: "雅马哈（Yamaha、山叶）品牌于1887年在日本前桥创立。",
                    color: "white",
                },
                {
                    name: "重庆市住房和城乡建设委",
                    url: "zfcxjw.png",
                    desc: "",
                    color: "grey darken-1",
                },
            ],
            partnerUrl: [
                { title: "重庆市住房公积金管理中心", url: "https://www.cqgjj.cn/" },
                {
                    title: "重庆市人才研究和人力资源服务协会",
                    url: "http://www.cqhra.com/",
                },
                { title: "重庆市人力资源和社会保障局", url: "http://rlsbj.cq.gov.cn/" },
                { title: "重庆人才网", url: "https://www.cqrc.net/" },
            ],
            classOption: {
                limitMoveNum: 6,
                direction: 3,
                openTouch: true,
                step: 3,
            },
        };
    },
    methods: {
        navigetTo: function (url) {
            window.open(url, "_blank");
        },
    },
};