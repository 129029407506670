import { render, staticRenderFns } from "./App.vue?vue&type=template&id=d54ed936&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&id=d54ed936&prod&lang=scss&"


/* normalize component */
import normalizer from "!../.yarn/__virtual__/vue-loader-virtual-c467dc4293/0/cache/vue-loader-npm-15.10.1-3a0b6c19d2-828dd30ec4.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports