import homeNewsHistory from "./staff-history/index.vue";
import homeNewsItem from "./staff-item/index.vue";
import { listNewsWithDetail } from "@/api/news";

export default {
  name: "staff-section",
  components: { homeNewsHistory, homeNewsItem },
  props: {},
  data: () => ({
    newsList: [],
    newsListMobile: [],
    newsId: 0,
    newsItemShow: false,
    historyNewsShow: false,
    firstOpen: true,

  }),
  mounted: function () {
    this.getListNewsWithDetail();
  },
  methods: {
    getListNewsWithDetail: function () {
      let newsType = {
        newsType: 1
      }
      listNewsWithDetail(newsType).then((response) => {
        this.newsListMobile = response.data.slice(0, 7)
        this.newsList = response.data.slice(0, 4)
      });
    },
    openNewsItem: function (id) {
      this.newsId = id;
      this.newsItemShow = true;
    },
    openPayroll: function () {
      const features = 'width=480,height=960,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no';
      window.open('http://47.108.77.156:9003/', '_blank', features);
    }
  },
};