<template>
  <v-dialog v-model="wechatPublicAccountDialogShow" @click:outside="$emit('update:wechatPublicAccountDialogShow', false)"
    width="900" :fullscreen="!$vuetify.breakpoint.xl">
    <v-card class="beautiful-card text--secondary">
      <v-card-title class="beautiful-card-title" flat>
        <div class="beautiful-card-title-text">微信公众号</div>
        <v-spacer></v-spacer>
        <close-dialog-button @click-close="$emit('update:wechatPublicAccountDialogShow', false)" />
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="pa-10">
            <div>集世界500强名企最新招聘，分您找工作难之忧。</div>
            <div>
              具世界500强名企服务经验，解您难觅专业、专著、称心如意的人力资源合作伙伴之烦。
            </div>
            <div>
              专业的后台团队在线咨询答疑关于劳动、就业、合同、薪酬、社保等，远程服务于我们的广大在职员工，解您不必要的舟车劳顿之苦。
            </div>
            <div>
              内容丰富，海量信息，特别是最新行业资讯、行业知识适时更新发布，让您一览无余。
            </div>
            <div>我们在这里，在微信的另一头，期待您的关注，诚邀您的加入。</div>
          </v-col>
          <v-col class="text-center">
            <div class="pa-5 qr-box">
              <v-img max-height="230px" contain :src="$nginxUrl + 'qr-code/wy-sq.png'" class="ma-1"></v-img>
              <p>微信扫一扫</p>
              <div>添加朋友 → 搜索微信号 → "WANYOURC"</div>
              <div>搜索微信帐号 → "重庆万友人才服务有限公司"</div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text> </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>