<template>
  <div>
    <v-container>
      <caption-widget title="员工服务" subtitle="News / Staff" :dark="true" />
      <div class="home pt-12" v-if="$vuetify.breakpoint.xl">
        <div class="popular-articles">
          <div class="mini-card-grid">
            <!-- 标准卡片 -->
            <v-container class="mini-card" v-for="(item, index) in newsList" :key="index" @click="openNewsItem(item.id)"
              v-ripple="{ class: `accent--text` }">
              <h3 class="h6 pa-1">
                <p>{{ item.newsTitle }}</p>
              </h3>
              {{ item.newsSubtitle }}
              <div class="description">
                <p>{{ item.newsDesc }}</p>
              </div>
              <v-spacer></v-spacer>
              <div class="my-2">
                <v-chip v-if="item.newsIsTop == 'Y'" color="pink" label text-color="white">
                  <v-icon left> mdi-fire </v-icon>
                  置顶
                </v-chip>
              </div>
              <div class="date pa-1">
                <span>{{ item.newsInitialDt }}</span>
              </div>
            </v-container>
            <!-- 历史员工服务列表 -->
            <v-container class="mini-card history" v-ripple>
              <div class="text-h4 pa-5">
                <p>员工服务</p>
              </div>
              <v-btn class="mini-card-button" depressed @click="openPayroll">
                工资查询
              </v-btn>
              <v-btn class="mini-card-button" depressed @click="historyNewsShow = true">
                查看历史消息
              </v-btn>
              <v-spacer></v-spacer>
              <div class="text-right pt-16">
                <svg t="1704783369089" class="icon" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="1478" width="128" height="128">
                  <path
                    d="M512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m0 950.693512a438.693512 438.693512 0 1 1 438.693512-438.693512 438.693512 438.693512 0 0 1-438.693512 438.693512z"
                    fill="#ffffffcc" p-id="1479"></path>

                  <path
                    d="M512 146.040268a365.959732 365.959732 0 1 0 365.387025 365.959732 365.959732 365.959732 0 0 0-365.387025-365.959732z m164.36689 466.183446H458.165548a36.653244 36.653244 0 0 1-36.653244-36.653244V356.223714a36.653244 36.653244 0 0 1 73.306488 0v183.266219h182.693512a36.653244 36.653244 0 1 1 0 72.733781z"
                    fill="#ffffffcc" p-id="1480"></path>
                </svg>
              </div>
            </v-container>
          </div>
        </div>
      </div>
      <!-- vuetify.breakpoint.xl -->
      <div v-else class="home-mobile pt-6">
        <v-list class="list" color="rgb(255 255 255 / 70%)">
          <v-list-item-group>
            <v-list-item v-for="(item, index) in newsListMobile" :key="index" @click="openNewsItem(item.id)">
              <v-list-item-content>
                <v-list-item-title>
                  <v-chip v-if="item.newsIsTop == 'Y'" color="pink" label small text-color="white">
                    <v-icon small left> mdi-fire </v-icon>
                    置顶
                  </v-chip>
                  {{ item.newsTitle }}
                </v-list-item-title>
                <v-list-item-subtitle class="text--primary">
                  <div v-text="item.newsSubtitle" />
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <div v-text="item.newsDesc" />
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>
                  <div v-text="item.newsInitialDt" />
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
            <v-divider />
            <v-list-item-action class="d-flex flex-row justify-space-around">
              <v-btn color="success" plain @click="openPayroll">
                <v-icon left>
                  mdi-magnify
                </v-icon>
                查询工资
              </v-btn>
              <v-btn color="#3a6df0" plain @click="historyNewsShow = true">
                <v-icon left>
                  mdi-history
                </v-icon>
                查看历史
              </v-btn>
            </v-list-item-action>
          </v-list-item-group>
        </v-list>
      </div>
    </v-container>
    <homeNewsHistory :historyNewsShow.sync="historyNewsShow" />
    <homeNewsItem :newsItemShow.sync="newsItemShow" :newsId="newsId" />
  </div>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "./index.mobile.scss";
</style>