import request from '@/utils/request'

// 查询GB/T 6565-2015 职业分类与代码列表
export function listClassification(query) {
  return request({
    url: '/classification/list',
    method: 'get',
    params: query
  })
}

// 查询GB/T 6565-2015 职业分类与代码详细
export function getClassification(pcId) {
  return request({
    url: '/classification/' + pcId,
    method: 'get'
  })
}

export function findClassification(str) {
  let res = ""
  let classification = [
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10000",
      "pcNme": "党的机关、国家机关、群众团体和社会组织、"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10100",
      "pcNme": "中国共产党机关负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10200",
      "pcNme": "国家机关负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10201",
      "pcNme": "国家权力机关负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10202",
      "pcNme": "国家行政机关负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10203",
      "pcNme": "人民政协机关负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10204",
      "pcNme": "人民法院和人民检察院负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10300",
      "pcNme": "民主党派和工商联负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10400",
      "pcNme": "人民团体和群众团体、社会组织及其他成员组"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10401",
      "pcNme": "人民团体和群众团体负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10402",
      "pcNme": "社会团体负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10403",
      "pcNme": "民办非企业单位负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10404",
      "pcNme": "社会中介组织负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10405",
      "pcNme": "基金会负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10406",
      "pcNme": "宗教组织负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10500",
      "pcNme": "基层群众自治组织负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10600",
      "pcNme": "企事业单位负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10601",
      "pcNme": "企业负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "10602",
      "pcNme": "事业单位负责人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20000",
      "pcNme": "专业技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20100",
      "pcNme": "科学研究人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20101",
      "pcNme": "哲学研究人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20102",
      "pcNme": "经济学研究人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20103",
      "pcNme": "法学研究人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20104",
      "pcNme": "教育学研究人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20105",
      "pcNme": "历史学研究人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20107",
      "pcNme": "农学研究人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20108",
      "pcNme": "医学研究人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20109",
      "pcNme": "管理学研究人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20111",
      "pcNme": "军事学研究人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20112",
      "pcNme": "文学研究人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20113",
      "pcNme": "理学研究人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20114",
      "pcNme": "工学研究人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20115",
      "pcNme": "艺术学科研究人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20199",
      "pcNme": "其他科学研究人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20200",
      "pcNme": "工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20201",
      "pcNme": "地质勘探工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20202",
      "pcNme": "测绘和地理信息工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20203",
      "pcNme": "矿山工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20204",
      "pcNme": "石油天然气工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20205",
      "pcNme": "冶金工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20206",
      "pcNme": "化工工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20207",
      "pcNme": "机械工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20208",
      "pcNme": "航空工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20209",
      "pcNme": "电子工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20210",
      "pcNme": "信息和通信工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20211",
      "pcNme": "电气工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20212",
      "pcNme": "电力工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20213",
      "pcNme": "邮政和快递工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20214",
      "pcNme": "广播电影电视及演艺设备工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20215",
      "pcNme": "交通和水上运输工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20216",
      "pcNme": "民用航空工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20217",
      "pcNme": "铁道工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20218",
      "pcNme": "建筑工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20219",
      "pcNme": "建材工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20220",
      "pcNme": "林业工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20221",
      "pcNme": "水利工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20222",
      "pcNme": "海洋工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20223",
      "pcNme": "纺织服装工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20224",
      "pcNme": "食品工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20225",
      "pcNme": "气象工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20226",
      "pcNme": "地震工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20227",
      "pcNme": "环境保护工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20228",
      "pcNme": "安全工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20229",
      "pcNme": "标准化、计量、质量和认证认可工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20230",
      "pcNme": "管理（工业）工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20231",
      "pcNme": "其他工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20232",
      "pcNme": "制药工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20233",
      "pcNme": "印刷复制工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20234",
      "pcNme": "工业（产品）设计工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20235",
      "pcNme": "康复辅具工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20236",
      "pcNme": "轻工工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20237",
      "pcNme": "土地整治工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20290",
      "pcNme": "兵器工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20291",
      "pcNme": "航天工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20299",
      "pcNme": "其他工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20300",
      "pcNme": "农业技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20301",
      "pcNme": "土壤肥料技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20302",
      "pcNme": "农业技术指导人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20303",
      "pcNme": "植物保护技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20304",
      "pcNme": "园艺技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20305",
      "pcNme": "作物遗传育种栽培技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20306",
      "pcNme": "兽医兽药技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20307",
      "pcNme": "畜牧和草业技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20308",
      "pcNme": "水产技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20309",
      "pcNme": "农业工程技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20399",
      "pcNme": "其他农业技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20400",
      "pcNme": "飞机和船舶技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20401",
      "pcNme": "飞行人员和领航人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20402",
      "pcNme": "船舶指挥和引航人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20499",
      "pcNme": "其他飞机和船舶技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20500",
      "pcNme": "卫生专业技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20501",
      "pcNme": "西医医师"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20502",
      "pcNme": "中医医师"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20503",
      "pcNme": "中西医结合医师"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20504",
      "pcNme": "民族医医师"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20505",
      "pcNme": "公共卫生与健康医师"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20506",
      "pcNme": "药学技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20507",
      "pcNme": "医疗卫生技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20508",
      "pcNme": "护理人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20509",
      "pcNme": "乡村医生"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20599",
      "pcNme": "其他卫生专业技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20600",
      "pcNme": "经济和金融专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20601",
      "pcNme": "经济专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20602",
      "pcNme": "统计专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20603",
      "pcNme": "会计专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20604",
      "pcNme": "审计专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20605",
      "pcNme": "税务专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20606",
      "pcNme": "评估专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20607",
      "pcNme": "商务专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20608",
      "pcNme": "人力资源专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20609",
      "pcNme": "银行专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20610",
      "pcNme": "保险专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20611",
      "pcNme": "证券专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20612",
      "pcNme": "知识产权专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20699",
      "pcNme": "其他经济和金融专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20700",
      "pcNme": "法律、社会和宗教专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20701",
      "pcNme": "法官"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20702",
      "pcNme": "检察官"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20703",
      "pcNme": "律师"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20704",
      "pcNme": "公证员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20705",
      "pcNme": "司法鉴定人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20706",
      "pcNme": "审判辅助人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20707",
      "pcNme": "法律顾问"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20708",
      "pcNme": "宗教教职人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20709",
      "pcNme": "社会工作专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20799",
      "pcNme": "其他法律、社会和宗教专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20800",
      "pcNme": "教学人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20801",
      "pcNme": "高等教育教师"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20802",
      "pcNme": "中等职业教育教师"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20803",
      "pcNme": "中小学教育教师"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20804",
      "pcNme": "幼儿教师"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20805",
      "pcNme": "特殊教育教师"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20899",
      "pcNme": "其他教学人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20900",
      "pcNme": "文学艺术、体育专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20901",
      "pcNme": "文艺创作与编导人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20902",
      "pcNme": "音乐指挥与演员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20903",
      "pcNme": "电影电视制作专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20904",
      "pcNme": "舞台专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20905",
      "pcNme": "美术专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20906",
      "pcNme": "工艺美术与创意设计专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20907",
      "pcNme": "体育专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "20999",
      "pcNme": "其他文学艺术、体育专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "21000",
      "pcNme": "新闻出版、文化专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "21001",
      "pcNme": "记者"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "21002",
      "pcNme": "编辑"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "21003",
      "pcNme": "校对员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "21004",
      "pcNme": "播音员及节目主持人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "21005",
      "pcNme": "翻译人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "21006",
      "pcNme": "图书资料与微缩摄影专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "21007",
      "pcNme": "档案专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "21008",
      "pcNme": "考古及文物保护专业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "21099",
      "pcNme": "其他新闻出版式、文化工作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "29900",
      "pcNme": "其他专业技术人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "30000",
      "pcNme": "办事人员和有关人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "30100",
      "pcNme": "办事人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "30101",
      "pcNme": "行政业务办理人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "30102",
      "pcNme": "行政事务处理人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "30103",
      "pcNme": "行政执法和仲裁人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "30199",
      "pcNme": "其他办事人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "30200",
      "pcNme": "安全和消防人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "30201",
      "pcNme": "人民警察"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "30202",
      "pcNme": "保卫人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "30203",
      "pcNme": "消防和应急救援人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "30299",
      "pcNme": "其他安全和消防人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "39900",
      "pcNme": "其他办事人员和有关人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40000",
      "pcNme": "社会生产服务和生活服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40100",
      "pcNme": "批发与零售服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40101",
      "pcNme": "采购人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40102",
      "pcNme": "销售人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40103",
      "pcNme": "贸易经济代理人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40104",
      "pcNme": "再生物资回收人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40105",
      "pcNme": "特殊商品购销人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40199",
      "pcNme": "其他批发与零售服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40200",
      "pcNme": "交通运输、仓储和邮政业服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40201",
      "pcNme": "轨道交通运输服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40202",
      "pcNme": "道路运输服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40203",
      "pcNme": "水上运输服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40204",
      "pcNme": "航空运输服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40205",
      "pcNme": "装卸搬运和运输代理服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40206",
      "pcNme": "仓储人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40207",
      "pcNme": "邮政和快递服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40299",
      "pcNme": "其他交通运输、仓储和邮政业服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40300",
      "pcNme": "住宿和餐饮服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40301",
      "pcNme": "住宿服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40302",
      "pcNme": "餐饮服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40399",
      "pcNme": "其他住宿和餐饮服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40400",
      "pcNme": "信息运输、软件和信息技术服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40401",
      "pcNme": "信息通信业务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40402",
      "pcNme": "信息通信网络维护人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40403",
      "pcNme": "广播电视传输服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40404",
      "pcNme": "信息通信网络运行管理人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40405",
      "pcNme": "软件和信息技术服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40499",
      "pcNme": "其他信息运输、软件和信息技术服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40500",
      "pcNme": "金融服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40501",
      "pcNme": "银行服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40502",
      "pcNme": "证券服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40503",
      "pcNme": "期货服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40504",
      "pcNme": "保险服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40505",
      "pcNme": "典当服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40506",
      "pcNme": "信托服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40599",
      "pcNme": "其他金融服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40600",
      "pcNme": "房地产服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40601",
      "pcNme": "物业管理服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40602",
      "pcNme": "房地产中介服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40603",
      "pcNme": "其他房地产服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40700",
      "pcNme": "租赁和商务服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40701",
      "pcNme": "租赁业务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40702",
      "pcNme": "商务资讯服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40703",
      "pcNme": "人力资源服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40704",
      "pcNme": "旅游及公共游览场所服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40705",
      "pcNme": "安全保护服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40706",
      "pcNme": "市场管理服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40707",
      "pcNme": "会议及展览服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40799",
      "pcNme": "其他租赁和商务服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40800",
      "pcNme": "技术辅助服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40801",
      "pcNme": "气象服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40802",
      "pcNme": "海洋服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40803",
      "pcNme": "测绘服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40804",
      "pcNme": "地理信息服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40805",
      "pcNme": "检验，检测和计量服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40806",
      "pcNme": "环境检测服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40807",
      "pcNme": "地质勘查人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40808",
      "pcNme": "专业化设计服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40809",
      "pcNme": "摄影扩印服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40899",
      "pcNme": "其他技术辅助服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40900",
      "pcNme": "水利、环境和公共设施管理服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40901",
      "pcNme": "水利设施管养人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40902",
      "pcNme": "水文服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40903",
      "pcNme": "水土保持人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40904",
      "pcNme": "农田灌排人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40905",
      "pcNme": "自然保护区和草地监护人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40906",
      "pcNme": "野生动植物保护人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40907",
      "pcNme": "环境治理服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40908",
      "pcNme": "环境卫生服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40909",
      "pcNme": "有害生物防制人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40910",
      "pcNme": "绿化与园艺服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "40999",
      "pcNme": "其他水利、环境和公共设施管理服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41000",
      "pcNme": "居民服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41001",
      "pcNme": "生活照料服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41002",
      "pcNme": "服装裁剪和洗染织补人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41003",
      "pcNme": "美容美发和浴池服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41004",
      "pcNme": "保健服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41005",
      "pcNme": "婚姻服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41006",
      "pcNme": "殡葬服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41007",
      "pcNme": "宠物服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41099",
      "pcNme": "其他居民服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41100",
      "pcNme": "电力、燃气及水供应服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41101",
      "pcNme": "电力供应服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41102",
      "pcNme": "燃气供应服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41103",
      "pcNme": "水供应服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41199",
      "pcNme": "其他电力、燃气及水供应服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41200",
      "pcNme": "修理及制作服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41201",
      "pcNme": "汽车摩托车修理技术服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41202",
      "pcNme": "计算机和办公设备维修人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41203",
      "pcNme": "家用电子电器产品维修人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41204",
      "pcNme": "日用产品修理服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41205",
      "pcNme": "乐器维修人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41206",
      "pcNme": "印章制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41299",
      "pcNme": "其他修理及制作服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41300",
      "pcNme": "文化、体育及娱乐服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41301",
      "pcNme": "群众文化活动服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41302",
      "pcNme": "广播、电视、电影和影视录音制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41303",
      "pcNme": "文物保护作业人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41304",
      "pcNme": "健身和娱乐场所服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41305",
      "pcNme": "文化、娱乐、体育经纪代理人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41399",
      "pcNme": "其他文化、体育及娱乐服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41400",
      "pcNme": "健康服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41401",
      "pcNme": "医疗辅助服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41402",
      "pcNme": "健康资讯服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41403",
      "pcNme": "康复矫正服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41404",
      "pcNme": "公共卫生辅助服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "41499",
      "pcNme": "其他健康服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "49900",
      "pcNme": "其他社会生产和生活服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50000",
      "pcNme": "农、林、牧、渔业生产及辅助人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50100",
      "pcNme": "农业生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50101",
      "pcNme": "作物种子（苗）繁育生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50102",
      "pcNme": "农作物生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50199",
      "pcNme": "其他农业生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50200",
      "pcNme": "林业生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50201",
      "pcNme": "林木种苗繁育人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50202",
      "pcNme": "营造林人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50203",
      "pcNme": "森林经营和管护人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50204",
      "pcNme": "木材采运人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50299",
      "pcNme": "其他林业生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50300",
      "pcNme": "畜牧业生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50301",
      "pcNme": "畜禽种苗繁育人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50302",
      "pcNme": "畜禽饲养人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50303",
      "pcNme": "特种经济动物饲养人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50399",
      "pcNme": "其他畜牧业生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50400",
      "pcNme": "渔业生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50401",
      "pcNme": "水产苗种繁育人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50402",
      "pcNme": "水产养殖人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50403",
      "pcNme": "水产捕捞及有关人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50499",
      "pcNme": "其他渔业生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50500",
      "pcNme": "农林牧渔生产辅助人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50501",
      "pcNme": "农业生产服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50502",
      "pcNme": "动植物疫病防治人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50503",
      "pcNme": "农村能源利用人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50504",
      "pcNme": "农村环境保护人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50505",
      "pcNme": "农机化服务人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50506",
      "pcNme": "农副林特产品初加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "50599",
      "pcNme": "其他农林牧渔生产辅助人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "59900",
      "pcNme": "其他农、林、牧、渔、水利业生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60000",
      "pcNme": "生产制造及有关人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60100",
      "pcNme": "农副产品加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60101",
      "pcNme": "粮油加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60102",
      "pcNme": "饲料加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60103",
      "pcNme": "制糖人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60104",
      "pcNme": "畜禽制品加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60105",
      "pcNme": "水产品加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60106",
      "pcNme": "果蔬和坚果加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60107",
      "pcNme": "淀粉和豆制品加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60199",
      "pcNme": "其他农副产品加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60200",
      "pcNme": "食品、饮料生产加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60201",
      "pcNme": "焙烤食品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60202",
      "pcNme": "糖制品加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60203",
      "pcNme": "方便食品和罐头食品加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60204",
      "pcNme": "乳制品加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60205",
      "pcNme": "调味品及食品添加剂制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60206",
      "pcNme": "酒、饮料及精制茶制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60299",
      "pcNme": "其他食品、饮料生产加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60300",
      "pcNme": "烟草及其制品加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60301",
      "pcNme": "烟叶初加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60302",
      "pcNme": "烟用材料生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60303",
      "pcNme": "烟草制品生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60399",
      "pcNme": "其他烟草及其制品加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60400",
      "pcNme": "纺织、针织、印染人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60401",
      "pcNme": "纤维预处理人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60402",
      "pcNme": "纺纱人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60403",
      "pcNme": "织造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60404",
      "pcNme": "针织人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60405",
      "pcNme": "非织造布制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60406",
      "pcNme": "印染人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60499",
      "pcNme": "其他纺织、针织、印染人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60500",
      "pcNme": "纺织品、服装和皮革、毛皮制品加工制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60501",
      "pcNme": "纺织品和服装剪裁缝纫人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60502",
      "pcNme": "皮革、毛皮及其制作加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60503",
      "pcNme": "羽绒羽毛加工及制品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60504",
      "pcNme": "鞋帽制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60599",
      "pcNme": "其他纺织品、服装和皮革、毛皮制品加工制作"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60600",
      "pcNme": "木材加工、家具与木制品制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60601",
      "pcNme": "木材加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60602",
      "pcNme": "人造板制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60603",
      "pcNme": "木制品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60604",
      "pcNme": "家具制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60699",
      "pcNme": "其他木材加工、家具与木制品制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60700",
      "pcNme": "纸及纸制品生产加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60701",
      "pcNme": "制浆造纸人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60702",
      "pcNme": "纸制品制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60799",
      "pcNme": "其他纸及纸制品生产加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60800",
      "pcNme": "印刷和记录媒介复制人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60801",
      "pcNme": "印刷人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60802",
      "pcNme": "记录媒介复制人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60899",
      "pcNme": "其他印刷和记录媒介复制人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60900",
      "pcNme": "文教、工美、体育和娱乐用品制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60901",
      "pcNme": "文教用品制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60902",
      "pcNme": "乐器制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60903",
      "pcNme": "工艺美术品制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60904",
      "pcNme": "体育用品制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60905",
      "pcNme": "玩具制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "60999",
      "pcNme": "其他文教、工美、体育和娱乐用品制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61000",
      "pcNme": "石油加工和炼焦、煤化工制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61001",
      "pcNme": "石油炼制生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61002",
      "pcNme": "炼焦人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61003",
      "pcNme": "煤化工生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61099",
      "pcNme": "其他石油加工和炼焦、煤化工制作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61100",
      "pcNme": "化学原料和化学制品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61101",
      "pcNme": "化工产品生产通用工艺人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61102",
      "pcNme": "基础化学原料制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61103",
      "pcNme": "化学肥料生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61104",
      "pcNme": "农药生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61105",
      "pcNme": "涂料、油墨、颜料及类似产品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61106",
      "pcNme": "合成树脂生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61107",
      "pcNme": "合成橡胶生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61108",
      "pcNme": "专用化学产品生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61109",
      "pcNme": "火工品制造、保管、爆破及焰火产品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61110",
      "pcNme": "日用化学品生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61199",
      "pcNme": "其他化学原料和化学制品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61200",
      "pcNme": "医药制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61201",
      "pcNme": "化学药品原料药制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61202",
      "pcNme": "中药饮片加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61203",
      "pcNme": "药物制剂人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61204",
      "pcNme": "兽用药制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61205",
      "pcNme": "生物药品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61299",
      "pcNme": "其他医药制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61300",
      "pcNme": "化学纤维制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61301",
      "pcNme": "化学纤维原料制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61302",
      "pcNme": "化学纤维纺丝及后处理人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61399",
      "pcNme": "其他化学纤维制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61400",
      "pcNme": "橡胶和塑料制品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61401",
      "pcNme": "橡胶制品生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61402",
      "pcNme": "塑料制品加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61499",
      "pcNme": "其他橡胶和塑料制品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61500",
      "pcNme": "非金属矿物制品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61501",
      "pcNme": "水泥、石灰、石膏及其制品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61502",
      "pcNme": "砖瓦石材等建筑材料制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61503",
      "pcNme": "玻璃及玻璃制品生产加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61504",
      "pcNme": "玻璃纤维及玻璃纤维增强塑料制品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61505",
      "pcNme": "陶瓷制品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61506",
      "pcNme": "耐火材料制品生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61507",
      "pcNme": "石墨及炭素制品生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61508",
      "pcNme": "高岭土、珍珠岩等非金属矿物加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61599",
      "pcNme": "其他非金属矿物制品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61600",
      "pcNme": "采矿人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61601",
      "pcNme": "矿物采选人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61602",
      "pcNme": "石油和天然气开采与储运人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61603",
      "pcNme": "采盐人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61699",
      "pcNme": "其他采矿人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61700",
      "pcNme": "金属冶炼和压延加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61701",
      "pcNme": "炼铁人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61702",
      "pcNme": "炼钢人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61703",
      "pcNme": "铸铁管人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61704",
      "pcNme": "铁合金冶炼人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61705",
      "pcNme": "重有色金属冶炼人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61706",
      "pcNme": "轻有色金属冶炼人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61707",
      "pcNme": "稀贵金属冶炼人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61708",
      "pcNme": "半导体材料制备人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61709",
      "pcNme": "金属轧制人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61710",
      "pcNme": "硬质合金生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61799",
      "pcNme": "其他金属冶炼和压延加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61800",
      "pcNme": "机械制造基础加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61801",
      "pcNme": "机械冷加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61802",
      "pcNme": "机械热加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61803",
      "pcNme": "机械表面处理加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61804",
      "pcNme": "工装工具制造加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61899",
      "pcNme": "其他机械制造基础加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61900",
      "pcNme": "金属制品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61901",
      "pcNme": "五金制品制作装配人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "61999",
      "pcNme": "其他金属制品制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62000",
      "pcNme": "通用设备制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62001",
      "pcNme": "通用基础件装配制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62002",
      "pcNme": "锅炉及原动设备制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62003",
      "pcNme": "金属加工机械制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62004",
      "pcNme": "物料搬运设备制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62005",
      "pcNme": "泵、压缩机、阀门及类似机械制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62006",
      "pcNme": "烘炉、水处理、衡器等设备制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62007",
      "pcNme": "文化办公机械制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62099",
      "pcNme": "其他通用设备制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62100",
      "pcNme": "专用设备制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62101",
      "pcNme": "采矿、建筑专用设备制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62102",
      "pcNme": "印刷生产专用设备制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62103",
      "pcNme": "纺织服装和皮革加工专用设备制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62104",
      "pcNme": "电子专用设备装配调试人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62105",
      "pcNme": "农业机械制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62106",
      "pcNme": "医疗器械制品和康复辅具生产人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62199",
      "pcNme": "其他专用设备制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62200",
      "pcNme": "汽车制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62201",
      "pcNme": "汽车零部件、饰件生产加工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62202",
      "pcNme": "汽车整车制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62299",
      "pcNme": "其他汽车制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62300",
      "pcNme": "铁路、船舶、航空设备制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62301",
      "pcNme": "轨道交通运输设备制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62302",
      "pcNme": "船舶制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62303",
      "pcNme": "航空产品装配、调试人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62304",
      "pcNme": "摩托车、自行车制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62399",
      "pcNme": "其他铁路、船舶、航空设备制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62400",
      "pcNme": "电气机械和器材制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62401",
      "pcNme": "电机制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62402",
      "pcNme": "输配电及控制设备制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62403",
      "pcNme": "电线电缆、光纤光缆及电工器材制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62404",
      "pcNme": "电池制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62405",
      "pcNme": "家用电器器具制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62406",
      "pcNme": "非家用电器器具制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62407",
      "pcNme": "照明器具制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62408",
      "pcNme": "电气信号设备装置制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62499",
      "pcNme": "其他电气机械和器材制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62500",
      "pcNme": "计算机、通信和其他电子设备制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62501",
      "pcNme": "电子元件制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62502",
      "pcNme": "电子器件制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62503",
      "pcNme": "计算机制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62504",
      "pcNme": "电子设备装配调试人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62599",
      "pcNme": "其他计算机、通信和其他电子设备制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62600",
      "pcNme": "仪器仪表制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62601",
      "pcNme": "仪器仪表装配人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62699",
      "pcNme": "其他仪器仪表制造人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62700",
      "pcNme": "废弃资源综合利用人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62701",
      "pcNme": "废料和碎屑加工处理人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62799",
      "pcNme": "其他废弃资源综合利用人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62800",
      "pcNme": "电力、热力、气体、水生产和输配人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62801",
      "pcNme": "电力、热力生产和供应人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62802",
      "pcNme": "气体生产、处理和输送人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62803",
      "pcNme": "水生产、输排和水处理人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62899",
      "pcNme": "其他电力、热力、气体、水生产和输配人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62900",
      "pcNme": "建筑施工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62901",
      "pcNme": "房屋建筑施工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62902",
      "pcNme": "土木工程建筑施工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62903",
      "pcNme": "建筑安装施工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62904",
      "pcNme": "建筑装饰人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62905",
      "pcNme": "古建筑修建人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "62999",
      "pcNme": "其他建筑施工人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "63000",
      "pcNme": "运输设备和通用工程机械操作人员及有关人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "63001",
      "pcNme": "专用车辆操作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "63002",
      "pcNme": "轨道交通运输机械设备操作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "63003",
      "pcNme": "民用航空设备操作及有关人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "63004",
      "pcNme": "水上运输设备操作及有关人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "63005",
      "pcNme": "通用工程机械操作人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "63099",
      "pcNme": "其他运输设备和通用工程机械操作人员及有关"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "63100",
      "pcNme": "生产辅助人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "63101",
      "pcNme": "机械设备修理人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "63102",
      "pcNme": "船舶、民用航空器修理人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "63103",
      "pcNme": "检验试验人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "63104",
      "pcNme": "称重计量人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "63105",
      "pcNme": "包装人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "63106",
      "pcNme": "安全生产管理人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "63199",
      "pcNme": "其他生产辅助人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "69900",
      "pcNme": "其他生产制造及有关人员"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "70000",
      "pcNme": "军人"
    },
    {
      "searchValue": null,
      "createBy": null,
      "createTime": null,
      "updateBy": null,
      "updateTime": null,
      "remark": null,
      "params": {},
      "pcId": "80000",
      "pcNme": "不便分类的其他从业人员"
    }
  ];
  classification.forEach((item) => {
    if (item.pcId == str) {
      res = item.pcNme
    }
  })
  return res;
}