<template>
  <div>
    <div ref="scrollParallax" v-if="$vuetify.breakpoint.xl">
      <v-parallax :src="$nginxUrl + 'company/history-3.jpg'" class="history rounded-t-xl" v-intersect="onIntersect"
        :style="'filter: grayscale(' + parallaxGrayscale + '%)'">
        <div class="text-center text-h2 font-weight-black py-10">发展历程</div>
        <div>
          <v-slider class="progress-bar" v-if="!$vuetify.breakpoint.mdAndDown" v-model="currentYear"
            :tick-labels="ticksLabels" :max="3" color="#F4F4F4EE" step="1" ticks="always" track-color="#F4F4F4EE">
            <template v-slot:thumb-label=""> 🔵 </template>
          </v-slider>
        </div>
      </v-parallax>
      <div id="description" class="overflow-x-auto rounded-b-xl">
        <div class="panels d-inline-flex">
          <div class="panel px-3 rounded-b-xl" v-for="(item, index) in companyHistoryDesc" :key="index" :year="item.year"
            :id="item.year" :class="{ toyear: item.toyear }" v-ripple>
            <div class="text-h5 pa-4 mb-3 title">{{ item.year }} 年</div>
            <ol class="list mb-3" v-for="(even, eveni) in item.historyEvens" :key="eveni">
              <li class="li">
                {{ even }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- $vuetify.breakpoint.xl -->
    <div ref="scrollParallax" v-else>
      <v-parallax height="200" :src="$nginxUrl + 'company/history-5.jpg'" class="history-mobile rounded-t-xl"
        v-intersect="onIntersect" :style="'filter: grayscale(' + parallaxGrayscale + '%)'">
        <div class="text-center text-h4 font-weight-bold py-10">发展历程</div>
      </v-parallax>
      <div id="description-mobile" class="overflow-x-auto rounded-b-xl">
        <div class="panels d-inline-flex">
          <div class="panel px-3 rounded-b-xl" v-for="(item, index) in companyHistoryDesc" :key="index" :year="item.year"
            :id="item.year" :class="{ toyear: item.toyear }" v-ripple>
            <div class="pa-2 mb-1 title">{{ item.year }} 年</div>
            <ol class="list text-subtitle-2" v-for="(even, eveni) in item.historyEvens" :key="eveni">
              <li class="li">
                {{ even }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "./index.mobile.scss";
</style>