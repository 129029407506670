<template>
  <v-container class="partner-bg">
    <caption-widget dark title="合作伙伴" subtitle="Partner" />

    <div class="text-center py-10 panter-url-box">
      <vue-seamless-scroll :data="partnerItem" :class-option="classOption" class="warp pa-5"
        v-if="$vuetify.breakpoint.xl">
        <ul class="ul-item">
          <li class="li-item mr-5" v-for="(num, index) in partnerItem" :key="index">
            <div class="gallery-div" v-ripple v-tilt="{
              'max-glare': 0.8,
              glare: true,
              scale: 0.95,
              speed: 1000,
              transition: true,
              reverse: true,
              max: 10,
              transition: true,
            }" @contextmenu.prevent.stop="() => { }">
              <div class="gallery pa-1 ma-2">
                <v-img max-height="130px" max-width="280" contain :src="$nginxUrl + 'parnter/' + num.url" />
              </div>
            </div>
          </li>
        </ul>
      </vue-seamless-scroll>
      <!--  $vuetify.breakpoint.xl -->
      <v-slide-group v-else class="pa-4">
        <v-slide-item v-for="(item, n) in partnerItem.length / 3" :key="n">
          <div>
            <v-img class="ma-3 partner-card" :aspect-ratio="16 / 9" :src="$nginxUrl + 'parnter/' + partnerItem[3 * n].url"
              width="150px" contain />
            <v-img v-if="partnerItem[3 * n + 1].url != null" class="ma-3 partner-card" :aspect-ratio="16 / 9"
              :src="$nginxUrl + 'parnter/' + partnerItem[3 * n + 1].url" width="120px" contain />
            <v-img v-if="partnerItem[3 * n + 2].url != null" class="ma-3 partner-card" :aspect-ratio="16 / 9"
              :src="$nginxUrl + 'parnter/' + partnerItem[3 * n + 2].url" width="120px" contain />
          </div>
        </v-slide-item>
      </v-slide-group>

      <v-divider></v-divider>
      <div class="text-h4 py-5 panter-url">友情链接</div>
      <v-row class="px-10 py-4">
        <v-col v-for="(item, index) in partnerUrl" :key="index" cols="12" sm="3" @click="navigetTo(item.url)"
          class="side-menu" v-ripple>
          {{ item.title }}
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "./index.mobile.scss";
</style>