<template>
  <div>
    <v-dialog v-if="$vuetify.breakpoint.xl" hide-overlay max-width="80%" v-model="recruitmentItemShow"
      @click:outside="$emit('update:recruitmentItemShow', false)">
      <v-card class="beautiful-card">
        <transition name="fade">
          <v-app-bar class="beautiful-card-header overflow-hidden off" v-show="!isIntersecting" absolute shrink-on-scroll
            elevate-on-scroll scroll-target="#scrolling-techniques">
            <template>
              <v-row class="py-3 px-5">
                <v-col cols="11">
                  {{ recruitmentItemRes.recruitmentTitle }}
                </v-col>
                <v-col cols="1">
                  <div class="pl-15">
                    <close-dialog-button @click-close="$emit('update:recruitmentItemShow', false)" />
                  </div>
                </v-col>
                <v-col :cols="getLength(item.value)" v-for="(item, index) in recruitmentItemHead" :key="index"
                  :hidden="!item.value">
                  <div class="pa-0">
                    <v-text-field v-if="getLength(item.value) != 12" :label="item.label" v-model="item.value" readonly
                      dense filled hide-details rounded />
                    <v-textarea v-if="getLength(item.value) == 12" :label="item.label" v-model="item.value" readonly
                      auto-grow hide-details filled rows="1" rounded />
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-app-bar>
        </transition>

        <transition name="fade">
          <v-app-bar class="beautiful-card-header overflow-hidden" v-show="isIntersecting" absolute shrink-on-scroll
            elevate-on-scroll scroll-target="#scrolling-techniques">
            <template>
              <v-row class="py-3 px-5">
                <v-col cols="11">
                  {{ recruitmentItemRes.recruitmentTitle }}
                </v-col>
                <v-col cols="1">
                  <div class="pl-15">
                    <close-dialog-button @click-close="$emit('update:recruitmentItemShow', false)" />
                  </div>
                </v-col>
                <v-col :cols="getLength(item.value)" v-for="(item, index) in recruitmentItemHead" :key="index"
                  :hidden="!item.value">
                  <div class="pa-0">
                    <v-text-field v-if="getLength(item.value) != 12" :label="item.label" v-model="item.value" readonly
                      dense filled hide-details rounded />
                    <v-textarea v-if="getLength(item.value) == 12" :label="item.label" v-model="item.value" readonly
                      auto-grow hide-details filled rows="1" rounded />
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-app-bar>
        </transition>

        <v-card-text class="beautiful-card-content-wrapper recruitment-box">
          <v-sheet id="scrolling-techniques" class="overflow-y-auto" color="rgba(255, 255, 255, 0)">
            <v-container style="min-height: 1200px" class="pa-6">
              <div style="height: 200px" v-intersect="onIntersect"></div>
              <div style="height: 200px"></div>
              <div v-html="recruitmentItemRes.jobDescription"></div>
              <div class="pa-4" v-if="wechatQr != null">
                <v-card class="mx-auto qr-box" max-width="250">
                  <v-card-title>
                    <span class="text-h7">微信扫描咨询详情 </span>
                  </v-card-title>
                  <v-card-text>
                    <v-img :src="$nginxFileUrl + wechatQr.wechatQrImg" />
                  </v-card-text>
                  <v-card-actions>
                    <v-list-item>
                      <svg data-v-5ec0bea2="" xmlns="http://www.w3.org/2000/svg" width="30" height="30" x="240" y="210">
                        <g data-v-5ec0bea2="" fill="none" fill-rule="evenodd">
                          <path data-v-5ec0bea2="" fill="#0082F0"
                            d="M24.27 6.9a10.746 10.746 0 00-1.945-2.764C20.295 2.034 17.453.68 14.322.323a14.773 14.773 0 00-1.656-.096c-.511 0-1.044.03-1.585.089-3.144.342-6.002 1.69-8.047 3.792a10.757 10.757 0 00-1.955 2.757 9.634 9.634 0 00-1.003 4.277c0 1.909.58 3.79 1.678 5.442a14.96 14.96 0 002.302 2.634v.002l-.378 2.974c-.015.04-.031.08-.04.123-.01.039-.012.079-.016.119-.002.03-.01.06-.01.091 0 .033.008.065.01.098.05.489.458.872.96.872.175 0 .337-.05.478-.131l.014-.008c.02-.012.042-.023.062-.036l.903-.454 2.69-1.352a13.93 13.93 0 002.35.453 14.54 14.54 0 003.243-.009 14.092 14.092 0 003.173-.742 1.752 1.752 0 01-1.192-1.832c-.72.23-1.472.393-2.25.482a12.172 12.172 0 01-2.716.008c-.09-.01-.182-.025-.274-.037a11.976 11.976 0 01-1.78-.373 1.217 1.217 0 00-.37-.057c-.2 0-.392.053-.588.154-.026.014-.05.025-.076.04l-2.21 1.303-.096.057-.003.001c-.047.028-.075.039-.1.039a.15.15 0 01-.146-.153l.085-.345.098-.375.162-.614.179-.686a.93.93 0 00-.334-1.033 9.906 9.906 0 01-.923-.777c-.5-.475-.94-.994-1.31-1.55-.869-1.308-1.328-2.792-1.328-4.293 0-1.164.266-2.297.79-3.366a8.633 8.633 0 011.569-2.21c1.68-1.728 4.045-2.837 6.656-3.12.454-.05.901-.075 1.328-.075.449 0 .916.027 1.388.08 2.599.296 4.949 1.41 6.617 3.138a8.618 8.618 0 011.56 2.215 7.584 7.584 0 01.778 3.338c0 .12-.007.24-.013.36a1.746 1.746 0 012.149.251c.03.03.054.062.08.093a9.11 9.11 0 00.031-.739 9.634 9.634 0 00-.986-4.243">
                          </path>
                          <path data-v-5ec0bea2="" fill="#FB6500"
                            d="M24.149 22.148a1.7 1.7 0 00-.21-.035 5.377 5.377 0 01-2.996-1.67v.001a.418.418 0 10-.534.64c.041.04.082.078.121.118a5.39 5.39 0 011.478 2.761 1.715 1.715 0 00.059.356 1.749 1.749 0 002.926.777 1.754 1.754 0 00-.844-2.948">
                          </path>
                          <path data-v-5ec0bea2="" fill="#0082F0"
                            d="M29.944 17.673a1.75 1.75 0 00-2.98 1.056 5.388 5.388 0 01-1.667 2.999.419.419 0 10.641.534l.117-.121a5.376 5.376 0 012.758-1.48 1.71 1.71 0 00.355-.058 1.754 1.754 0 00.776-2.93">
                          </path>
                          <path data-v-5ec0bea2="" fill="#2DBC00"
                            d="M22.53 12.717a1.754 1.754 0 001.054 2.982 5.376 5.376 0 012.996 1.67.419.419 0 10.534-.641 5.389 5.389 0 01-1.6-2.879 1.625 1.625 0 00-.058-.355 1.75 1.75 0 00-2.926-.777">
                          </path>
                          <path data-v-5ec0bea2="" fill="#FC0"
                            d="M20.543 19.19a1.93 1.93 0 00.015-.106 5.393 5.393 0 011.668-3 .42.42 0 10-.64-.534 5.375 5.375 0 01-2.876 1.6 1.73 1.73 0 00-.355.06 1.754 1.754 0 00-.776 2.93 1.75 1.75 0 002.964-.95">
                          </path>
                        </g>
                      </svg>
                      <v-list-item-content class="px-4">
                        <v-list-item-title>{{
                          wechatQr.userName
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-actions>
                </v-card>
              </div>
            </v-container>
          </v-sheet>
          <v-banner v-model="snackbar" transition="slide-y-transition" class="text-right" ref="banner">
            <div class="font-weight-bold color-primary">复制链接分享</div>
            <div class="text--secondary">{{ snackbarTitle }}</div>
          </v-banner>
        </v-card-text>
        <v-overlay :value="overlay" opacity="0.05">
          <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
        </v-overlay>
        <v-col class="d-flex flex-row-reverse" cols="12">
          <v-btn class="ma-1" plain dark rounded @click="shareAction" color="primary">
            <v-icon dark> mdi-share </v-icon>
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>

    <!-- vuetify.breakpoint.xl -->

    <v-dialog v-else fullscreen hide-overlay v-model="recruitmentItemShow"
      @click:outside="$emit('update:recruitmentItemShow', false)">
      <v-card class="beautiful-card-mobile">
        <v-card-title class="beautiful-card-title" flat>
          <span class="beautiful-card-title-text">{{
            recruitmentItemRes.recruitmentTitle
          }}</span>
          <v-spacer></v-spacer>
          <close-dialog-button @click-close="$emit('update:recruitmentItemShow', false)" />
        </v-card-title>

        <v-card-text class="
            fullScreen
            beautiful-card-content-wrapper
            recruitment-box-mobile
          ">
          <v-row justify="center">
            <v-expansion-panels class="pa-3 mt-5 expansion" accordion>
              <v-expansion-panel>
                <v-expansion-panel-header ripple>查看招聘简章</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <template v-for="(item, index) in recruitmentItemHead">
                    <p :key="index" v-if="item.value != null && item.value != ''" v-ripple>
                      <b>{{ item.label }}</b>
                      <span> : </span>
                      <span>{{ item.value }}</span>
                    </p>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>

          <v-sheet id="scrolling-techniques" class="overflow-y-auto" color="rgba(255, 255, 255, 0)">
            <v-container class="pa-3">
              <div v-html="recruitmentItemRes.jobDescription"></div>
              <div class="pa-4" v-if="wechatQr != null">
                <v-card class="mx-auto qr-box" max-width="250">
                  <v-card-title>
                    <span class="text-h7">微信扫描咨询详情 </span>
                  </v-card-title>
                  <v-card-text>
                    <v-img :src="$nginxFileUrl + wechatQr.wechatQrImg" />
                  </v-card-text>
                  <v-card-actions>
                    <v-list-item>
                      <svg data-v-5ec0bea2="" xmlns="http://www.w3.org/2000/svg" width="30" height="30" x="240" y="210">
                        <g data-v-5ec0bea2="" fill="none" fill-rule="evenodd">
                          <path data-v-5ec0bea2="" fill="#0082F0"
                            d="M24.27 6.9a10.746 10.746 0 00-1.945-2.764C20.295 2.034 17.453.68 14.322.323a14.773 14.773 0 00-1.656-.096c-.511 0-1.044.03-1.585.089-3.144.342-6.002 1.69-8.047 3.792a10.757 10.757 0 00-1.955 2.757 9.634 9.634 0 00-1.003 4.277c0 1.909.58 3.79 1.678 5.442a14.96 14.96 0 002.302 2.634v.002l-.378 2.974c-.015.04-.031.08-.04.123-.01.039-.012.079-.016.119-.002.03-.01.06-.01.091 0 .033.008.065.01.098.05.489.458.872.96.872.175 0 .337-.05.478-.131l.014-.008c.02-.012.042-.023.062-.036l.903-.454 2.69-1.352a13.93 13.93 0 002.35.453 14.54 14.54 0 003.243-.009 14.092 14.092 0 003.173-.742 1.752 1.752 0 01-1.192-1.832c-.72.23-1.472.393-2.25.482a12.172 12.172 0 01-2.716.008c-.09-.01-.182-.025-.274-.037a11.976 11.976 0 01-1.78-.373 1.217 1.217 0 00-.37-.057c-.2 0-.392.053-.588.154-.026.014-.05.025-.076.04l-2.21 1.303-.096.057-.003.001c-.047.028-.075.039-.1.039a.15.15 0 01-.146-.153l.085-.345.098-.375.162-.614.179-.686a.93.93 0 00-.334-1.033 9.906 9.906 0 01-.923-.777c-.5-.475-.94-.994-1.31-1.55-.869-1.308-1.328-2.792-1.328-4.293 0-1.164.266-2.297.79-3.366a8.633 8.633 0 011.569-2.21c1.68-1.728 4.045-2.837 6.656-3.12.454-.05.901-.075 1.328-.075.449 0 .916.027 1.388.08 2.599.296 4.949 1.41 6.617 3.138a8.618 8.618 0 011.56 2.215 7.584 7.584 0 01.778 3.338c0 .12-.007.24-.013.36a1.746 1.746 0 012.149.251c.03.03.054.062.08.093a9.11 9.11 0 00.031-.739 9.634 9.634 0 00-.986-4.243">
                          </path>
                          <path data-v-5ec0bea2="" fill="#FB6500"
                            d="M24.149 22.148a1.7 1.7 0 00-.21-.035 5.377 5.377 0 01-2.996-1.67v.001a.418.418 0 10-.534.64c.041.04.082.078.121.118a5.39 5.39 0 011.478 2.761 1.715 1.715 0 00.059.356 1.749 1.749 0 002.926.777 1.754 1.754 0 00-.844-2.948">
                          </path>
                          <path data-v-5ec0bea2="" fill="#0082F0"
                            d="M29.944 17.673a1.75 1.75 0 00-2.98 1.056 5.388 5.388 0 01-1.667 2.999.419.419 0 10.641.534l.117-.121a5.376 5.376 0 012.758-1.48 1.71 1.71 0 00.355-.058 1.754 1.754 0 00.776-2.93">
                          </path>
                          <path data-v-5ec0bea2="" fill="#2DBC00"
                            d="M22.53 12.717a1.754 1.754 0 001.054 2.982 5.376 5.376 0 012.996 1.67.419.419 0 10.534-.641 5.389 5.389 0 01-1.6-2.879 1.625 1.625 0 00-.058-.355 1.75 1.75 0 00-2.926-.777">
                          </path>
                          <path data-v-5ec0bea2="" fill="#FC0"
                            d="M20.543 19.19a1.93 1.93 0 00.015-.106 5.393 5.393 0 011.668-3 .42.42 0 10-.64-.534 5.375 5.375 0 01-2.876 1.6 1.73 1.73 0 00-.355.06 1.754 1.754 0 00-.776 2.93 1.75 1.75 0 002.964-.95">
                          </path>
                        </g>
                      </svg>
                      <v-list-item-content class="px-4">
                        <v-list-item-title>{{
                          wechatQr.userName
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card-actions>
                </v-card>
              </div>
            </v-container>
          </v-sheet>
          <v-banner v-model="snackbar" transition="slide-y-transition" class="text-right" two-line ref="bannerM">
            <div class="font-weight-bold color-primary">长按链接复制分享</div>
            <div class="text--secondary">{{ snackbarTitle }}</div>
          </v-banner>
        </v-card-text>
        <v-overlay :value="overlay" opacity="0.05">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-col class="d-flex flex-row-reverse" cols="12">
          <v-btn class="ma-1" plain dark rounded @click="shareAction" color="primary">
            <v-icon dark> mdi-share </v-icon>
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "./index.mobile.scss";
</style>