import { listNewsWithDetail } from "@/api/news";
import homeNewsHistory from "./news-history/index.vue";
import homeNewsItem from "./news-item/index.vue";

export default {
  name: "news-section",
  components: { homeNewsHistory, homeNewsItem },
  props: {},
  data: () => ({
    newsList: [],
    newsListMobile: [],
    newsId: 0,
    newsItemShow: false,
    historyNewsShow: false,
    firstOpen: true,

  }),
  mounted: function () {
    this.getListNewsWithDetail();
    if (this.firstOpen && this.$getUrlKey("type") == 'news') {
      this.newsItemShow = true;
      this.newsId = parseInt(atob(this.$getUrlKey("id")));
      this.firstOpen = false;
    }
  },
  methods: {
    getListNewsWithDetail: function () {
      let newsType = {
        newsType: 0
      }
      listNewsWithDetail(newsType).then((response) => {
        this.newsListMobile = response.data.slice(0, 7)
        this.newsList = response.data.slice(0, 4)
      });
    },
    openNewsItem: function (id) {
      this.newsId = id;
      this.newsItemShow = true;
    },
  },
};