<template>
  <div class="organization-div" :class="{ mobile: !$vuetify.breakpoint.xl }">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:ev="http://www.w3.org/2001/xml-events" xmlns:v="http://schemas.microsoft.com/visio/2003/SVGExtensions/"
      :width="$vuetify.breakpoint.xl ? '12in' : '1.4in'" :height="$vuetify.breakpoint.xl ? '8in' : '3.5in'"
      :viewBox="$vuetify.breakpoint.xl ? '0 0 800 600' : '200 100 100 400'" xml:space="preserve"
      color-interpolation-filters="sRGB" class="st10">
      <v:documentProperties v:langID="2052" v:metric="true" v:viewMarkup="false">
        <v:userDefs>
          <v:ud v:nameU="msvSubprocessMaster" v:prompt="" v:val="VT4(Rectangle)" />
          <v:ud v:nameU="msvNoAutoConnect" v:val="VT0(1):26" />
        </v:userDefs>
      </v:documentProperties>

      <defs id="Filters">
        <filter id="filter_2.6666667461395">
          <feGaussianBlur stdDeviation="2.6666667461395" />
        </filter>
      </defs>
      <g v:mID="0" v:index="1" v:groupContext="foregroundPage">
        <v:userDefs>
          <v:ud v:nameU="msvThemeOrder" v:val="VT0(0):26" />
        </v:userDefs>
        <title>页-1</title>
        <v:pageProperties v:drawingScale="0.0393701" v:pageScale="0.0393701" v:drawingUnits="24" v:shadowOffsetX="8.50394"
          v:shadowOffsetY="-8.50394" />
        <v:layer v:name="连接线" v:index="0" />
        <g id="shape1-1" v:mID="1" v:groupContext="shape" transform="translate(458.225,-533.173)">
          <title>矩形</title>
          <desc>董事会</desc>
          <v:userDefs>
            <v:ud v:nameU="visVersion" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" />
          <v:textRect cx="30.9767" cy="581.657" width="61.96" height="27.2368" />
          <g id="shadow1-2" v:groupContext="shadow" v:shadowOffsetX="0" v:shadowOffsetY="-3" v:shadowType="1"
            transform="matrix(1,0,0,1,0,3)" class="st1">
            <rect x="0" y="568.039" width="61.9535" height="27.2368" class="st2" />
          </g>
          <rect x="0" y="568.039" width="61.9535" height="27.2368" class="st3" />
          <text x="12.98" y="585.26" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />董事会
          </text>
        </g>
        <g id="shape2-7" v:mID="2" v:groupContext="shape" transform="translate(458.133,-490.17)">
          <title>矩形.2</title>
          <desc>总经理</desc>
          <v:userDefs>
            <v:ud v:nameU="visVersion" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" />
          <v:textRect cx="31.0224" cy="581.657" width="62.05" height="27.2368" />
          <g id="shadow2-8" v:groupContext="shadow" v:shadowOffsetX="0" v:shadowOffsetY="-3" v:shadowType="1"
            transform="matrix(1,0,0,1,0,3)" class="st1">
            <rect x="0" y="568.039" width="62.0447" height="27.2368" class="st2" />
          </g>
          <rect x="0" y="568.039" width="62.0447" height="27.2368" class="st5" />
          <text x="13.02" y="585.26" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />总经理
          </text>
        </g>
        <g id="shape3-13" v:mID="3" v:groupContext="shape" transform="translate(108.039,-406.909)">
          <title>矩形.3</title>
          <desc>子公司</desc>
          <v:userDefs>
            <v:ud v:nameU="visVersion" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" />
          <v:textRect cx="38.0976" cy="582.67" width="76.2" height="25.2117" />
          <rect x="0" y="570.064" width="76.1953" height="25.2117" class="st6" />
          <text x="20.1" y="586.27" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />子公司
          </text>
        </g>
        <g id="shape4-16" v:mID="4" v:groupContext="shape" transform="translate(312.133,-406.909)">
          <title>矩形.4</title>
          <desc>分公司</desc>
          <v:userDefs>
            <v:ud v:nameU="visVersion" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" />
          <v:textRect cx="38.0976" cy="582.67" width="76.2" height="25.2117" />
          <rect x="0" y="570.064" width="76.1953" height="25.2117" class="st6" />
          <text x="20.1" y="586.27" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />分公司
          </text>
        </g>
        <g id="shape5-19" v:mID="5" v:groupContext="shape" transform="translate(599.922,-405.943)">
          <title>矩形.5</title>
          <desc>合资公司</desc>
          <v:userDefs>
            <v:ud v:nameU="visVersion" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" />
          <v:textRect cx="38.0976" cy="582.67" width="76.2" height="25.2117" />
          <rect x="0" y="570.064" width="76.1953" height="25.2117" class="st6" />
          <text x="14.1" y="586.27" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />合资公司
          </text>
        </g>
        <g id="shape10-22" v:mID="10" v:groupContext="shape" v:layerMember="0" transform="translate(489.156,-488.613)">
          <title>动态连接线.10</title>
          <path d="M0 595.28 L0 612.12 L148.86 612.12 L148.86 654.29" class="st7" />
        </g>
        <g id="shape14-25" v:mID="14" v:groupContext="shape" transform="translate(177.773,-286.653)">
          <title>剪去单角的矩形</title>
          <desc>友一家政</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.042988840485498):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="15.476" cy="552.313" width="30.96" height="85.9259" />
          <g id="shadow14-26" v:groupContext="shadow" v:shadowOffsetX="0" v:shadowOffsetY="-3" v:shadowType="1"
            transform="matrix(1,0,0,1,0,3)" class="st1">
            <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st2" />
          </g>
          <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st8" />
          <text x="15.48" y="528.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />友一家政
          </text>
        </g>
        <g id="shape15-31" v:mID="15" v:groupContext="shape" transform="translate(238.471,-286.653)">
          <title>剪去单角的矩形.15</title>
          <desc>第一分公司</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.042988840485498):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="15.476" cy="552.313" width="30.96" height="85.9259" />
          <g id="shadow15-32" v:groupContext="shadow" v:shadowOffsetX="0" v:shadowOffsetY="-3" v:shadowType="1"
            transform="matrix(1,0,0,1,0,3)" class="st1">
            <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st2" />
          </g>
          <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st8" />
          <text x="15.48" y="522.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />第一分公司
          </text>
        </g>
        <g id="shape17-37" v:mID="17" v:groupContext="shape" transform="translate(286.613,-286.653)">
          <title>剪去单角的矩形.17</title>
          <desc>大渡口分公司</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.042988840485498):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="15.476" cy="552.313" width="30.96" height="85.9259" />
          <g id="shadow17-38" v:groupContext="shadow" v:shadowOffsetX="0" v:shadowOffsetY="-3" v:shadowType="1"
            transform="matrix(1,0,0,1,0,3)" class="st1">
            <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st2" />
          </g>
          <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st8" />
          <text x="15.48" y="516.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />大渡口分公司
          </text>
        </g>
        <g id="shape19-43" v:mID="19" v:groupContext="shape" transform="translate(130.66,-286.653)">
          <title>剪去单角的矩形.19</title>
          <desc>万众人力</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.042988840485498):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="15.476" cy="552.313" width="30.96" height="85.9259" />
          <g id="shadow19-44" v:groupContext="shadow" v:shadowOffsetX="0" v:shadowOffsetY="-3" v:shadowType="1"
            transform="matrix(1,0,0,1,0,3)" class="st1">
            <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st2" />
          </g>
          <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st8" />
          <text x="15.48" y="528.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />万众人力
          </text>
        </g>
        <g id="shape20-49" v:mID="20" v:groupContext="shape" transform="translate(622.544,-286.653)">
          <title>剪去单角的矩形.20</title>
          <desc>万优人力</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.042988840485498):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="15.476" cy="552.313" width="30.96" height="85.9259" />
          <g id="shadow20-50" v:groupContext="shadow" v:shadowOffsetX="0" v:shadowOffsetY="-3" v:shadowType="1"
            transform="matrix(1,0,0,1,0,3)" class="st1">
            <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st2" />
          </g>
          <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st8" />
          <text x="15.48" y="528.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />万优人力
          </text>
        </g>
        <g id="shape22-55" v:mID="22" v:groupContext="shape" v:layerMember="0" transform="translate(489.156,-490.17)">
          <title>动态连接线.22</title>
          <path d="M0 595.28 L0 613.25 L-343.02 613.25 L-343.02 653.32" class="st7" />
        </g>
        <g id="shape23-58" v:mID="23" v:groupContext="shape" v:layerMember="0" transform="translate(489.156,-490.17)">
          <title>动态连接线.23</title>
          <path d="M0 595.28 L0 613.25 L-138.92 613.25 L-138.92 653.32" class="st7" />
        </g>
        <g id="shape30-61" v:mID="30" v:groupContext="shape" v:layerMember="0" transform="translate(630.933,-405.943)">
          <title>动态连接线.30</title>
          <path d="M7.09 595.28 L7.09 628.64" class="st7" />
        </g>
        <g id="shape33-64" v:mID="33" v:groupContext="shape" transform="translate(214.449,-86.9516)">
          <title>圆角的矩形.33</title>
          <desc>市场发展部</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="17.5517" cy="552.313" width="35.11" height="85.9259" />
          <path
            d="M3.51 595.28 L31.59 595.28 A3.51029 3.51029 -180 0 0 35.1 591.77 L35.1 512.86 A3.51029 3.51029 -180 0 0 31.59        509.35 L3.51 509.35 A3.51029 3.51029 -180 0 0 -0 512.86 L0 591.77 A3.51029 3.51029 -180 0 0 3.51 595.28        Z"
            class="st9" />
          <text x="17.55" y="522.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />市场发展部
          </text>
        </g>
        <g id="shape34-67" v:mID="34" v:groupContext="shape" transform="translate(317.311,-86.9516)">
          <title>圆角的矩形.34</title>
          <desc>薪酬社保部</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="17.5517" cy="552.313" width="35.11" height="85.9259" />
          <path
            d="M3.51 595.28 L31.59 595.28 A3.51029 3.51029 -180 0 0 35.1 591.77 L35.1 512.86 A3.51029 3.51029 -180 0 0 31.59        509.35 L3.51 509.35 A3.51029 3.51029 -180 0 0 -0 512.86 L0 591.77 A3.51029 3.51029 -180 0 0 3.51 595.28        Z"
            class="st9" />
          <text x="17.55" y="522.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />薪酬社保部
          </text>
        </g>
        <g id="shape35-70" v:mID="35" v:groupContext="shape" transform="translate(265.88,-86.9516)">
          <title>圆角的矩形.35</title>
          <desc>客户服务部</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="17.5517" cy="552.313" width="35.11" height="85.9259" />
          <path
            d="M3.51 595.28 L31.59 595.28 A3.51029 3.51029 -180 0 0 35.1 591.77 L35.1 512.86 A3.51029 3.51029 -180 0 0 31.59        509.35 L3.51 509.35 A3.51029 3.51029 -180 0 0 -0 512.86 L0 591.77 A3.51029 3.51029 -180 0 0 3.51 595.28        Z"
            class="st9" />
          <text x="17.55" y="522.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />客户服务部
          </text>
        </g>
        <g id="shape36-73" v:mID="36" v:groupContext="shape" transform="translate(368.742,-86.9516)">
          <title>圆角的矩形.36</title>
          <desc>法务部</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="17.5517" cy="552.313" width="35.11" height="85.9259" />
          <path
            d="M3.51 595.28 L31.59 595.28 A3.51029 3.51029 -180 0 0 35.1 591.77 L35.1 512.86 A3.51029 3.51029 -180 0 0 31.59        509.35 L3.51 509.35 A3.51029 3.51029 -180 0 0 -0 512.86 L0 591.77 A3.51029 3.51029 -180 0 0 3.51 595.28        Z"
            class="st9" />
          <text x="17.55" y="534.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />法务部
          </text>
        </g>
        <g id="shape37-76" v:mID="37" v:groupContext="shape" transform="translate(471.604,-86.9516)">
          <title>圆角的矩形.37</title>
          <desc>人事代理部</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="17.5517" cy="552.313" width="35.11" height="85.9259" />
          <path
            d="M3.51 595.28 L31.59 595.28 A3.51029 3.51029 -180 0 0 35.1 591.77 L35.1 512.86 A3.51029 3.51029 -180 0 0 31.59        509.35 L3.51 509.35 A3.51029 3.51029 -180 0 0 -0 512.86 L0 591.77 A3.51029 3.51029 -180 0 0 3.51 595.28        Z"
            class="st9" />
          <text x="17.55" y="522.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />人事代理部
          </text>
        </g>
        <g id="shape38-79" v:mID="38" v:groupContext="shape" transform="translate(523.035,-86.9516)">
          <title>圆角的矩形.38</title>
          <desc>T3项目部</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="17.5517" cy="552.313" width="35.11" height="85.9259" />
          <path
            d="M3.51 595.28 L31.59 595.28 A3.51029 3.51029 -180 0 0 35.1 591.77 L35.1 512.86 A3.51029 3.51029 -180 0 0 31.59        509.35 L3.51 509.35 A3.51029 3.51029 -180 0 0 -0 512.86 L0 591.77 A3.51029 3.51029 -180 0 0 3.51 595.28        Z"
            class="st9" />
          <text x="17.55" y="522.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />T3项目部
          </text>
        </g>
        <g id="shape39-82" v:mID="39" v:groupContext="shape" transform="translate(574.466,-86.9516)">
          <title>圆角的矩形.39</title>
          <desc>信息技术部</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="17.5517" cy="552.313" width="35.11" height="85.9259" />
          <path
            d="M3.51 595.28 L31.59 595.28 A3.51029 3.51029 -180 0 0 35.1 591.77 L35.1 512.86 A3.51029 3.51029 -180 0 0 31.59        509.35 L3.51 509.35 A3.51029 3.51029 -180 0 0 -0 512.86 L0 591.77 A3.51029 3.51029 -180 0 0 3.51 595.28        Z"
            class="st9" />
          <text x="17.55" y="522.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />信息技术部
          </text>
        </g>
        <g id="shape40-85" v:mID="40" v:groupContext="shape" transform="translate(625.897,-86.9516)">
          <title>圆角的矩形.40</title>
          <desc>人力资源部</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="17.5517" cy="552.313" width="35.11" height="85.9259" />
          <path
            d="M3.51 595.28 L31.59 595.28 A3.51029 3.51029 -180 0 0 35.1 591.77 L35.1 512.86 A3.51029 3.51029 -180 0 0 31.59        509.35 L3.51 509.35 A3.51029 3.51029 -180 0 0 -0 512.86 L0 591.77 A3.51029 3.51029 -180 0 0 3.51 595.28        Z"
            class="st9" />
          <text x="17.55" y="522.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />人力资源部
          </text>
        </g>
        <g id="shape41-88" v:mID="41" v:groupContext="shape" transform="translate(677.328,-86.9516)">
          <title>圆角的矩形.41</title>
          <desc>咨询培训部</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="17.5517" cy="552.313" width="35.11" height="85.9259" />
          <path
            d="M3.51 595.28 L31.59 595.28 A3.51029 3.51029 -180 0 0 35.1 591.77 L35.1 512.86 A3.51029 3.51029 -180 0 0 31.59        509.35 L3.51 509.35 A3.51029 3.51029 -180 0 0 -0 512.86 L0 591.77 A3.51029 3.51029 -180 0 0 3.51 595.28        Z"
            class="st9" />
          <text x="17.55" y="522.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />咨询培训部
          </text>
        </g>
        <g id="shape53-91" v:mID="53" v:groupContext="shape" transform="translate(420.173,-86.9516)">
          <title>圆角的矩形.53</title>
          <desc>外包事业部</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="17.5517" cy="552.313" width="35.11" height="85.9259" />
          <path
            d="M3.51 595.28 L31.59 595.28 A3.51029 3.51029 -180 0 0 35.1 591.77 L35.1 512.86 A3.51029 3.51029 -180 0 0 31.59        509.35 L3.51 509.35 A3.51029 3.51029 -180 0 0 -0 512.86 L0 591.77 A3.51029 3.51029 -180 0 0 3.51 595.28        Z"
            class="st9" />
          <text x="17.55" y="522.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />外包事业部
          </text>
        </g>
        <g id="shape55-94" v:mID="55" v:groupContext="shape" transform="translate(334.755,-286.653)">
          <title>剪去单角的矩形.55</title>
          <desc>万盛分公司</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.042988840485498):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="15.476" cy="552.313" width="30.96" height="85.9259" />
          <g id="shadow55-95" v:groupContext="shadow" v:shadowOffsetX="0" v:shadowOffsetY="-3" v:shadowType="1"
            transform="matrix(1,0,0,1,0,3)" class="st1">
            <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st2" />
          </g>
          <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st8" />
          <text x="15.48" y="522.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />万盛分公司
          </text>
        </g>
        <g id="shape56-100" v:mID="56" v:groupContext="shape" transform="translate(382.897,-286.653)">
          <title>剪去单角的矩形.56</title>
          <desc>成都分公司</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.042988840485498):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="15.476" cy="552.313" width="30.96" height="85.9259" />
          <g id="shadow56-101" v:groupContext="shadow" v:shadowOffsetX="0" v:shadowOffsetY="-3" v:shadowType="1"
            transform="matrix(1,0,0,1,0,3)" class="st1">
            <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st2" />
          </g>
          <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st8" />
          <text x="15.48" y="522.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />成都分公司
          </text>
        </g>
        <g id="shape67-106" v:mID="67" v:groupContext="shape" transform="translate(431.039,-286.653)">
          <title>剪去单角的矩形.67</title>
          <desc>贵阳分公司</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.042988840485498):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="15.476" cy="552.313" width="30.96" height="85.9259" />
          <g id="shadow67-107" v:groupContext="shadow" v:shadowOffsetX="0" v:shadowOffsetY="-3" v:shadowType="1"
            transform="matrix(1,0,0,1,0,3)" class="st1">
            <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st2" />
          </g>
          <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st8" />
          <text x="15.48" y="522.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />贵阳分公司
          </text>
        </g>
        <g id="shape69-112" v:mID="69" v:groupContext="shape" transform="translate(83.5475,-286.653)">
          <title>剪去单角的矩形.69</title>
          <desc>万友信成</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.042988840485498):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="15.476" cy="552.313" width="30.96" height="85.9259" />
          <g id="shadow69-113" v:groupContext="shadow" v:shadowOffsetX="0" v:shadowOffsetY="-3" v:shadowType="1"
            transform="matrix(1,0,0,1,0,3)" class="st1">
            <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st2" />
          </g>
          <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st8" />
          <text x="15.48" y="528.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />万友信成
          </text>
        </g>
        <g id="shape71-118" v:mID="71" v:groupContext="shape" transform="translate(728.759,-86.9516)">
          <title>圆角的矩形.71</title>
          <desc>综合办公室</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0.04875479402549):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="17.5517" cy="552.313" width="35.11" height="85.9259" />
          <path
            d="M3.51 595.28 L31.59 595.28 A3.51029 3.51029 -180 0 0 35.1 591.77 L35.1 512.86 A3.51029 3.51029 -180 0 0 31.59        509.35 L3.51 509.35 A3.51029 3.51029 -180 0 0 -0 512.86 L0 591.77 A3.51029 3.51029 -180 0 0 3.51 595.28        Z"
            class="st9" />
          <text x="17.55" y="522.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />综合办公室
          </text>
        </g>
        <g id="shape73-121" v:mID="73" v:groupContext="shape" transform="translate(83.5475,-86.9516)">
          <title>剪去单角的矩形.73</title>
          <desc>重庆万宏优行</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.042988840485498):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="15.476" cy="552.313" width="30.96" height="85.9259" />
          <g id="shadow73-122" v:groupContext="shadow" v:shadowOffsetX="0" v:shadowOffsetY="-3" v:shadowType="1"
            transform="matrix(1,0,0,1,0,3)" class="st1">
            <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st2" />
          </g>
          <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st8" />
          <text x="15.48" y="516.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />重庆万宏优行
          </text>
        </g>
        <g id="shape74-127" v:mID="74" v:groupContext="shape" transform="translate(130.66,-86.9516)">
          <title>剪去单角的矩形.74</title>
          <desc>成都万众国辉</desc>
          <v:userDefs>
            <v:ud v:nameU="CTypeTopLeftSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CTypeTopRightSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotLeftSnip" v:prompt="" v:val="VT0(1):5" />
            <v:ud v:nameU="CTypeBotRightSnip" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockHoriz" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockVert" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="CornerLockDiag" v:prompt="" v:val="VT0(0):5" />
            <v:ud v:nameU="TopLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.15748031496063):24" />
            <v:ud v:nameU="BotLeftOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="BotRightOffset" v:prompt="" v:val="VT0(0):24" />
            <v:ud v:nameU="visVersion" v:prompt="" v:val="VT0(15):26" />
            <v:ud v:nameU="visMasterStencil" v:prompt="" v:val="VT4(Basic_M.vssx)" />
            <v:ud v:nameU="TopRightOffset" v:prompt="" v:val="VT0(0.042988840485498):1" />
          </v:userDefs>
          <v:textBlock v:margins="rect(4,4,4,4)" v:tabSpace="42.5197" v:verticalText="true" />
          <v:textRect cx="15.476" cy="552.313" width="30.96" height="85.9259" />
          <g id="shadow74-128" v:groupContext="shadow" v:shadowOffsetX="0" v:shadowOffsetY="-3" v:shadowType="1"
            transform="matrix(1,0,0,1,0,3)" class="st1">
            <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st2" />
          </g>
          <path d="M0 595.28 L30.95 595.28 L30.95 512.44 L27.86 509.35 L0 509.35 L0 595.28 Z" class="st8" />
          <text x="15.48" y="516.31" writing-mode="tb-rl" class="st4" v:langID="2052">
            <v:paragraph v:horizAlign="1" />
            <v:tabList />成都万众国辉
          </text>
        </g>
        <g id="shape75-133" v:mID="75" v:groupContext="shape" v:layerMember="0" transform="translate(139.05,-406.909)">
          <title>动态连接线</title>
          <path d="M7.09 595.28 L7.09 629.61" class="st7" />
        </g>
        <g id="shape76-136" v:mID="76" v:groupContext="shape" v:layerMember="0" transform="translate(146.136,-406.909)">
          <title>动态连接线.76</title>
          <path d="M0 595.28 L0 605.91 L-47.11 605.91 L-47.11 629.61" class="st7" />
        </g>
        <g id="shape77-139" v:mID="77" v:groupContext="shape" v:layerMember="0" transform="translate(146.136,-406.909)">
          <title>动态连接线.77</title>
          <path d="M0 595.28 L0 605.91 L47.11 605.91 L47.11 629.61" class="st7" />
        </g>
        <g id="shape78-142" v:mID="78" v:groupContext="shape" v:layerMember="0" transform="translate(350.231,-406.909)">
          <title>动态连接线.78</title>
          <path d="M0 595.28 L0 605.91 L-96.28 605.91 L-96.28 629.61" class="st7" />
        </g>
        <g id="shape79-145" v:mID="79" v:groupContext="shape" v:layerMember="0" transform="translate(350.231,-406.909)">
          <title>动态连接线.79</title>
          <path d="M0 595.28 L0 605.91 L96.28 605.91 L96.28 629.61" class="st7" />
        </g>
        <g id="shape80-148" v:mID="80" v:groupContext="shape" v:layerMember="0" transform="translate(350.231,-406.909)">
          <title>动态连接线.80</title>
          <path d="M0 595.28 L0 605.91 L-48.14 605.91 L-48.14 629.61" class="st7" />
        </g>
        <g id="shape81-151" v:mID="81" v:groupContext="shape" v:layerMember="0" transform="translate(343.144,-406.909)">
          <title>动态连接线.81</title>
          <path d="M7.09 595.28 L7.09 629.61" class="st7" />
        </g>
        <g id="shape82-154" v:mID="82" v:groupContext="shape" v:layerMember="0" transform="translate(350.231,-406.909)">
          <title>动态连接线.82</title>
          <path d="M0 595.28 L0 605.91 L48.14 605.91 L48.14 629.61" class="st7" />
        </g>
        <g id="shape83-157" v:mID="83" v:groupContext="shape" v:layerMember="0" transform="translate(496.265,-533.173)">
          <title>动态连接线.83</title>
          <path d="M-7.06 595.28 L-7.06 600.59 L-7.11 600.59 L-7.11 611.04" class="st7" />
        </g>
        <g id="shape84-160" v:mID="84" v:groupContext="shape" v:layerMember="0" transform="translate(489.156,-490.17)">
          <title>动态连接线.84</title>
          <path d="M0 595.28 L0 836.85 L257.16 836.85 L257.16 912.57" class="st7" />
        </g>
        <g id="shape85-163" v:mID="85" v:groupContext="shape" v:layerMember="0" transform="translate(496.242,-490.17)">
          <title>动态连接线.85</title>
          <path d="M-7.09 595.28 L-7.09 912.57" class="st7" />
        </g>
        <g id="shape86-166" v:mID="86" v:groupContext="shape" v:layerMember="0" transform="translate(489.156,-490.17)">
          <title>动态连接线.86</title>
          <path d="M0 595.28 L0 836.85 L-51.43 836.85 L-51.43 912.57" class="st7" />
        </g>
        <g id="shape87-169" v:mID="87" v:groupContext="shape" v:layerMember="0" transform="translate(489.156,-490.17)">
          <title>动态连接线.87</title>
          <path d="M0 595.28 L0 836.85 L51.43 836.85 L51.43 912.57" class="st7" />
        </g>
        <g id="shape88-172" v:mID="88" v:groupContext="shape" v:layerMember="0" transform="translate(489.156,-490.17)">
          <title>动态连接线.88</title>
          <path d="M0 595.28 L0 836.85 L102.86 836.85 L102.86 912.57" class="st7" />
        </g>
        <g id="shape89-175" v:mID="89" v:groupContext="shape" v:layerMember="0" transform="translate(489.156,-490.17)">
          <title>动态连接线.89</title>
          <path d="M0 595.28 L0 836.85 L-102.86 836.85 L-102.86 912.57" class="st7" />
        </g>
        <g id="shape90-178" v:mID="90" v:groupContext="shape" v:layerMember="0" transform="translate(489.156,-490.17)">
          <title>动态连接线.90</title>
          <path d="M0 595.28 L0 836.85 L154.29 836.85 L154.29 912.57" class="st7" />
        </g>
        <g id="shape91-181" v:mID="91" v:groupContext="shape" v:layerMember="0" transform="translate(489.156,-490.17)">
          <title>动态连接线.91</title>
          <path d="M0 595.28 L0 836.85 L205.72 836.85 L205.72 912.57" class="st7" />
        </g>
        <g id="shape92-184" v:mID="92" v:groupContext="shape" v:layerMember="0" transform="translate(489.156,-490.17)">
          <title>动态连接线.92</title>
          <path d="M0 595.28 L0 836.85 L-154.29 836.85 L-154.29 912.57" class="st7" />
        </g>
        <g id="shape93-187" v:mID="93" v:groupContext="shape" v:layerMember="0" transform="translate(489.156,-490.17)">
          <title>动态连接线.93</title>
          <path d="M0 595.28 L0 836.85 L-205.72 836.85 L-205.72 912.57" class="st7" />
        </g>
        <g id="shape94-190" v:mID="94" v:groupContext="shape" v:layerMember="0" transform="translate(489.156,-490.17)">
          <title>动态连接线.94</title>
          <path d="M0 595.28 L0 836.85 L-257.16 836.85 L-257.16 912.57" class="st7" />
        </g>
        <g id="shape95-193" v:mID="95" v:groupContext="shape" v:layerMember="0" transform="translate(91.9368,-286.653)">
          <title>动态连接线.95</title>
          <path d="M7.09 595.28 L7.09 709.05" class="st7" />
        </g>
        <g id="shape96-196" v:mID="96" v:groupContext="shape" v:layerMember="0" transform="translate(139.05,-286.653)">
          <title>动态连接线.96</title>
          <path d="M7.09 595.28 L7.09 709.05" class="st7" />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "./index.mobile.scss";
</style>
