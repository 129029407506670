<template>
  <v-footer class="z-index" dark>
    <div class="container text-center py-13">
      <v-row>
        <v-col cols="12">
          <v-img class="logo-grey mx-auto" width="64" height="64" :src="$nginxUrl + 'logo/logo-grey.png'" />
        </v-col>
        <v-col cols="12">
          <div class="btn-bar" :class="{ help: isHelp }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab icon plain large v-model="phoneListDialogShow"
                  @click="phoneListDialogShow = !phoneListDialogShow" dark v-bind="attrs" v-on="on">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" x="300" y="240">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M15 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15zm5.517-8.448l.47-.475c.173-.174.339-.426.485-.688.356-.635.07-1.41-.558-1.778l-2.134-1.25a1.5 1.5 0 00-1.55.02l-.493.307c-.337.21-.861.177-1.167-.074 0 0-.936-.737-1.638-1.447-.709-.716-1.585-1.818-1.585-1.818-.25-.308-.285-.834-.078-1.174l.298-.489a1.5 1.5 0 00-.02-1.59l-1.373-2.14c-.363-.565-1.07-.837-1.657-.51a2.72 2.72 0 00-.603.436l-.47.475c-1.3 1.312.35 5.105 3.685 8.473 3.334 3.368 7.09 5.034 8.388 3.722z"
                      fill="#267EF0" />
                  </svg>
                </v-btn>
              </template>
              <span>联系电话</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab icon plain large @click="
                  wechatPublicAccountDialogShow =
                  !wechatPublicAccountDialogShow
                  " v-bind="attrs" v-on="on">
                  <svg t="1644819747063" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="8254" width="32" height="32">
                    <path
                      d="M690.1 377.4c5.9 0 11.8 0.2 17.6 0.5-24.4-128.7-158.3-227.1-319.9-227.1C209 150.8 64 271.4 64 420.2c0 81.1 43.6 154.2 111.9 203.6 5.5 3.9 9.1 10.3 9.1 17.6 0 2.4-0.5 4.6-1.1 6.9-5.5 20.3-14.2 52.8-14.6 54.3-0.7 2.6-1.7 5.2-1.7 7.9 0 5.9 4.8 10.8 10.8 10.8 2.3 0 4.2-0.9 6.2-2l70.9-40.9c5.3-3.1 11-5 17.2-5 3.2 0 6.4 0.5 9.5 1.4 33.1 9.5 68.8 14.8 105.7 14.8 6 0 11.9-0.1 17.8-0.4-7.1-21-10.9-43.1-10.9-66 0-135.8 132.2-245.8 295.3-245.8z m-194.3-86.5c23.8 0 43.2 19.3 43.2 43.1s-19.3 43.1-43.2 43.1c-23.8 0-43.2-19.3-43.2-43.1s19.4-43.1 43.2-43.1z m-215.9 86.2c-23.8 0-43.2-19.3-43.2-43.1s19.3-43.1 43.2-43.1 43.2 19.3 43.2 43.1-19.4 43.1-43.2 43.1z"
                      p-id="8255" fill="#07c160"></path>
                    <path
                      d="M866.7 792.7c56.9-41.2 93.2-102 93.2-169.7 0-124-120.8-224.5-269.9-224.5-149 0-269.9 100.5-269.9 224.5S540.9 847.5 690 847.5c30.8 0 60.6-4.4 88.1-12.3 2.6-0.8 5.2-1.2 7.9-1.2 5.2 0 9.9 1.6 14.3 4.1l59.1 34c1.7 1 3.3 1.7 5.2 1.7 2.4 0 4.7-0.9 6.4-2.6 1.7-1.7 2.6-4 2.6-6.4 0-2.2-0.9-4.4-1.4-6.6-0.3-1.2-7.6-28.3-12.2-45.3-0.5-1.9-0.9-3.8-0.9-5.7 0.1-5.9 3.1-11.2 7.6-14.5zM600.2 587.2c-19.9 0-36-16.1-36-35.9 0-19.8 16.1-35.9 36-35.9s36 16.1 36 35.9c0 19.8-16.2 35.9-36 35.9z m179.9 0c-19.9 0-36-16.1-36-35.9 0-19.8 16.1-35.9 36-35.9s36 16.1 36 35.9c-0.1 19.8-16.2 35.9-36 35.9z"
                      p-id="8256" fill="#07c160"></path>
                  </svg>
                </v-btn>
              </template>
              <span>关注我们微信公众号</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab icon plain large v-model="wechatDialogShow" @click="wechatDialogShow = !wechatDialogShow"
                  v-bind="attrs" v-on="on"><svg xmlns="http://www.w3.org/2000/svg" width="31" height="26" x="240" y="210">
                    <g fill="none" fill-rule="evenodd">
                      <path fill="#0082F0"
                        d="M24.27 6.9a10.746 10.746 0 00-1.945-2.764C20.295 2.034 17.453.68 14.322.323a14.773 14.773 0 00-1.656-.096c-.511 0-1.044.03-1.585.089-3.144.342-6.002 1.69-8.047 3.792a10.757 10.757 0 00-1.955 2.757 9.634 9.634 0 00-1.003 4.277c0 1.909.58 3.79 1.678 5.442a14.96 14.96 0 002.302 2.634v.002l-.378 2.974c-.015.04-.031.08-.04.123-.01.039-.012.079-.016.119-.002.03-.01.06-.01.091 0 .033.008.065.01.098.05.489.458.872.96.872.175 0 .337-.05.478-.131l.014-.008c.02-.012.042-.023.062-.036l.903-.454 2.69-1.352a13.93 13.93 0 002.35.453 14.54 14.54 0 003.243-.009 14.092 14.092 0 003.173-.742 1.752 1.752 0 01-1.192-1.832c-.72.23-1.472.393-2.25.482a12.172 12.172 0 01-2.716.008c-.09-.01-.182-.025-.274-.037a11.976 11.976 0 01-1.78-.373 1.217 1.217 0 00-.37-.057c-.2 0-.392.053-.588.154-.026.014-.05.025-.076.04l-2.21 1.303-.096.057-.003.001c-.047.028-.075.039-.1.039a.15.15 0 01-.146-.153l.085-.345.098-.375.162-.614.179-.686a.93.93 0 00-.334-1.033 9.906 9.906 0 01-.923-.777c-.5-.475-.94-.994-1.31-1.55-.869-1.308-1.328-2.792-1.328-4.293 0-1.164.266-2.297.79-3.366a8.633 8.633 0 011.569-2.21c1.68-1.728 4.045-2.837 6.656-3.12.454-.05.901-.075 1.328-.075.449 0 .916.027 1.388.08 2.599.296 4.949 1.41 6.617 3.138a8.618 8.618 0 011.56 2.215 7.584 7.584 0 01.778 3.338c0 .12-.007.24-.013.36a1.746 1.746 0 012.149.251c.03.03.054.062.08.093a9.11 9.11 0 00.031-.739 9.634 9.634 0 00-.986-4.243" />
                      <path fill="#FB6500"
                        d="M24.149 22.148a1.7 1.7 0 00-.21-.035 5.377 5.377 0 01-2.996-1.67v.001a.418.418 0 10-.534.64c.041.04.082.078.121.118a5.39 5.39 0 011.478 2.761 1.715 1.715 0 00.059.356 1.749 1.749 0 002.926.777 1.754 1.754 0 00-.844-2.948" />
                      <path fill="#0082F0"
                        d="M29.944 17.673a1.75 1.75 0 00-2.98 1.056 5.388 5.388 0 01-1.667 2.999.419.419 0 10.641.534l.117-.121a5.376 5.376 0 012.758-1.48 1.71 1.71 0 00.355-.058 1.754 1.754 0 00.776-2.93" />
                      <path fill="#2DBC00"
                        d="M22.53 12.717a1.754 1.754 0 001.054 2.982 5.376 5.376 0 012.996 1.67.419.419 0 10.534-.641 5.389 5.389 0 01-1.6-2.879 1.625 1.625 0 00-.058-.355 1.75 1.75 0 00-2.926-.777" />
                      <path fill="#FC0"
                        d="M20.543 19.19a1.93 1.93 0 00.015-.106 5.393 5.393 0 011.668-3 .42.42 0 10-.64-.534 5.375 5.375 0 01-2.876 1.6 1.73 1.73 0 00-.355.06 1.754 1.754 0 00-.776 2.93 1.75 1.75 0 002.964-.95" />
                    </g>
                  </svg>
                </v-btn>
              </template>
              <span>找工作上企业微信</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab icon plain large v-bind="attrs" v-on="on" @click="baiduMapDialogShow = !baiduMapDialogShow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" x="330" y="270">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M15 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15zm5.745-16.34a6.17 6.17 0 00.05-.777c0-3.25-2.53-5.883-5.648-5.883C12.028 7 9.5 9.634 9.5 12.883c0 .263.017.523.049.777h-.01c.226 2.392 2.958 6.017 4.525 7.921a1.39 1.39 0 002.167-.006c1.556-1.91 4.27-5.54 4.527-7.915h-.013zm-3.427-.889c0 1.226-.972 2.22-2.171 2.22-1.2 0-2.171-.994-2.171-2.22 0-1.226.972-2.22 2.171-2.22 1.2 0 2.171.994 2.171 2.22z"
                      fill="#ec6750" />
                  </svg>
                </v-btn>
              </template>
              <span>公司位置</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <a href="https://www.douyin.com/user/MS4wLjABAAAA7a6Ogv2wIsU2PpSeOqtmaUg8bs6e02lLIgcDC7K6MG6S4sm4SkOTHJu9ti148VPQ"
                  target="_Blank">
                  <v-btn fab icon plain large v-bind="attrs" v-on="on">
                    <svg t="1686035830486" class="icon" viewBox="0 0 1024 1024" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" p-id="3524" width="34" height="34">
                      <path d="M513.12 523.2m-464 0a464 464 0 1 0 928 0 464 464 0 1 0-928 0Z" p-id="3525"></path>
                      <path d="M618.24 458.24l11.04 5.6-11.04-6.72z" fill="#FFFFFF" p-id="3526"></path>
                      <path
                        d="M532.96 624.8a24 24 0 0 0 0-5.92V293.12h66.08c0-3.2 0-6.56-1.12-9.92h-83.2v325.92a23.36 23.36 0 0 1 0 5.76 68.96 68.96 0 0 1-88.16 51.2 67.36 67.36 0 0 0 106.88-41.28zM322.08 662.24a146.24 146.24 0 0 1-9.12-57.92A152.32 152.32 0 0 1 422.24 464a136.64 136.64 0 0 1 25.76-5.76V448a142.72 142.72 0 0 0-44 6.08A152.64 152.64 0 0 0 304 652.32a156.48 156.48 0 0 0 83.04 88.32 155.84 155.84 0 0 1-64.96-78.4zM713.6 398.08a110.24 110.24 0 0 1-41.6-8.32 109.76 109.76 0 0 0 41.6 16z"
                        fill="#00F5FF" opacity=".7" p-id="3527" data-spm-anchor-id="a313x.7781069.0.i20" class=""></path>
                      <path
                        d="M633.28 363.84A120.16 120.16 0 0 0 672 389.76a132.8 132.8 0 0 1-20-16 122.08 122.08 0 0 1-34.72-80h-17.6a116.16 116.16 0 0 0 33.6 70.08zM713.6 406.24v76.48A188 188 0 0 1 629.28 464a196.16 196.16 0 0 0 103.04 28.8v-84.8a119.84 119.84 0 0 1-18.72-1.76zM599.52 454.4v146.24a153.28 153.28 0 0 1-128 150.72 148.8 148.8 0 0 1-84.8-10.72 149.76 149.76 0 0 0 103.52 20.64 153.44 153.44 0 0 0 128-150.88v-152.16q-9.44-5.12-18.72-11.04zM435.04 532.32A68.32 68.32 0 0 0 378.24 592a69.92 69.92 0 0 0 40 71.04 48 48 0 0 0 7.84 3.2 68.48 68.48 0 0 1 27.68-123.84c4.16 0 8.32-1.12 12.48-1.76v-83.2a144 144 0 0 0-18.72 0.8v72.48c-4.16 0.48-8.32 0.8-12.48 1.6z"
                        fill="#FF4040" opacity=".4" p-id="3528" data-spm-anchor-id="a313x.7781069.0.i18" class="selected">
                      </path>
                      <path
                        d="M713.6 482.72v-76.48a109.76 109.76 0 0 1-41.6-16 120.16 120.16 0 0 1-38.72-25.92 116.16 116.16 0 0 1-33.6-70.72h-66.08v325.76a24 24 0 0 1 0 5.92 67.36 67.36 0 0 1-106.88 41.28 48 48 0 0 1-7.84-3.2A69.92 69.92 0 0 1 378.24 592a68.32 68.32 0 0 1 56.8-59.52c4.16-0.8 8.32-1.12 12.48-1.6v-72.64a136.64 136.64 0 0 0-25.28 5.28 152.32 152.32 0 0 0-109.28 140.8 146.24 146.24 0 0 0 9.12 57.92 155.84 155.84 0 0 0 64 78.4 148.8 148.8 0 0 0 84.8 10.72 153.28 153.28 0 0 0 128-150.72V448q9.44 6.08 18.72 11.04v-1.12l11.04 6.72a188 188 0 0 0 84.96 18.08z"
                        fill="#FFFFFF" p-id="3529"></path>
                    </svg>
                  </v-btn>
                </a>
              </template>
              <span>关注我们的抖音账号</span>
            </v-tooltip>


            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <a href="https://www.kuaishou.com/profile/3x7w8nujdeukuzm" target="_Blank">
                  <v-btn fab icon plain large v-bind="attrs" v-on="on">
                    <svg t="1686036648177" class="icon" viewBox="0 0 1024 1024" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" p-id="4661" width="30" height="30">
                      <path
                        d="M640 149.333333a149.333333 149.333333 0 0 0-102.613333 41.173334 170.666667 170.666667 0 1 0 0 216.32A149.333333 149.333333 0 1 0 640 149.333333zM405.333333 384a85.333333 85.333333 0 1 1 85.333334-85.333333 85.333333 85.333333 0 0 1-85.333334 85.333333z m234.666667-21.333333a64 64 0 1 1 64-64 64 64 0 0 1-64 64z m116.906667 106.666666h-234.666667a139.093333 139.093333 0 0 0-128 85.333334l-106.666667-62.506667A106.666667 106.666667 0 0 0 128 584.32V789.333333a106.666667 106.666667 0 0 0 160.853333 91.946667l108.8-64A138.88 138.88 0 0 0 523.093333 896h234.666667A139.093333 139.093333 0 0 0 896 756.906667v-149.333334A139.093333 139.093333 0 0 0 756.906667 469.333333z m-512 337.92A21.333333 21.333333 0 0 1 234.666667 810.666667a21.333333 21.333333 0 0 1-21.333334-21.333334v-205.013333a21.333333 21.333333 0 0 1 21.333334-21.333333 21.333333 21.333333 0 0 1 10.666666 3.2l138.666667 81.28V725.333333zM810.666667 756.906667A53.76 53.76 0 0 1 756.906667 810.666667h-234.666667A53.76 53.76 0 0 1 469.333333 756.906667v-149.333334A53.76 53.76 0 0 1 523.093333 554.666667h234.666667A53.76 53.76 0 0 1 810.666667 608.426667z"
                        p-id="4662" fill="#FF6734"></path>
                    </svg>
                  </v-btn>
                </a>
              </template>
              <span>关注我们的快手账号</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="search-input v-responsive mx-auto">
            <v-text-field dark single-line solo solo-flat label="搜索" rounded background-color="grey"
              v-model="searchValue">
              <v-btn icon tile depressed slot="append" color="primary" @click="search" plain>
                <svg t="1647322381342" class="icon" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="1929" width="32" height="32" fill="#ffffffcc">
                  <path
                    d="M871.38476563 906.54101563c-8.96484375 0-18.01757813-3.42773438-24.87304688-10.28320313L733.8359375 783.58203125c-13.7109375-13.7109375-13.7109375-35.94726563 0-49.74609375s35.94726563-13.7109375 49.74609375 0l112.67578125 112.67578125c13.7109375 13.7109375 13.7109375 35.94726563 0 49.74609375-6.85546875 6.85546875-15.90820313 10.28320313-24.87304688 10.28320313zM464.71484375 811.44335938c-46.7578125 0-92.02148438-9.140625-134.73632813-27.24609376-41.22070313-17.40234375-78.22265625-42.36328125-110.03906249-74.17968749S163.16210937 641.19921875 145.75976562 599.97851563c-18.10546875-42.71484375-27.24609375-88.06640625-27.24609374-134.73632813s9.140625-92.02148438 27.24609375-134.73632813c17.40234375-41.22070313 42.36328125-78.22265625 74.17968749-110.03906249s68.81835938-56.77734375 110.0390625-74.1796875c42.71484375-18.10546875 88.06640625-27.24609375 134.73632813-27.24609375 46.7578125 0 92.02148438 9.140625 134.73632813 27.24609375 41.22070313 17.40234375 78.22265625 42.36328125 110.03906249 74.1796875s56.77734375 68.81835938 74.1796875 110.0390625c18.10546875 42.71484375 27.24609375 88.06640625 27.24609375 134.73632812s-9.140625 92.02148438-27.24609375 134.73632813c-17.40234375 41.22070313-42.36328125 78.22265625-74.1796875 110.03906249s-68.81835938 56.77734375-110.0390625 74.1796875c-42.71484375 18.10546875-88.06640625 27.24609375-134.73632812 27.24609375z m0-622.08984375c-152.13867188 0-275.88867188 123.75-275.88867188 275.88867187 0 152.13867188 123.75 275.88867188 275.88867188 275.88867188s275.88867188-123.75 275.88867188-275.88867188c0-152.05078125-123.75-275.88867188-275.88867188-275.88867187z"
                    p-id="1930"></path>
                </svg>
              </v-btn>
            </v-text-field>
          </div>
          <div class="text-center text-caption drop-shadow">
            <span>促社会和谐发展， 为客户创造价值， 助员工实现梦想</span>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="v-responsive mx-auto" style="max-width: 70%; width: 250px">
            <div class="v-responsive__content">
              <hr role="separator" aria-orientation="horizontal" class="v-divider theme--dark" />
            </div>
          </div>
        </v-col>
        <v-row justify="space-between">
          <v-col class="text-center footer drop-shadow">
            <div>
              <span class="px-2">重庆万友人才服务有限公司 版权所有</span>
              <v-divider vertical></v-divider>
              <span class="px-2">人力资源服务许可证 (渝)人服证字 [2022]第0000003912</span>
            </div>
            <div>
              <a href="https://beian.miit.gov.cn/" target="_Blank">
                <span class="px-2">经营许可证渝ICP备11004941号-2</span>
              </a>
              <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010302004655">
                <v-img style="display: inline-block;padding:2px" height="18px" width="18px"
                  :src="$nginxUrl + 'logo/record.png'" />
                <span>渝公网安备 50010302004655号</span>
              </a>
            </div>

            <div>
              <span>
                Copyright &copy; 2004 - 2021 Chongqing Wanyou Talents Service
                Co.,Ltd. All Rights Reserved.
              </span>
            </div>
          </v-col>
        </v-row>
      </v-row>
    </div>
    <phone-book :phoneListDialogShow.sync="phoneListDialogShow" @closeDialog="handleCloseDialog" />
    <wechat-dialog :wechatDialogShow.sync="wechatDialogShow" @closeDialog="handleCloseDialog" />
    <wechat-public-account-dialog :wechatPublicAccountDialogShow.sync="wechatPublicAccountDialogShow"
      @closeDialog="handleCloseDialog" />

    <navigate-dialog :baiduMapDialogShow.sync="baiduMapDialogShow" @closeDialog="handleCloseDialog" />

    <v-snackbar light text rounded="pill" v-model="snackbar">
      请输入搜索关键字
      <template v-slot:action="{ attrs }">
        <v-btn rounded color="pink" top text v-bind="attrs" @click="snackbar = false">
          关闭
        </v-btn>
      </template>
    </v-snackbar>
  </v-footer>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>