<template>
  <div>
    <v-container>
      <caption-widget title="公司概况" subtitle="Company Overview" />
      <company-history />
      <br />
      <v-divider></v-divider>
      <br />
      <div class="overview-box">
        <v-tabs v-model="companyProfileTab" color="#333333" height="60" background-color="#00000000">
          <v-tab v-for="item in companyProfileTabItems" :key="item" class="bg">
            <span class="font-weight-black" :class="{
              'text-h6 px-6': $vuetify.breakpoint.xl,
              'text-subtitle-2 ': !$vuetify.breakpoint.xl,
            }">
              {{ item }}
            </span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="companyProfileTab" class="bg" show-arrows show-arrows-on-hover>
          <v-tab-item v-for="item in companyProfileTabItems" :key="item">
            <company-description v-if="item == '公司简介'" />
            <company-culture v-if="item == '企业文化'" />
            <company-qualification v-if="item == '资质荣誉'" />
            <company-organization v-if="item == '组织架构'" />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>

<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "./index.mobile.scss";
</style>