import { listNews } from "@/api/news";
import homeNewsItem from "../staff-item/index.vue";
export default {
  name: "staff-history",
  props: {
    historyNewsShow: {
      type: Boolean,
      default: false,
    },
  },
  components: { homeNewsItem },
  data: () => ({
    newsId: 0,
    newsItemShow: false,
    //信息公告
    queryParams: {
      pageNum: 1,
      pageSize: 10,
      newsTitle: null,
      newsSubtitle: null,
      newsDesc: null,
      newsContent: null,
      newsInitialUserName: null,
      newsInitialDt: null,
      newsImage: null,
      newsFile: null,
      newMdfUserName: null,
      updatedDt: null,
      newsViewed: null,
      newsType: 1,
    },
    date: new Date().toISOString().substring(0, 7),
    menu: false,
    newsList: [],
    total: 0,
    overlay: true,
  }),
  watch: {
    queryParams: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    historyNewsShow(val) {
      if (val) {
        this.getList();
      }
    }
  },
  methods: {
    /** 查询信息公告列表 */
    getList: function () {
      listNews(this.queryParams).then((response) => {
        this.newsList = response.data.rows;
        this.total = response.data.total;
        this.overlay = false;
      });
    },
    // 表单重置
    reset: function () {
      let _this = this;
      _this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        newsTitle: null,
        newsSubtitle: null,
        newsDesc: null,
        newsContent: null,
        newsInitialUserName: null,
        newsInitialDt: null,
        newsImage: null,
        newsFile: null,
        newMdfUserName: null,
        updatedDt: null,
        newsViewed: null,
      };
      _this.handleQuery();
    },
    handleQuery: function () {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    changeDate: function (date) {
      this.queryParams.newsInitialDt = date + "-01";
    },
    openNewsItem: function (id) {
      this.newsId = id;
      this.newsItemShow = true;
    },
  },
};