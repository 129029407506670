import companyHistory from "./company-history/index.vue";
import companyDescription from "./company-description/index.vue";
import companyCulture from "./company-culture/index.vue";
import companyOrganization from "./company-organization/index.vue";
import companyQualification from "./company-qualification/index.vue";
export default {
    components: {
        'company-history': companyHistory,
        'company-description': companyDescription,
        'company-culture': companyCulture,
        'company-organization': companyOrganization,
        'company-qualification': companyQualification,
    },
    name: "overview-section",
    data() {
        return {
            //公司概况
            companyProfileTab: null,
            companyProfileTabItems: ["公司简介", "企业文化", "组织架构", "资质荣誉"],
        };
    },
};