<template>
  <div>
    <div class=" culture-wrap" v-if="$vuetify.breakpoint.xl">
      <div class="culture-box ">
        <div class="t-box">
          <div class="pic">
            <v-img class="pc-box" :src="$nginxUrl + 'company/book.jpg'" alt="img" aspect-ratio
              :height="$vuetify.breakpoint.xl ? 700 : 200" />
          </div>
          <div class="mess-box">
            <div class="t-flx ">
              <div class="label-box">
                <h3 class="item-title">企业文化</h3>
              </div>
              <div class="b-box">
                <div class="boxx">
                  <div class="x-item">
                    <h5>我们的使命</h5>
                    <v-divider inset></v-divider>
                    <br />
                    <v-tooltip bottom color="success">
                      <template v-slot:activator="{ on, attrs }">
                        <p class="p" dark v-bind="attrs" v-on="on">
                          促社会和谐发展，
                        </p>
                      </template>
                      <span>拓展就业机会，促社会人才合理流动，推动社会经济发展；诚信经营、合规管理，利于社会和谐稳定；与合作伙伴、监管机构和利益相关者保持良好关系，融入社区、担当责任、开放边界、共享协同、共生成长。</span>
                    </v-tooltip>
                    <v-tooltip bottom color="success">
                      <template v-slot:activator="{ on, attrs }">
                        <p class="p" dark v-bind="attrs" v-on="on">
                          为客户创造价值，
                        </p>
                      </template>
                      <span>诚信为先，规范经营，遵守法规政策底线，坚持原则；对承诺保持敬畏心，言而有信，不找任何借口。</span>
                    </v-tooltip>
                    <v-tooltip bottom color="success">
                      <template v-slot:activator="{ on, attrs }">
                        <p class="p" dark v-bind="attrs" v-on="on">
                          助员工实现梦想
                        </p>
                      </template>
                      <span>专业是核心竞争力，专业包括网络与系统技术，从业知识、技能、经验和综合服务方案，客户关系维护方法，人员/客户渠道开发能力，逻辑分析和重混创新能力等；服务不仅是工作职责和提升能力，更是生活方式和扩大朋友圈；客户服务，包括基础事务、解决问题等功能需求；也包括身份认同、关系维护等情感需求，体现为服务的近距离与亲和乐意的温度；尽职尽责，严格慎独，敢于担当，满足和超越客户需求。</span>
                    </v-tooltip>
                  </div>
                  <div class="x-item">
                    <h5>价值观</h5>
                    <v-divider inset></v-divider>
                    <br />
                    <p class="p">
                      <v-tooltip bottom color="light-blue">
                        <template v-slot:activator="{ on, attrs }">
                          <span dark v-bind="attrs" v-on="on">
                            以人为本，
                          </span>
                        </template>
                        <span>人力资源公司的“人”是第一位的资源，重视人的成长，鼓励员工充分发挥智慧；人性化管理，尊重员工，关怀员工，善待员工，激励员工，提升工作的价值，赋予生活的意义。</span>
                      </v-tooltip>
                      <v-tooltip bottom color="light-blue">
                        <template v-slot:activator="{ on, attrs }">
                          <span dark v-bind="attrs" v-on="on">
                            诚信经营，
                          </span>
                        </template>
                        <span>诚信为先，规范经营，遵守法规政策底线，坚持原则；对承诺保持敬畏心，言而有信，不找任何借口。</span>
                      </v-tooltip>
                      <v-tooltip bottom color="light-blue">
                        <template v-slot:activator="{ on, attrs }">
                          <span dark v-bind="attrs" v-on="on">
                            专业服务，
                          </span>
                        </template>
                        <span>专业是核心竞争力，专业包括网络与系统技术，从业知识、技能、经验和综合服务方案，客户关系维护方法，人员/客户渠道开发能力，逻辑分析和重混创新能力等；服务不仅是工作职责和提升能力，更是生活方式和扩大朋友圈；客户服务，包括基础事务、解决问题等功能需求；也包括身份认同、关系维护等情感需求，体现为服务的近距离与亲和乐意的温度；尽职尽责，严格慎独，敢于担当，满足和超越客户需求。</span>
                      </v-tooltip>
                      <v-tooltip bottom color="light-blue">
                        <template v-slot:activator="{ on, attrs }">
                          <span dark v-bind="attrs" v-on="on">
                            创新发展，
                          </span>
                        </template>
                        <span>创新不仅是原创，更是整合，包括产品创新、技术创新、制度创新，模式创新等；勤于工作，恒于学习，善于思考，敢于创新，持续发展。</span>
                      </v-tooltip>
                      <v-tooltip bottom color="light-blue">
                        <template v-slot:activator="{ on, attrs }">
                          <span dark v-bind="attrs" v-on="on"> 共享共赢 </span>
                        </template>
                        <span>合法合理、平等尊重，信息共享，利益共同，合作共赢。</span>
                      </v-tooltip>
                    </p>
                  </div>
                  <div class="x-item">
                    <h5>公司愿景</h5>
                    <v-divider inset></v-divider>
                    <br />
                    <v-tooltip bottom color="purple">
                      <template v-slot:activator="{ on, attrs }">
                        <p class="p" dark v-bind="attrs" v-on="on">
                          成为客户和员工最信赖的伙伴，
                        </p>
                      </template>
                      <span>规范、专业、核心技术，提供全方位的解决方案，持续为相关方创造价值。</span>
                    </v-tooltip>
                    <v-tooltip bottom color="purple">
                      <template v-slot:activator="{ on, attrs }">
                        <p class="p" dark v-bind="attrs" v-on="on">
                          成为人力资源服务业的领军者
                        </p>
                      </template>
                      <span>公司品牌、市场布局、市场规模、核心产品、优势竞争力。</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- $vuetify.breakpoint.xl" -->
    <div class=" culture-wrap-mobile" v-else>
      <div class="culture-box-mobile ">
        <div class="t-box">
          <div class="pic">
            <v-img class="pc-box" :src="$nginxUrl + 'company/book.jpg'" alt="img" aspect-ratio
              :height="$vuetify.breakpoint.xl ? 700 : 200" />
          </div>
          <div class="mess-box">
            <div class="t-flx ">
              <div class="label-box">
                <h3 class="item-title">企业文化</h3>
              </div>
              <div class="b-box">
                <div class="boxx">
                  <div class="x-item">
                    <h5>我们的使命</h5>
                    <v-divider inset></v-divider>
                    <br />
                    <v-tooltip bottom color="success">
                      <template v-slot:activator="{ on, attrs }">
                        <p class="p" dark v-bind="attrs" v-on="on">
                          促社会和谐发展，
                        </p>
                      </template>
                      <span>拓展就业机会，促社会人才合理流动，推动社会经济发展；诚信经营、合规管理，利于社会和谐稳定；与合作伙伴、监管机构和利益相关者保持良好关系，融入社区、担当责任、开放边界、共享协同、共生成长。</span>
                    </v-tooltip>
                    <v-tooltip bottom color="success">
                      <template v-slot:activator="{ on, attrs }">
                        <p class="p" dark v-bind="attrs" v-on="on">
                          为客户创造价值，
                        </p>
                      </template>
                      <span>诚信为先，规范经营，遵守法规政策底线，坚持原则；对承诺保持敬畏心，言而有信，不找任何借口。</span>
                    </v-tooltip>
                    <v-tooltip bottom color="success">
                      <template v-slot:activator="{ on, attrs }">
                        <p class="p" dark v-bind="attrs" v-on="on">
                          助员工实现梦想
                        </p>
                      </template>
                      <span>专业是核心竞争力，专业包括网络与系统技术，从业知识、技能、经验和综合服务方案，客户关系维护方法，人员/客户渠道开发能力，逻辑分析和重混创新能力等；服务不仅是工作职责和提升能力，更是生活方式和扩大朋友圈；客户服务，包括基础事务、解决问题等功能需求；也包括身份认同、关系维护等情感需求，体现为服务的近距离与亲和乐意的温度；尽职尽责，严格慎独，敢于担当，满足和超越客户需求。</span>
                    </v-tooltip>
                  </div>
                  <div class="x-item">
                    <h5>价值观</h5>
                    <v-divider inset></v-divider>
                    <br />
                    <p class="p">
                      <v-tooltip bottom color="light-blue">
                        <template v-slot:activator="{ on, attrs }">
                          <span dark v-bind="attrs" v-on="on">
                            以人为本，
                          </span>
                        </template>
                        <span>人力资源公司的“人”是第一位的资源，重视人的成长，鼓励员工充分发挥智慧；人性化管理，尊重员工，关怀员工，善待员工，激励员工，提升工作的价值，赋予生活的意义。</span>
                      </v-tooltip>
                      <v-tooltip bottom color="light-blue">
                        <template v-slot:activator="{ on, attrs }">
                          <span dark v-bind="attrs" v-on="on">
                            诚信经营，
                          </span>
                        </template>
                        <span>诚信为先，规范经营，遵守法规政策底线，坚持原则；对承诺保持敬畏心，言而有信，不找任何借口。</span>
                      </v-tooltip>
                      <v-tooltip bottom color="light-blue">
                        <template v-slot:activator="{ on, attrs }">
                          <span dark v-bind="attrs" v-on="on">
                            专业服务，
                          </span>
                        </template>
                        <span>专业是核心竞争力，专业包括网络与系统技术，从业知识、技能、经验和综合服务方案，客户关系维护方法，人员/客户渠道开发能力，逻辑分析和重混创新能力等；服务不仅是工作职责和提升能力，更是生活方式和扩大朋友圈；客户服务，包括基础事务、解决问题等功能需求；也包括身份认同、关系维护等情感需求，体现为服务的近距离与亲和乐意的温度；尽职尽责，严格慎独，敢于担当，满足和超越客户需求。</span>
                      </v-tooltip>
                      <v-tooltip bottom color="light-blue">
                        <template v-slot:activator="{ on, attrs }">
                          <span dark v-bind="attrs" v-on="on">
                            创新发展，
                          </span>
                        </template>
                        <span>创新不仅是原创，更是整合，包括产品创新、技术创新、制度创新，模式创新等；勤于工作，恒于学习，善于思考，敢于创新，持续发展。</span>
                      </v-tooltip>
                      <v-tooltip bottom color="light-blue">
                        <template v-slot:activator="{ on, attrs }">
                          <span dark v-bind="attrs" v-on="on"> 共享共赢 </span>
                        </template>
                        <span>合法合理、平等尊重，信息共享，利益共同，合作共赢。</span>
                      </v-tooltip>
                    </p>
                  </div>
                  <div class="x-item">
                    <h5>公司愿景</h5>
                    <v-divider inset></v-divider>
                    <br />
                    <v-tooltip bottom color="purple">
                      <template v-slot:activator="{ on, attrs }">
                        <p class="p" dark v-bind="attrs" v-on="on">
                          成为客户和员工最信赖的伙伴，
                        </p>
                      </template>
                      <span>规范、专业、核心技术，提供全方位的解决方案，持续为相关方创造价值。</span>
                    </v-tooltip>
                    <v-tooltip bottom color="purple">
                      <template v-slot:activator="{ on, attrs }">
                        <p class="p" dark v-bind="attrs" v-on="on">
                          成为人力资源服务业的领军者
                        </p>
                      </template>
                      <span>公司品牌、市场布局、市场规模、核心产品、优势竞争力。</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import index from "./index";
export default index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
@import "./index.mobile.scss";
</style>