import heroSection from "./component/hero-section/index.vue";
import servicesSection from "./component/services-section/index.vue";
import newsSection from "./component/news-section/index.vue";
import staffSection from "./component/staff-section/index.vue";
import overviewSection from "./component/overview-section/index.vue";
import recruitmentSection from "./component/recruitment-section/index.vue";
import partnerSection from "./component/partner-section/index.vue";
import footerSection from "./component/footer-section/index.vue";
import floatingHelpButton from "./component/floating-help-button/index.vue";

export default {
    components: {
        'hero-section': heroSection,
        'services-section': servicesSection,
        'news-section': newsSection,
        'staff-section': staffSection,
        'overview-section': overviewSection,
        'recruitment-section': recruitmentSection,
        'partner-section': partnerSection,
        'footer-section': footerSection,
        'floating-help-button': floatingHelpButton,
    },

    data: () => ({
        //模块
        indexModules: [
            { id: "services", value: "企业服务" },
            { id: "news", value: "信息公告" },
            { id: "staff", value: "员工服务" },
            { id: "overview", value: "公司概况" },
            { id: "recruitment", value: "岗位招聘" },
            { id: "partner", value: "合作伙伴" },
            { id: "contact-us", value: "联系我们" },
        ],
        drawer: false,
        isHeroIntersecting: false,
        isServiceIntersecting: false,
        partnerEasterEggs: 2,
    }),
    mounted: function () {
        this.easterEggs();
    },
    methods: {
        goToTool: function (str) {
            this.$vuetify.goTo("#" + str);
            if (str == "contact-us") {
                setTimeout(() => {
                    this.$refs.officialFooter.openPhoneListDialog();
                }, 800);
            }
        },
        getHelp: function () {
            this.goToTool("contact-us");
            this.$refs.officialFooter.getHelp();
        },
        heroOnIntersect: function (entries) {
            this.isHeroIntersecting = entries[0].isIntersecting;
        },
        serviceOnIntersect: function (entries) {
            this.isServiceIntersecting = entries[0].isIntersecting;
        },
        easterEggs: function () {
            var noattack =
                '　　　 　　　　┏┓　　┏┓    + +\n'
                + '　　　　　　┏━━┛┻━━━┛┻━━┓ + +\n'
                + '　　　　　　┃　　　　 　　┃ \n'
                + '　　　　　　┃　　　━　　　┃ ++ + + +\n'
                + '          ████━████   ┃ +\n'
                + '　　　　　　┃　　　　　 　┃ +\n'
                + '　　　　　　┃　　┻　     ┃\n'
                + '　　　　　　┃　　　　 　　┃ + +\n'
                + '　　　　　　┗━┓  　　　┏━┛\n'
                + '　　　　　　　　┃　　　┃\n'
                + '　　　　　　　　┃　　　┃   + + + +\n'
                + '　　　　　　　　┃　　　┃     　　Mythical animals bless, website no attack\n'
                + '　　　　　　　　┃　　　┃   + 　　神兽保佑,网站无攻击\n'
                + '　　　　　　　　┃　　　┃\n'
                + '　　　　　　　　┃　　　┃　　+\n'
                + '　　　　　　　　┃　　　┗mmmmmm┓   + +\n'
                + '　　　　　　　　┃ 　　　　　　　┣┓\n'
                + '　　　　　　　　┃ 　　　　　　　┏┛\n'
                + '　　　　　　　  ┗www┓┓┏www┳┓┏┛   + + + +\n'
                + '　　　　　　　　    ┃┫┫　  ┃┫┫\n'
                + '　　　　　　　　    ┗┻┛　  ┗┻┛    + + + +';

            console.log('%c' + noattack, "color:orange");
            console.log('%c' + 'Made in China', "color:#D32F2F");
            console.log('%c' + 'Designed by NymphEX', "color:#6A1B9A");
            console.log('%c' + 'Code by NymphEX', "color:#6A1B9A");
            console.log('%c' + 'Email: 498092988@qq.com', "color:#1ba1e2");
            console.log('%c' + '欢迎提交bug', "color:#76FF03");
        }
    },
};